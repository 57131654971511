import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import {
//   ConnectionProvider,
//   WalletProvider,
// } from "@solana/wallet-adapter-react";
// import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
// import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";

import { AuthProvider } from "./contexts/AuthProvider";
import { WagmiProvider } from "wagmi";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { rainbowKitConfig } from "./utils/currencies";
import { ChainContextProvider } from "./contexts/SolanaChainContextProvider";
import { SelectedWalletAccountContextProvider } from "./contexts/WalletAccountProvider";
import { RpcContextProvider } from "./contexts/SolanaRpcContext";

import GlobalStyle from "./global-styles";
import App from "./App";

import "./input.css";
import "@rainbow-me/rainbowkit/styles.css";

// Initialize Sentry service
if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: "production", // Explicitly set to production
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Configure which URLs to send trace data to
    tracePropagationTargets: [
      /^https:\/\/paytrie\.com/,
      /^https:\/\/app\.paytrie\.com/,
      /^\//, // Include relative URLs within your own domain
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Add custom error processing for wallet errors
    beforeSend(event) {
      // Detect wallet-related errors
      const errorMessage = event.exception?.values?.[0]?.value || "";
      const isWalletError =
        errorMessage.includes("provider") ||
        errorMessage.includes("disconnect") ||
        errorMessage.includes("wallet");

      if (isWalletError) {
        // Add a custom tag for easier filtering
        event.tags = event.tags || {};
        event.tags.error_source = "wallet";

        // Improve error grouping with custom fingerprint
        event.fingerprint = [
          "wallet-connection-error",
          errorMessage.includes("disconnect") ? "disconnect" : "other",
        ];

        // Add package versions as context
        event.contexts = event.contexts || {};
        event.contexts.versions = {
          rainbowKit: "2.2.3",
          wagmi: "2.14.11",
          viem: "2.23.2",
        };
      }

      return event;
    },
  });
}

// Initialize @tanstack/query
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (import.meta.env.MODE === "development") {
  // When development, setup the MSW.
  // import the worker (under the browser.ts file)
  import("./mocks/browser")
    .then(({ worker }) => {
      // Start the worker.
      // worker.start({
      //   onUnhandledRequest: (request) => {
      //     if (request.url.pathname.includes("/src/assets/")) {
      //       return;
      //     }
      //     console.warn("Unhandled: %s %s", request.method, request.url.href);
      //   },
      // });
      worker.start({ onUnhandledRequest: "bypass" });
    })
    .then(() => {
      // Render the application.
      root.render(
        <WagmiProvider config={rainbowKitConfig}>
          <QueryClientProvider client={queryClient}>
            <ChainContextProvider>
              <SelectedWalletAccountContextProvider>
                <RpcContextProvider>
                  <RainbowKitProvider>
                    <AuthProvider>
                      <GlobalStyle />
                      <App />
                    </AuthProvider>
                  </RainbowKitProvider>
                </RpcContextProvider>
              </SelectedWalletAccountContextProvider>
            </ChainContextProvider>
          </QueryClientProvider>
        </WagmiProvider>
      );
    });
} else {
  // Render the application in production without the MSW.
  root.render(
    <>
      <WagmiProvider config={rainbowKitConfig}>
        <QueryClientProvider client={queryClient}>
          <ChainContextProvider>
            <SelectedWalletAccountContextProvider>
              <RpcContextProvider>
                <RainbowKitProvider>
                  <AuthProvider>
                    <GlobalStyle />
                    <App />
                  </AuthProvider>
                </RainbowKitProvider>
              </RpcContextProvider>
            </SelectedWalletAccountContextProvider>
          </ChainContextProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
}
