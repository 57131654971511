import clsx from "clsx";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  BackButton,
  PanelBackground,
  panelClass,
} from "@/components/widget-components";
import { Pages, usePageStateStore } from "@/reducers";
import { DarkModeMenu } from "../signup/DarkmodeSignUpPanel";

const titleText = clsx("text-md md:text-[48px] leading-tight");

export default function PaytrieWelcomeLeftPanel() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parent = searchParams.get("parent");

  return (
    <PanelBackground className={panelClass}>
      <BackButton
        onClick={() => navigate("/")}
        className="absolute top-8 md:top-5 left-5 md:left-10"
      >
        &#x27f5;
      </BackButton>
      <div className="hidden md:block py-5 md:py-10 md:px-4">
        <h2 className={clsx(titleText, "dark:text-sun text-clementine")}>
          Sign in to Paytrie,
        </h2>
        <div className={titleText}>Canada&apos;s home for stablecoins</div>
      </div>
      {window.self === window.top && parent !== "landing" && (
        <div className="absolute top-10 right-0"></div>
      )}
    </PanelBackground>
  );
}
