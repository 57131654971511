import QRCode from "react-qr-code";
import { Address } from "viem";

export const WalletAddressQRCode = ({
  address,
  size = 200,
}: {
  address: Address;
  size: number;
}) => {
  if (!address) return null;

  return <QRCode value={address} size={size} level="H" />;
};
