export const FormStates = {
  CORPINFO: "CORPINFO",
  CORP_BUS_ADDRESS: "CORP_BUS_ADDRESS",
  CORP_ADDN_DETAILS: "CORP_ADDN_DETAILS",
  CORP_DATE_DETAILS: "CORP_DATE_DETAILS",
  DIRECTOR_INFO: "DIRECTOR_INFO",
  DIRECTOR_PEP_INFO: "DIRECTOR_PEP_INFO",
  CORPDOCS: "CORPDOCS",
  DIRECTOR_PERSONAL_SHARE: "DIRECTOR_PERSONAL_SHARE",
  COMPLETE: "COMPLETE",
  DIRECTOR_AUTH_INFO: "DIRECTOR_AUTH_INFO",
  CORPORATE_ARTICLE_INCORPORATION: "CORPORATE_ARTICLE_INCORPORATION",
  CORPORATE_ASSIST: "CORPORATE_ASSIST",
} as const;

export type StateValue = (typeof FormStates)[keyof typeof FormStates];

export class FormWizard {
  private currentState: StateValue;
  private transitions: Record<StateValue, StateValue>;
  private reverseTransitions: Record<StateValue, StateValue>;

  constructor() {
    this.currentState = FormStates.CORPINFO;
    this.transitions = {
      [FormStates.CORPINFO]: FormStates.CORP_DATE_DETAILS,
      [FormStates.CORP_DATE_DETAILS]: FormStates.CORP_BUS_ADDRESS,
      [FormStates.CORP_BUS_ADDRESS]: FormStates.CORP_ADDN_DETAILS,
      [FormStates.CORP_ADDN_DETAILS]: FormStates.DIRECTOR_INFO,
      [FormStates.DIRECTOR_INFO]: FormStates.DIRECTOR_PERSONAL_SHARE,
      [FormStates.DIRECTOR_PERSONAL_SHARE]: FormStates.DIRECTOR_PEP_INFO,
      [FormStates.DIRECTOR_PEP_INFO]: FormStates.DIRECTOR_AUTH_INFO,
      [FormStates.DIRECTOR_AUTH_INFO]: FormStates.CORPDOCS,
      [FormStates.CORPDOCS]: FormStates.CORPORATE_ARTICLE_INCORPORATION,
      [FormStates.CORPORATE_ARTICLE_INCORPORATION]: FormStates.CORPORATE_ASSIST,
      [FormStates.CORPORATE_ASSIST]: FormStates.COMPLETE,
      [FormStates.COMPLETE]: FormStates.COMPLETE,
    };
    this.reverseTransitions = this.buildReverseTransitions();
  }

  getCurrentState(): StateValue {
    return this.currentState;
  }

  private setCurrentState(state: StateValue): void {
    if (this.isValidState(state)) {
      this.currentState = state;
    }
  }

  private isValidState(state: StateValue): boolean {
    return Object.values(FormStates).includes(state);
  }

  private buildReverseTransitions(): Record<StateValue, StateValue> {
    return Object.entries(this.transitions).reduce(
      (acc: Record<StateValue, StateValue>, [from, to]) => {
        if (to !== FormStates.COMPLETE) {
          acc[to] = from as StateValue;
        }
        return acc;
      },
      {} as Record<StateValue, StateValue>
    );
  }

  public next(): void {
    if (this.currentState !== FormStates.COMPLETE) {
      this.currentState = this.transitions[this.currentState];
    }
  }

  public back(): void {
    const previousState = this.reverseTransitions[this.currentState];
    if (previousState) {
      this.currentState = previousState;
    }
  }

  public jumpTo(page: StateValue): void {
    this.setCurrentState(page);
  }

  public reset(): void {
    this.setCurrentState(FormStates.CORPINFO);
  }

  public complete(): void {
    this.setCurrentState(FormStates.COMPLETE);
  }
}

export const FormManager = new FormWizard();
