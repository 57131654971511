import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { Checkbox } from "@/components/Checkbox";
import { useCorpStore } from "@/reducers/corporateReducer";
import { useState } from "react";
import axiosInstance from "@/requests/axios";
import { ROUTES } from "../CorporateSignupPanel";

interface ValidationError {
  isPep?: string;
}

export default function DirectorPepInfo() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const isPep = useCorpStore((state) => state.isPep);
  const setIsPep = useCorpStore((state) => state.setIsPep);

  const [errors, setErrors] = useState<ValidationError>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Determine if form is valid (an option is selected)
  const isFormValid = isPep !== null && isPep !== undefined;

  const validateForm = (): ValidationError => {
    const newErrors: ValidationError = {};

    if (!isFormValid) {
      newErrors.isPep = "Please select an option";
    }

    return newErrors;
  };

  const handlePepChange = (value: boolean) => {
    setIsPep(value);
    setTouched((prev) => ({ ...prev, isPep: true }));
    setErrors({}); // Clear errors when selection is made
  };

  const handleSubmit = async () => {
    if (!isFormValid || isSubmitting) return;

    setIsSubmitting(true);
    const formErrors = validateForm();
    setErrors(formErrors);
    setTouched({ isPep: true });

    if (Object.keys(formErrors).length === 0) {
      try {
        // Save to localStorage
        const stateToSave = {
          isPep,
        };
        localStorage.setItem("corporatePepState", JSON.stringify(stateToSave));

        // Get corporation_email from corporateInfoState
        const corporateInfoState = localStorage.getItem("corporateInfoState");
        const corporateInfo = corporateInfoState
          ? JSON.parse(corporateInfoState)
          : {};
        const corporateEmail = corporateInfo.corporateEmail?.toLowerCase();

        await axiosInstance({
          method: "post",
          url: "/updateCorporate",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            corporation_email: corporateEmail,
            is_pep: isPep,
          },
        });

        nextState();

        // Navigate based on PEP status
        if (!isPep) {
          navigate(ROUTES.DIRECTOR_AUTH_INFO);
        } else {
          // If PEP is true, navigate to CorporateAssist
          navigate(ROUTES.CORPORATE_ASSIST);
        }
      } catch (error) {
        console.error("Error updating PEP status:", error);
        setErrors({
          isPep:
            "Unfortunately we were unable to process your information at this time. Please try again later or contact us at support@paytrie.com",
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="text-lg font-bold uppercase">OWNER'S INFORMATION</div>

      <div className="flex flex-col gap-4">
        <p className="text-base">
          Are any individual associated with the Business, or their direct
          relatives a Foreign or Domestic Politically Exposed Person (
          <a
            href="https://faq.paytrie.com/help/what-is-a-politically-exposed-person-pep"
            className="text-blue-600"
          >
            PEP
          </a>
          ) or a Head of an International Organization (
          <a
            href="https://faq.paytrie.com/help/what-is-a-politically-exposed-person-pep"
            className="text-blue-600"
          >
            HIO
          </a>
          )?
        </p>

        <div className="flex items-center gap-8">
          <Checkbox
            label="Yes"
            checked={isPep === true}
            onCheckedChange={() => handlePepChange(true)}
          />
          <Checkbox
            label="No"
            checked={isPep === false}
            onCheckedChange={() => handlePepChange(false)}
          />
        </div>
        {touched.isPep && errors.isPep && (
          <span className="text-sm text-red-500 mt-1">{errors.isPep}</span>
        )}
      </div>

      <Button
        onClick={handleSubmit}
        disabled={!isFormValid || isSubmitting}
        className="mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
      >
        {isSubmitting ? "Processing..." : "Continue"}
      </Button>
    </div>
  );
}
