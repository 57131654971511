import { Navigate, Outlet, useLocation } from "react-router-dom";
import { usePageStateStore } from "@/reducers";

import { useAuth } from "@/contexts/auth-context";

export const ProtectedRoute = () => {
  const { isAuth, loading } = useAuth();
  const location = useLocation();
  const authToken = usePageStateStore.use.authToken() || "";
  const setProtectedRouteRedirectPath = usePageStateStore.use.setProtectedRouteRedirectPath();

  // If not authenticated,  then save redirected link to localStorage
  if (!authToken) {
    setProtectedRouteRedirectPath(location.pathname.replace("/", ""));
  }

  if (loading) {
    return <div>Account loading...</div>;
  }

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/EasyLogin" replace state={{ from: location }} />
  );
};
