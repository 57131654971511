import { useState, useEffect, useContext } from "react";
import {
  useAccount,
  useChainId,
  useWriteContract,
  useSwitchChain,
  useWaitForTransactionReceipt,
} from "wagmi";
import { parseUnits } from "viem";
import { address } from "@solana/addresses";

import {
  getChainId,
  getTokenAddress,
  getTokenDecimals,
} from "@/utils/currencies";
import * as Sentry from "@sentry/react";
import { SelectedWalletAccountContext } from "@/contexts/WalletAccountProvider";

type TransactionStatus =
  | "idle"
  | "processing"
  | "mining"
  | "completed"
  | "failed";

export type StoredTransaction = {
  orderId: string;
  status: TransactionStatus;
  txHash?: string;
  timestamp: number;
  amount: number;
  tokenSymbol: string;
  attempts?: number;
};

interface UseTransactionProps {
  amount: number;
  recipientAddress: string;
  tokenSymbol: string;
  senderAddress: string;
  orderId: string;
  onTransferComplete?: (txHash: string) => void;
  onError?: (error: Error) => void;
}

export const TRANSACTION_LISTENER_STORAGE_KEY = "paytrieTransactions";

const getStoredTransactions = (): Record<string, StoredTransaction> => {
  try {
    const stored = localStorage.getItem(TRANSACTION_LISTENER_STORAGE_KEY);
    return stored ? JSON.parse(stored) : {};
  } catch (error) {
    console.error("Error reading stored transactions:", error);
    return {};
  }
};

const ERC20_TRANSFER_ABI = [
  {
    name: "transfer",
    type: "function",
    stateMutability: "nonpayable",
    inputs: [
      { name: "recipient", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    outputs: [{ name: "", type: "bool" }],
  },
] as const;

const captureWalletError = (
  error: Error,
  contextData: Record<string, any> = {}
) => {
  // Extract error patterns
  const errorMessage = error?.message || "";
  const isProviderError = errorMessage.includes("provider");
  const isDisconnectError = errorMessage.includes("disconnect");
  const isSignError = errorMessage.includes("sign");
  const isRejectedError =
    errorMessage.includes("rejected") || errorMessage.includes("user denied");

  // Create error classification for better grouping in Sentry
  const errorType = isDisconnectError
    ? "wallet.disconnect_error"
    : isSignError
      ? "wallet.sign_error"
      : isProviderError
        ? "wallet.provider_error"
        : isRejectedError
          ? "wallet.user_rejected"
          : "wallet.other_error";

  // Extract any chain information if available
  const chainId = contextData.chainId || "unknown";
  const walletType = contextData.isEVM ? "EVM" : "Solana";

  // Capture with enriched context
  Sentry.captureException(error, {
    tags: {
      wallet_error_type: errorType,
      wallet_type: walletType,
      chain_id: chainId,
      // Add other useful classification tags
      user_action: contextData.userAction || "unknown",
      is_mobile: /Mobi|Android/i.test(navigator.userAgent),
    },
    extra: {
      // Add the context data from the component
      ...contextData,
    },
    level: isRejectedError ? "warning" : "error", // Lower severity for user rejections
  });

  // Also log to console for local debugging
  console.error(`Wallet Error (${errorType}):`, error, contextData);

  // Return the error type for potential UI handling
  return errorType;
};

export const useTransaction = ({
  amount,
  recipientAddress,
  tokenSymbol,
  senderAddress,
  orderId,
  onTransferComplete,
  onError,
}: UseTransactionProps) => {
  const [transactionState, setTransactionState] = useState<{
    status: TransactionStatus;
    txHash?: string;
    error?: Error;
  }>({ status: "idle" });

  const isEVM = !tokenSymbol.includes("SOL");

  // EVM specific hooks
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();
  const { data: hash, isPending, writeContract } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isError: hasError,
    error: txError,
  } = useWaitForTransactionReceipt({
    hash,
    timeout: 60_000,
  });

  // Solana specific hooks
  const [selectedWalletAccount] = useContext(SelectedWalletAccountContext);

  const { address: ethAddress } = useAccount();

  const updateTransactionStatus = (
    orderId: string,
    status: TransactionStatus,
    txHash?: string
  ) => {
    try {
      const transactions = getStoredTransactions();
      const existingTx = transactions[orderId];

      if (existingTx) {
        transactions[orderId] = {
          ...existingTx,
          status,
          txHash: txHash || existingTx.txHash,
          timestamp: Date.now(),
        };
      } else {
        transactions[orderId] = {
          orderId,
          status,
          txHash,
          timestamp: Date.now(),
          amount,
          tokenSymbol,
          attempts: 1,
        };
      }

      localStorage.setItem(
        TRANSACTION_LISTENER_STORAGE_KEY,
        JSON.stringify(transactions)
      );

      // Update local state immediately
      setTransactionState((prev) => ({
        ...prev,
        status,
        txHash,
      }));
    } catch (error) {
      console.error("Error updating transaction:", error);
    }
  };

  // Effect to monitor EVM transaction status
  useEffect(() => {
    if (!isEVM) return;

    if (isPending) {
      setTransactionState({ status: "processing" });
      updateTransactionStatus(orderId, "processing");
    } else if (hash && isConfirming) {
      setTransactionState({ status: "mining", txHash: hash });
      updateTransactionStatus(orderId, "mining", hash);
    } else if (hash && isConfirmed) {
      setTransactionState({ status: "completed", txHash: hash });
      updateTransactionStatus(orderId, "completed", hash);
      onTransferComplete?.(hash);
    } else if (hasError) {
      setTransactionState({ status: "failed", error: txError as Error });
      updateTransactionStatus(orderId, "failed");
      onError?.(txError as Error);
    }
  }, [hash, isPending, isConfirming, isConfirmed, hasError, txError]);

  const handleEvmTransfer = async () => {
    // Start a Sentry transaction
    Sentry.addBreadcrumb({
      category: "wallet",
      message: "Starting EVM transfer",
      data: {
        amount,
        tokenSymbol,
        chainId,
        orderId,
      },
      level: "info",
    });
    const transactions = getStoredTransactions();
    const existingTx = transactions[orderId];

    if (existingTx?.status === "completed") {
      setTransactionState({
        status: "completed",
        txHash: existingTx.txHash,
      });
      return;
    }

    try {
      const targetId = getChainId(tokenSymbol);
      if (chainId !== targetId) {
        await switchChain({ chainId: targetId });
      }

      // Determine decimal places based on token type
      const decimals = getTokenDecimals(tokenSymbol);
      const amountString = Number(amount).toFixed(decimals);
      const amountToSend = parseUnits(amountString, decimals);

      const recipientAddr = import.meta.env
        .VITE_PAYTRIE_WALLET_ADDR as `0x${string}`;

      // Add transaction attempt breadcrumb
      Sentry.addBreadcrumb({
        category: "wallet",
        message: "Writing contract",
        data: {
          tokenAddress: getTokenAddress(targetId, tokenSymbol),
          recipient: recipientAddr,
          amount: amountString,
          decimals,
        },
        level: "info",
      });

      // Set a custom tag for this transaction for easier filtering in Sentry
      Sentry.setTag("transaction_id", orderId);
      Sentry.setTag(
        "transaction_type",
        `transfer_${tokenSymbol.toLowerCase()}`
      );
      Sentry.setContext("transaction_details", {
        amount,
        tokenSymbol,
        chainId: targetId,
        recipient: recipientAddr,
        sender: address,
      });

      await writeContract({
        abi: ERC20_TRANSFER_ABI,
        address: getTokenAddress(targetId, tokenSymbol),
        functionName: "transfer",
        args: [recipientAddr, amountToSend],
      });
    } catch (error) {
      captureWalletError(error as Error, {
        isEVM,
        chainId,
        walletAddress: address,
        userAction: "transfer",
        tokenSymbol,
        orderId,
        amount,
        recipient: recipientAddress,
      });

      console.error("EVM transfer failed:", error);
      setTransactionState({ status: "failed", error: error as Error });
      updateTransactionStatus(orderId, "failed");
      onError?.(error as Error);
      throw error;
    }
  };

  const isCorrectEvmWallet =
    isEVM && ethAddress?.toLowerCase() === senderAddress?.toLowerCase();

  // Check if connected to the correct Solana wallet
  const isCorrectSolWallet =
    !isEVM && selectedWalletAccount?.address === senderAddress;

  return {
    transactionState,
    handleEvmTransfer,
    isEVM,
    isPending,
    isConfirming,
    chainId,
    address,
    connected: true,
    isCorrectWallet: isEVM ? isCorrectEvmWallet : isCorrectSolWallet,
  };
};
