import { PropsWithChildren } from "react";
import { Cross1Icon } from "@radix-ui/react-icons";
import { useLocation, Link } from "react-router-dom";
import clsx from "clsx";

import SidebarHistory from "@/assets/sidebar-history.svg";
import SidebarTrade from "@/assets/sidebar-trade.svg";
import SidebarProfile from "@/assets/sidebar-profile.svg";
import SideBarOTC from "@/assets/sidebar-otc.svg";

const Item = ({
  isActive,
  children,
  ariaLabel,
  to,
}: PropsWithChildren<{
  isActive: boolean;
  ariaLabel?: string;
  to?: string;
}>) => {
  return (
    <li
      aria-label={ariaLabel}
      className={clsx(
        "relative py-2 pl-4 transition-colors duration-200 ease-in-out",
        {
          "bg-gray-200 dark:bg-gray-700": isActive,
          "text-black dark:text-white": !isActive,
        }
      )}
    >
      <Link
        to={to || "#"}
        className="hover:text-clementine dark:hover:text-sun block w-full h-full"
        style={{ textDecoration: "none" }}
      >
        {children}
      </Link>
    </li>
  );
};

export const Sidebar = ({
  isOpen,
  toggleSidebar,
}: {
  isOpen: boolean;
  toggleSidebar: () => void;
}) => {
  const location = useLocation();

  return (
    <>
      <div
        className={clsx(
          "fixed left-0 top-0 h-screen bg-cloud dark:bg-pebble transform transition-transform duration-300 z-[1000]",
          {
            "translate-x-0": isOpen,
            "-translate-x-full": !isOpen,
          },
          "w-full sm:w-[250px] lg:w-[180px] md:w-[180px]"
        )}
        style={{
          boxShadow: isOpen ? "1px 0 10px rgba(0, 0, 0, 0.3)" : "none",
        }}
      >
        <div className="h-screen p-0 pt-[80px] sm:pt-[60px] relative">
          <button onClick={toggleSidebar} className="absolute top-4 right-4">
            <Cross1Icon />
          </button>
          <ol className="flex flex-col space-y-5">
            <Item
              isActive={location.pathname === "/Dashboard"}
              ariaLabel="Trade"
              to="/Dashboard"
            >
              <div className="flex items-center space-x-2">
                <img
                  src={SidebarTrade}
                  alt="Trade Icon"
                  className="w-7 h-6 dark:invert"
                />
                <h3 className="text-lg">Trade</h3>
              </div>
            </Item>
            <Item
              isActive={location.pathname === "/History"}
              ariaLabel="History"
              to="/History"
            >
              <div className="flex items-center space-x-2">
                <img
                  src={SidebarHistory}
                  alt="History Icon"
                  className="w-7 h-6 dark:invert"
                />
                <h3 className="text-lg">History</h3>
              </div>
            </Item>
            <Item
              isActive={location.pathname === "/Profile"}
              ariaLabel="Profile"
              to="/Profile"
            >
              <div className="flex items-center space-x-2">
                <img
                  src={SidebarProfile}
                  alt="Profile Icon"
                  className="w-7 h-7 dark:invert"
                />
                <h3 className="text-lg">Profile</h3>
              </div>
            </Item>
            {/* <Item
              isActive={location.pathname === "/OTC"}
              ariaLabel="OTC"
              to="/OTC"
            >
              <div className="flex items-center space-x-2">
                <img
                  src={SideBarOTC}
                  alt="Profile Icon"
                  className="w-7 h-7 dark:invert"
                />
                <h3 className="text-lg">OTC</h3>
              </div>
            </Item> */}
          </ol>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
