import React from "react";
import { WidgetLayout } from "@/pages/Buy/WidgetLayout";

interface OrderSummaryProps {
  setShowOrderSummary: (show: boolean) => void;
  formData?: any;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ formData }) => {
  const handleWireInstructions = () => {
    const url =
      formData.currency === "USD"
        ? "https://storage.paytrie.com/Paytrie_USD_Wire_Instructions_(Canadian_Bank).pdf"
        : "https://storage.paytrie.com/Paytrie_CAD_Wire_Instructions.pdf";

    window.open(url, "_blank");
  };

  const getConfirmationUrl = () => {
    const network = formData?.network || "";
    switch (network.toUpperCase()) {
      case "SOL":
        return "https://solscan.io/address/";
      case "ARB":
        return "https://arbiscan.io/address/";
      case "POLY":
        return "https://polygonscan.com/address/";
      case "OPT":
        return "https://optimistic.etherscan.io/address/";
      case "AVAX":
        return "https://snowtrace.io/address/";
      case "CEL":
        return "https://celoscan.io/address/";
      default:
        return "https://etherscan.io/address/";
    }
  };

  return (
    <div className="md:max-w-[730px] max-w-[330px] mx-auto">
      <WidgetLayout showInteracLabel={false}>
        <div className="flex justify-center items-start min-h-screen max-w-[750px]">
          <div className="dark:text-white sm:p-8 w-full max-w-full sm:max-w-[700px] text-black">
            <h3 className="text-xl font-bold mb-2 text-center mt-20 md:mt-10">
              ORDER SUMMARY
            </h3>

            <div className="space-y-4 md:text-lg sm:text-sm mt-8">
              <p className="flex justify-between">
                <span>Date:</span>
                <span>{formData?.currentTime || "N/A"}</span>
              </p>
              <p className="flex justify-between">
                <span>Wire amount:</span>
                <span>{formData?.amount || "N/A"}</span>
              </p>
              <p className="flex justify-between">
                <span>Currency:</span>
                <span>{formData?.currency || "N/A"}</span>
              </p>
              <p className="flex justify-between">
                <span>Stablecoin:</span>
                <span>{formData?.stablecoin || "N/A"}</span>
              </p>
              <p className="flex justify-between">
                <span>Network:</span>
                <span>{formData?.network || "N/A"}</span>
              </p>
              <p className="flex justify-between">
                <span>Wallet:</span>
                <span className="min-w-0 break-words text-right">
                  {formData?.wallet ? (
                    <a
                      href={`${getConfirmationUrl()}${formData.wallet}#tokentxns`}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      className="text-blue-500 break-all"
                    >
                      {formData.wallet}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </span>
              </p>
            </div>

            <div className="mt-20 space-y-6">
              <button
                className="w-full py-4 sm:py-6 bg-black text-white dark:bg-white dark:text-black border border-black text-center block transition-colors text-sm sm:text-base"
                onClick={handleWireInstructions}
              >
                Wire Instructions
              </button>
            </div>
          </div>
        </div>
      </WidgetLayout>
    </div>
  );
};

export default OrderSummary;
