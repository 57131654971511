export const FormStates = {
  EMAIL: "EMAIL",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  PASSWORD: "PASSWORD",
  PHONE: "PHONE",
  VERIFY_PHONE: "VERIFY_PHONE",
  DISCLOSURE: "DISCLOSURE",
  PERSONAL_INFO: "PERSONAL_INFO",
  OCCUPATION: "OCCUPATION",
  KYC: "KYC",
  KYC_GENERATE_QUESTIONS: "KYC_GENERATE_QUESTIONS",
  COMPLETE: "COMPLETE",
};

export type StateValue = (typeof FormStates)[keyof typeof FormStates];

export class FormWizard {
  private currentState: StateValue;
  private transitions: Record<StateValue, StateValue>;
  private reverseTransitions: Record<StateValue, StateValue>;

  constructor() {
    this.currentState = FormStates.EMAIL;
    this.transitions = {
      //[FormStates.KYC]: FormStates.EMAIL,
      [FormStates.EMAIL]: FormStates.VERIFY_EMAIL,
      [FormStates.VERIFY_EMAIL]: FormStates.PHONE,
      [FormStates.PHONE]: FormStates.VERIFY_PHONE,
      [FormStates.VERIFY_PHONE]: FormStates.DISCLOSURE,
      [FormStates.DISCLOSURE]: FormStates.PERSONAL_INFO,
      [FormStates.PERSONAL_INFO]: FormStates.OCCUPATION,
      [FormStates.OCCUPATION]: FormStates.KYC_GENERATE_QUESTIONS,
      [FormStates.KYC_GENERATE_QUESTIONS]: FormStates.PASSWORD,
      [FormStates.PASSWORD]: FormStates.COMPLETE,
    };
    this.reverseTransitions = this.reverse_transitions();
  }

  getCurrentState() {
    return this.currentState;
  }

  private setCurrentState(state: StateValue) {
    this.currentState = state;
  }

  public next() {
    if (this.currentState !== FormStates.COMPLETE) {
      this.currentState = this.transitions[this.currentState];
    }
  }

  private reverse_transitions = () =>
    Object.keys(this.transitions).reduce(
      (acc: Record<StateValue, StateValue>, key: StateValue) => {
        const reverseKey = this.transitions[key];
        acc[reverseKey] = key;
        return acc;
      },
      {}
    );

  public back() {
    this.currentState = this.reverseTransitions[this.currentState];
  }

  public jumpTo(page: StateValue) {
    this.setCurrentState(page);
  }

  public reset() {
    this.setCurrentState(FormStates.EMAIL);
  }

  public complete() {
    this.setCurrentState(FormStates.COMPLETE);
  }
}

export const FormManager = new FormWizard();

export const CorporateFormStates = {
  CORPINFO: "CORPINFO",
  CORP_BUS_ADDRESS: "CORP_BUS_ADDRESS",
  CORP_ADDN_DETAILS: "CORP_ADDN_DETAILS",
  CORP_DATE_DETAILS: "CORP_DATE_DETAILS",
  CORPDOCS: "CORPDOCS",
  COMPLETE: "COMPLETE",
} as const;

export type CorporateStateValue =
  (typeof CorporateFormStates)[keyof typeof CorporateFormStates];

export class CorporateFormWizard {
  private currentState: CorporateStateValue;
  private transitions: Record<CorporateStateValue, CorporateStateValue>;
  private reverseTransitions: Record<CorporateStateValue, CorporateStateValue>;

  constructor() {
    this.currentState = CorporateFormStates.CORPINFO;
    this.transitions = {
      [CorporateFormStates.CORPINFO]: CorporateFormStates.CORP_DATE_DETAILS,
      [CorporateFormStates.CORP_DATE_DETAILS]:
        CorporateFormStates.CORP_BUS_ADDRESS,
      [CorporateFormStates.CORP_BUS_ADDRESS]:
        CorporateFormStates.CORP_ADDN_DETAILS,
      [CorporateFormStates.CORP_ADDN_DETAILS]: CorporateFormStates.CORPDOCS,
      [CorporateFormStates.CORPDOCS]: CorporateFormStates.COMPLETE,
      [CorporateFormStates.COMPLETE]: CorporateFormStates.COMPLETE,
    };
    this.reverseTransitions = this.buildReverseTransitions();
  }

  getCurrentState(): CorporateStateValue {
    return this.currentState;
  }

  private setCurrentState(state: CorporateStateValue): void {
    this.currentState = state;
  }

  public next(): void {
    if (this.currentState !== CorporateFormStates.COMPLETE) {
      this.currentState = this.transitions[this.currentState];
    }
  }

  private buildReverseTransitions(): Record<
    CorporateStateValue,
    CorporateStateValue
  > {
    return Object.entries(this.transitions).reduce(
      (acc: Record<CorporateStateValue, CorporateStateValue>, [from, to]) => {
        if (to !== CorporateFormStates.COMPLETE) {
          acc[to] = from as CorporateStateValue;
        }
        return acc;
      },
      {} as Record<CorporateStateValue, CorporateStateValue>
    );
  }

  public back(): void {
    const previousState = this.reverseTransitions[this.currentState];
    if (previousState) {
      this.currentState = previousState;
    }
  }

  public jumpTo(page: CorporateStateValue): void {
    this.setCurrentState(page);
  }

  public reset(): void {
    this.setCurrentState(CorporateFormStates.CORPINFO);
  }

  public complete(): void {
    this.setCurrentState(CorporateFormStates.COMPLETE);
  }
}
