import React, { useState, useContext, useCallback, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useDisconnect } from "wagmi";
import { Networks } from "@/utils/currencies";
import { Button } from "@/components/Button/Button";
import {
  useWallets,
  useConnect,
  type UiWallet,
  type UiWalletAccount,
} from "@wallet-standard/react";
import { SelectedWalletAccountContext } from "@/contexts/WalletAccountProvider";

import * as Sentry from "@sentry/react";
import * as Dialog from "@radix-ui/react-dialog";
import * as Icons from "@radix-ui/react-icons";

interface SimpleWalletConnectProps {
  network: string;
  onWalletConnect: (address: string) => void;
  isValidManualAddress: boolean;
  currentWallet: string;
}

export const SimpleWalletConnect: React.FC<SimpleWalletConnectProps> = ({
  network,
  onWalletConnect,
  isValidManualAddress,
  currentWallet,
}) => {
  const { disconnect } = useDisconnect();
  const isEVM = network !== Networks.sol;
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  // Get the selected wallet account from context
  const [selectedWalletAccount, setSelectedWalletAccount] = useContext(
    SelectedWalletAccountContext
  );

  // Get available wallets
  const wallets = useWallets();

  // Filter for Solana wallets
  const availableSolanaWallets = wallets.filter((wallet) =>
    wallet.chains.includes("solana:mainnet")
  );

  // Function to disconnect current Solana wallet
  const disconnectSolanaWallet = useCallback(() => {
    if (selectedWalletAccount) {
      console.log(
        "Disconnecting current wallet account:",
        selectedWalletAccount.address
      );
      setSelectedWalletAccount(undefined);
      onWalletConnect("");
    }
  }, [selectedWalletAccount, setSelectedWalletAccount, onWalletConnect]);

  const handleSolanaConnect = async () => {
    try {
      // First disconnect the current wallet if connected
      if (selectedWalletAccount) {
        disconnectSolanaWallet();
        // Brief timeout to ensure disconnection is complete
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // Open the wallet selection dialog
      setWalletDialogOpen(true);
    } catch (error) {
      // Capture Solana wallet errors in Sentry
      Sentry.captureException(error, {
        tags: {
          action: "solana_wallet_connect",
          is_connected: selectedWalletAccount ? "true" : "false",
          wallet_address: selectedWalletAccount?.address,
          expected_address: currentWallet,
        },
      });
      console.error("Error handling Solana wallet connection:", error);
    }
  };

  // Handle accounts after successful connection
  const handleAccountsReceived = useCallback(
    (account: UiWalletAccount) => {
      console.log("Selected account:", account);
      if (account) {
        // Set in the context (this will also save to localStorage)
        setSelectedWalletAccount(account);
        onWalletConnect(account.address);
      }
      setWalletDialogOpen(false);
    },
    [setSelectedWalletAccount, onWalletConnect]
  );

  // Use the ConnectButton component for each wallet
  const WalletConnectButton = ({
    wallet,
    onConnect,
  }: {
    wallet: UiWallet;
    onConnect: (accounts: UiWalletAccount) => void;
  }) => {
    const [isConnecting, connect] = useConnect(wallet);

    const handleConnectClick = useCallback(async () => {
      try {
        const nextAccounts = await connect();
        // Handle account selection if needed
        if (nextAccounts.length > 0) {
          onConnect(nextAccounts[0]);
        }
      } catch (error: any) {
        console.error("Wallet connection error:", error);

        Sentry.captureException(error, {
          tags: {
            action: "wallet_connect",
            wallet_name: wallet.name,
          },
        });
      }
    }, [connect, onConnect]);

    return (
      <button
        onClick={handleConnectClick}
        disabled={isConnecting}
        className="w-full justify-start p-3 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
      >
        <div className="flex items-center gap-2">
          {wallet.icon && (
            <img
              src={wallet.icon}
              alt={`${wallet.name} icon`}
              className="w-6 h-6"
            />
          )}
          <span className="font-medium">{wallet.name}</span>
          {isConnecting && <span className="ml-auto">Connecting...</span>}
        </div>
      </button>
    );
  };

  const truncateAddress = (address: string): string => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Solana wallet selection dialog
  const WalletSelectDialog = () => (
    <Dialog.Root open={walletDialogOpen} onOpenChange={setWalletDialogOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40 backdrop-blur-sm" />
        <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-900 p-6 rounded-lg shadow-xl w-[90vw] max-w-md">
          <Dialog.Title className="text-xl font-semibold mb-4">
            {selectedWalletAccount ? "Switch Wallet" : "Select a Wallet"}
          </Dialog.Title>
          {selectedWalletAccount && (
            <div className="mb-4 p-3 bg-yellow-50 dark:bg-yellow-900/30 rounded border border-yellow-200 dark:border-yellow-800">
              <p className="text-sm text-yellow-800 dark:text-yellow-200">
                You'll be disconnected from your current wallet (
                {truncateAddress(selectedWalletAccount.address)}) when you
                connect to a new one.
              </p>
            </div>
          )}
          <div className="space-y-2 max-h-64 overflow-y-auto">
            {availableSolanaWallets.length > 0 ? (
              availableSolanaWallets.map((wallet) => (
                <WalletConnectButton
                  key={wallet.name}
                  wallet={wallet}
                  onConnect={handleAccountsReceived}
                />
              ))
            ) : (
              <div className="p-3 text-center text-gray-500">
                No Solana wallets found. Please install a Solana wallet
                extension.
              </div>
            )}
          </div>
          <Dialog.Close asChild>
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              aria-label="Close"
            >
              <Icons.Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );

  // Check if connected to the expected Solana wallet
  const isSolConnectedWallet = selectedWalletAccount?.address === currentWallet;
  const shouldDisableSolButton = isValidManualAddress && !isSolConnectedWallet;

  if (isEVM) {
    return (
      <ConnectButton.Custom>
        {({ account, openConnectModal }) => {
          const ready = Boolean(account);
          const isConnectedWallet =
            account?.address?.toLowerCase() === currentWallet?.toLowerCase();

          useEffect(() => {
            if (account?.address) {
              onWalletConnect(account.address);
            }
          }, [account?.address]);

          const handleClick = () => {
            try {
              if (ready) {
                disconnect();
                onWalletConnect("");
                setTimeout(openConnectModal, 100);
              } else {
                openConnectModal();
              }
            } catch (error) {
              Sentry.captureException(error, {
                tags: {
                  action: "wallet_button_click",
                  is_connected: ready ? "true" : "false",
                  wallet_address: account?.address,
                  expected_address: currentWallet,
                },
              });
              console.error("Button click error:", error);
            }
          };

          const shouldDisable = isValidManualAddress && !isConnectedWallet;

          return (
            <Button
              onClick={handleClick}
              disabled={shouldDisable}
              className={`h-12 px-4 text-sm font-medium ${shouldDisable ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {ready ? "Switch Wallet" : "Connect"}
            </Button>
          );
        }}
      </ConnectButton.Custom>
    );
  }

  return (
    <>
      <Button
        onClick={handleSolanaConnect}
        disabled={shouldDisableSolButton}
        className={`h-12 px-4 text-sm font-medium ${shouldDisableSolButton ? "opacity-50 cursor-not-allowed" : ""}`}
      >
        {selectedWalletAccount ? "Switch Wallet" : "Connect"}
      </Button>
      <WalletSelectDialog />
    </>
  );
};
