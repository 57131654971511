import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { useCorpStore } from "@/reducers/corporateReducer";

import { ROUTES } from "../CorporateSignupPanel";
import * as Select from "@radix-ui/react-select";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import clsx from "clsx";

interface ValidationError {
  issuer?: string;
  file?: string;
}

const ISSUERS = [
  {
    group: "Federal Government",
    options: [{ value: "federal", label: "Government of Canada" }],
  },
  {
    group: "Provincial Government",
    options: [
      { value: "alberta", label: "Alberta" },
      { value: "british_columbia", label: "British Columbia" },
      { value: "manitoba", label: "Manitoba" },
      { value: "new_brunswick", label: "New Brunswick" },
      { value: "newfoundland", label: "Newfoundland and Labrador" },
      { value: "nova_scotia", label: "Nova Scotia" },
      { value: "ontario", label: "Ontario" },
      { value: "pei", label: "Prince Edward Island" },
      { value: "quebec", label: "Quebec" },
      { value: "saskatchewan", label: "Saskatchewan" },
      { value: "nwt", label: "Northwest Territories" },
      { value: "nunavut", label: "Nunavut" },
      { value: "yukon", label: "Yukon" },
    ],
  },
];

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Failed to convert file to base64"));
      }
    };
    reader.onerror = (error) => reject(error);
  });
};

export default function CorporateIssuerDocuments() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const [file, setFile] = useState<File | null>(null);
  const [selectedIssuer, setSelectedIssuer] = useState<string>("");
  const [isDragging, setIsDragging] = useState(false);
  const [errors, setErrors] = useState<ValidationError>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const savedState = localStorage.getItem("corporateIssuerState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      setSelectedIssuer(parsed.selectedIssuer || "");
    }
  }, []);

  const validateForm = (): ValidationError => {
    const newErrors: ValidationError = {};

    if (!selectedIssuer) {
      newErrors.issuer = "Please select an issuer";
    }

    if (!file) {
      newErrors.file = "Please upload a document";
    } else {
      if (file.size > MAX_FILE_SIZE) {
        newErrors.file = "File size must be less than 10MB";
      }
      if (!isValidFileType(file)) {
        newErrors.file =
          "Invalid file type. Please upload a PDF, JPG, or PNG file";
      }
    }

    return newErrors;
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    setTouched((prev) => ({ ...prev, file: true }));

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      if (droppedFile.size > MAX_FILE_SIZE) {
        setErrors((prev) => ({
          ...prev,
          file: "File size must be less than 10MB",
        }));
        return;
      }
      if (isValidFileType(droppedFile)) {
        setFile(droppedFile);
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors.file;
          return newErrors;
        });
      } else {
        setErrors((prev) => ({
          ...prev,
          file: "Invalid file type. Please upload a PDF, JPG, or PNG file",
        }));
      }
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    setTouched((prev) => ({ ...prev, file: true }));

    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        setErrors((prev) => ({
          ...prev,
          file: "File size must be less than 10MB",
        }));
        return;
      }
      if (isValidFileType(selectedFile)) {
        setFile(selectedFile);
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors.file;
          return newErrors;
        });
      } else {
        setErrors((prev) => ({
          ...prev,
          file: "Invalid file type. Please upload a PDF, JPG, or PNG file",
        }));
      }
    }
  };

  const isValidFileType = (file: File) => {
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    return allowedTypes.includes(file.type);
  };

  const handleIssuerChange = (value: string) => {
    setSelectedIssuer(value);
    setTouched((prev) => ({ ...prev, issuer: true }));
    if (value) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.issuer;
        return newErrors;
      });
    } else {
      setErrors((prev) => ({ ...prev, issuer: "Please select an issuer" }));
    }
  };

  const handleSubmit = async () => {
    const formErrors = validateForm();
    setErrors(formErrors);
    setTouched({ issuer: true, file: true });

    if (Object.keys(formErrors).length === 0) {
      try {
        setIsSubmitting(true);

        if (!file) {
          setErrors((prev) => ({
            ...prev,
            file: "Please upload a valid certificate of incorporation",
          }));
          setIsSubmitting(false);
          return;
        }

        // Get base64 data for the file
        const base64 = await getBase64(file);

        // Instead of storing the full base64 in localStorage, store it temporarily in sessionStorage
        const tempId = `certFile_${Date.now()}`;
        sessionStorage.setItem(tempId, base64);

        // Create a metadata object without the large base64 data
        const certOfIncorpFile = {
          uid: 1,
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          status: "done",
          // Instead of storing the base64, store a reference to it
          tempStorageId: tempId,
        };

        // Save minimal metadata to localStorage
        localStorage.setItem(
          "corporateIssuerState",
          JSON.stringify({
            selectedIssuer,
            certOfIncorpFile: certOfIncorpFile,
            verification: [selectedIssuer.toLowerCase()],
          })
        );

        nextState();
        navigate(ROUTES.CORPORATE_ARTICLE_INCORPORATION);
      } catch (error) {
        console.error("Error processing certificate:", error);
        setErrors((prev) => ({
          ...prev,
          submit:
            "Unfortunately we were unable to process your document. Please try again later or contact us at support@paytrie.com",
        }));
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const handleKeyDown = async (e: React.KeyboardEvent) => {
    const isFormComplete = selectedIssuer && file && !isSubmitting;

    if (e.key === "Enter" && isFormComplete) {
      e.preventDefault();
      await handleSubmit();
    }
  };

  useEffect(() => {
    const handleDocumentKeyDown = async (e: KeyboardEvent) => {
      const isFormComplete = selectedIssuer && file && !isSubmitting;

      if (e.key === "Enter" && isFormComplete) {
        e.preventDefault();
        await handleSubmit();
      }
    };

    document.addEventListener("keydown", handleDocumentKeyDown);
    return () => {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    };
  }, [selectedIssuer, file, isSubmitting]);

  return (
    <div className="flex flex-col gap-6" onKeyDown={handleKeyDown} tabIndex={0}>
      <div className="text-lg font-bold uppercase">CORPORATE DOCUMENTS</div>

      <div className="flex flex-col gap-2">
        <label className="text-base">
          Issuer of Certificate of Incorporation
        </label>
        <div className="">
          <Select.Root
            onValueChange={handleIssuerChange}
            value={selectedIssuer}
          >
            <Select.Trigger className="inline-flex items-center justify-between border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 px-3 py-2 text-sm w-full hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:border-blue-500 dark:text-white">
              <Select.Value placeholder="Select issuer" />
              <Select.Icon>
                <ChevronDownIcon className="h-4 w-4 text-gray-500 dark:text-white" />
              </Select.Icon>
            </Select.Trigger>

            <Select.Portal>
              <Select.Content className="overflow-hidden bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600">
                <Select.Viewport className="p-0">
                  {ISSUERS.map((group) => (
                    <Select.Group key={group.group}>
                      <Select.Label className="px-2 py-1 text-sm font-semibold text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600">
                        {group.group}
                      </Select.Label>
                      {group.options.map((option) => (
                        <Select.Item
                          key={option.value}
                          value={option.value}
                          className="relative flex items-center px-8 py-2 text-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:bg-gray-50 dark:focus:bg-gray-700 cursor-pointer outline-none select-none border-b border-gray-100 dark:border-gray-700 last:border-b-0 dark:text-white"
                        >
                          <Select.ItemText>{option.label}</Select.ItemText>
                        </Select.Item>
                      ))}
                    </Select.Group>
                  ))}
                </Select.Viewport>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
        </div>
        {touched.issuer && errors.issuer && (
          <span className="text-sm text-red-500 mt-1">{errors.issuer}</span>
        )}
      </div>

      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`
          mt-4 p-8 border-2 border-dashed rounded-lg
          flex flex-col items-center justify-center
          cursor-pointer transition-colors
          ${isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"}
          ${file ? "bg-green-50" : "hover:bg-gray-50"}
          ${touched.file && errors.file ? "border-red-500" : ""}
        `}
        onClick={() => document.getElementById("file-upload")?.click()}
      >
        <input
          id="file-upload"
          type="file"
          className="hidden"
          onChange={handleFileSelect}
          accept=".pdf,.jpg,.jpeg,.png"
        />
        {file ? (
          <div className="flex flex-col items-center">
            <p className="text-green-600 font-medium">{file.name}</p>
            <p className="text-sm text-gray-500 mt-1">Click to change file</p>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <p className="font-medium">Upload</p>
            <p className="text-sm text-gray-500 mt-1">
              Drag and drop or click to select a file
            </p>
            <p className="text-xs text-gray-400 mt-1">
              Supported formats: PDF, JPG, PNG (Max 10MB)
            </p>
          </div>
        )}
      </div>
      {touched.file && errors.file && (
        <span className="text-sm text-red-500 mt-1">{errors.file}</span>
      )}

      <Button
        onClick={handleSubmit}
        disabled={isSubmitting || !selectedIssuer || !file}
        className={clsx(
          "mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2",
          {
            "opacity-50 cursor-not-allowed bg-gray-400":
              !selectedIssuer || !file || isSubmitting,
            "hover:bg-gray-500": !selectedIssuer || !file || isSubmitting,
          }
        )}
      >
        {isSubmitting ? "Processing..." : "Continue"}
      </Button>
    </div>
  );
}
