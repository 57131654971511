import { DownloadIcon } from "@radix-ui/react-icons";
import { CSVLink } from "react-csv";

interface Props {
  transactions: any[];
  firstName: string;
}

const isValidDate = (date: any) => {
  return !isNaN(Date.parse(date));
};

const formatNumber = (value: any, decimals: number = 2) => {
  return typeof value === "number" ? value.toFixed(decimals) : "0.00";
};

export const ReportButton = ({ transactions, firstName }: Props) => {
  const headers = [
    { label: "Date", key: "date" },
    { label: "Received Amount", key: "received_amount" },
    { label: "Received Currency", key: "received_currency" },
    { label: "Sent Amount", key: "sent_amount" },
    { label: "Sent Currency", key: "sent_currency" },
    { label: "Description", key: "description" },
    { label: "TxHash", key: "txhash" },
  ];

  const koinlyTransactionsList = transactions
    .filter((t) => t.status === "complete")
    .map((t) => {
      const baseDate = isValidDate(t.date) ? new Date(t.date) : new Date();

      const incrementedDate = (seconds: number) =>
        new Date(baseDate.getTime() + seconds * 1000).toLocaleString("en-CA", {
          dateStyle: "short",
          timeStyle: "long",
          hourCycle: "h23",
          timeZone: "UTC",
        });

      const isBuy = t.leftSideLabel === "CAD";
      const label = isBuy ? "Buy Stablecoin" : "Sell Stablecoin";

      const transferRow = {
        date: isBuy ? incrementedDate(1) : incrementedDate(-1),
        sent_amount: isBuy ? formatNumber(t.rightSideValue) : undefined,
        sent_currency: isBuy ? t.rightSideLabel.split("-")[0] : undefined,
        received_amount: !isBuy ? formatNumber(t.leftSideValue) : undefined,
        received_currency: !isBuy ? t.leftSideLabel.split("-")[0] : undefined,
        txhash: t.payment_id || null,
        description: isBuy ? "Transfer" : "Deposit",
      };

      const mainRow = {
        date: incrementedDate(0),
        sent_amount: formatNumber(t.leftSideValue),
        sent_currency: t.leftSideLabel.split("-")[0],
        received_amount: formatNumber(t.rightSideValue),
        received_currency: t.rightSideLabel.split("-")[0],
        description: label,
      };

      const depositOrWithdrawRow = {
        date: isBuy ? incrementedDate(-1) : incrementedDate(1),
        received_amount: isBuy ? formatNumber(t.leftSideValue) : undefined,
        received_currency: isBuy ? t.leftSideLabel.split("-")[0] : undefined,
        sent_amount: !isBuy ? formatNumber(t.rightSideValue) : undefined,
        sent_currency: !isBuy ? t.rightSideLabel.split("-")[0] : undefined,
        description: isBuy ? "Deposit" : "Withdraw",
      };

      return isBuy
        ? [transferRow, mainRow, depositOrWithdrawRow]
        : [transferRow, mainRow, depositOrWithdrawRow];
    })
    .flat();

  return (
    <CSVLink
      filename={`${firstName}_paytrie_koinly.csv`}
      data={koinlyTransactionsList}
      headers={headers}
    >
      <button className="px-4 py-2 bg-black text-white dark:bg-white dark:text-black flex items-center">
        Export CSV <DownloadIcon className="w-4 h-4 ml-2" />
      </button>
    </CSVLink>
  );
};
