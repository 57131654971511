import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { TextInput } from "@/components/widget-components";
import { useCorpStore } from "@/reducers/corporateReducer";
import { ROUTES } from "../CorporateSignupPanel";
import axiosInstance from "@/requests/axios";
import * as Select from "@radix-ui/react-select";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as RadioGroup from "@radix-ui/react-radio-group";

interface ValidationError {
  businessNature?: string;
  purpose?: string;
  sourceOfFunds?: string;
  msbReg?: string;
}

const INTENDED_PURPOSES = [
  { value: "payments", label: "Payments" },
  { value: "payroll", label: "Payroll" },
  { value: "treasury_management", label: "Treasury Management" },
  { value: "trading_investments", label: "Trading and Investments" },
  { value: "merchant_transactions", label: "Merchant Transactions" },
  { value: "defi_transactions", label: "DeFi Transactions" },
] as const;

const SOURCE_OF_FUNDS = [
  { value: "business_revenue", label: "Business Revenue" },
  { value: "investments_treasury", label: "Investments and Treasury" },
  { value: "loans_financing", label: "Loans & Financing" },
  { value: "grants", label: "Grants" },
] as const;

export default function CorporateAdditionalDetails() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const businessNature = useCorpStore((state) => state.businessNature);
  const setBusinessNature = useCorpStore((state) => state.setBusinessNature);
  const purpose = useCorpStore((state) => state.purpose);
  const setPurpose = useCorpStore((state) => state.setPurpose);
  const sourceOfFunds = useCorpStore((state) => state.sourceOfFunds);
  const setSourceOfFunds = useCorpStore((state) => state.setSourceOfFunds);
  const msbReg = useCorpStore((state) => state.msbReg);
  const setMsbReg = useCorpStore((state) => state.setMsbReg);

  const [errors, setErrors] = useState<ValidationError>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Load state on component mount
  useEffect(() => {
    const savedState = localStorage.getItem("corporateAdditionalState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      setBusinessNature(parsed.businessNature || "");
      setPurpose(parsed.purpose || "");
      setSourceOfFunds(parsed.sourceOfFunds || "");
      if (parsed.msbReg !== undefined) {
        setMsbReg(parsed.msbReg);
      }
    }
  }, []);

  // Check form validity whenever fields change
  useEffect(() => {
    const formErrors = validateForm();
    const areRequiredFieldsFilled =
      businessNature.trim().length >= 5 &&
      purpose.trim().length >= 5 &&
      sourceOfFunds.trim().length >= 5 &&
      typeof msbReg === "boolean";

    setIsFormValid(
      Object.keys(formErrors).length === 0 && areRequiredFieldsFilled
    );
  }, [businessNature, purpose, sourceOfFunds, msbReg]);

  const handleBlur = (field: keyof ValidationError) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const formErrors = validateForm();
    if (formErrors[field]) {
      setErrors((prev) => ({ ...prev, [field]: formErrors[field] }));
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const validateForm = useCallback((): ValidationError => {
    const newErrors: ValidationError = {};

    if (!businessNature.trim()) {
      newErrors.businessNature = "Nature of business is required";
    } else if (businessNature.length < 5) {
      newErrors.businessNature =
        "Please provide more detail about the nature of business";
    }

    if (!purpose) {
      newErrors.purpose = "Purpose is required";
    }

    if (!sourceOfFunds) {
      newErrors.sourceOfFunds = "Source of funds is required";
    }

    if (
      msbReg === null ||
      msbReg === undefined ||
      typeof msbReg !== "boolean"
    ) {
      newErrors.msbReg =
        "Please indicate if the business is registered as an MSB";
    }

    return newErrors;
  }, [businessNature, purpose, sourceOfFunds, msbReg]);

  const handleSubmit = useCallback(async () => {
    const formErrors = validateForm();
    setErrors(formErrors);
    setTouched({
      businessNature: true,
      purpose: true,
      sourceOfFunds: true,
      msbReg: true,
    });

    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const stateToSave = {
          businessNature,
          purpose,
          sourceOfFunds,
          msbReg,
        };
        localStorage.setItem(
          "corporateAdditionalState",
          JSON.stringify(stateToSave)
        );

        const corporateInfoState = localStorage.getItem("corporateInfoState");
        const corporateInfo = corporateInfoState
          ? JSON.parse(corporateInfoState)
          : {};
        const corporateEmail = corporateInfo.corporateEmail?.toLowerCase();

        const values = {
          business_nature: businessNature.toLowerCase(),
          purpose: purpose.toLowerCase(),
          source_of_funds: sourceOfFunds.toLowerCase(),
          msb_reg: msbReg,
        };

        await axiosInstance({
          method: "post",
          url: "/updateCorporate",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            corporation_email: corporateEmail,
            ...values,
          },
        });

        nextState();
        navigate(ROUTES.DIRECTOR_INFO);
      } catch (error) {
        console.error("Error updating corporate date details:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  }, [
    businessNature,
    purpose,
    sourceOfFunds,
    msbReg,
    validateForm,
    nextState,
    navigate,
  ]);

  // Add global keydown effect
  useEffect(() => {
    const handleGlobalKeyDown = async (e: KeyboardEvent) => {
      // Only handle Enter key if no dropdown is open
      const isDropdownOpen = document.querySelector('[data-state="open"]');
      if (
        e.key === "Enter" &&
        !isDropdownOpen &&
        isFormValid &&
        !isSubmitting
      ) {
        e.preventDefault();
        await handleSubmit();
      }
    };

    document.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [isFormValid, isSubmitting, handleSubmit]);

  // Also memoize the handlers for field changes
  const handleSourceOfFundsChange = useCallback((value: string) => {
    setSourceOfFunds(value);
    setTouched((prev) => ({ ...prev, sourceOfFunds: true }));

    if (value) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.sourceOfFunds;
        return newErrors;
      });
    }
  }, []);

  const handlePurposeChange = useCallback((value: string) => {
    setPurpose(value);
    setTouched((prev) => ({ ...prev, purpose: true }));

    if (value) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.purpose;
        return newErrors;
      });
    }
  }, []);

  const handleMsbChange = useCallback((value: boolean) => {
    setMsbReg(value);
    setTouched((prev) => ({ ...prev, msbReg: true }));
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors.msbReg;
      return newErrors;
    });
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <div className="text-lg font-bold uppercase">BUSINESS DETAILS</div>
      <div className="flex flex-col gap-1">
        <TextInput
          placeholder="Nature of principal business"
          value={businessNature}
          onChange={(e) => setBusinessNature(e.target.value)}
          onBlur={() => handleBlur("businessNature")}
        />
        {touched.businessNature && errors.businessNature && (
          <span className="text-sm text-red-500 mt-1">
            {errors.businessNature}
          </span>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <Select.Root value={purpose} onValueChange={handlePurposeChange}>
          <Select.Trigger
            className="text-pebble dark:text-snow py-2 h-full w-full inline-flex items-end leading-none gap-[5px] min-w-[150px] justify-between outline-none border-b-rock border-b-2"
            onKeyDown={(e) => {
              if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                e.preventDefault();
              }
            }}
          >
            <Select.Value
              placeholder="Select intended purpose"
              className="text-gray-400"
            >
              {purpose ? (
                INTENDED_PURPOSES.find((p) => p.value === purpose)?.label
              ) : (
                <span className="text-gray-400">Select intended purpose</span>
              )}
            </Select.Value>
            <Select.Icon>
              <ChevronDownIcon className="w-4 h-4 text-gray-400" />
            </Select.Icon>
          </Select.Trigger>

          <Select.Portal>
            <Select.Content
              className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden"
              position="popper"
              sideOffset={5}
            >
              <Select.Viewport className="p-1">
                {INTENDED_PURPOSES.map((option) => (
                  <Select.Item
                    key={option.value}
                    value={option.value}
                    className="hover:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                  >
                    <Select.ItemText>{option.label}</Select.ItemText>
                  </Select.Item>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
        {touched.purpose && errors.purpose && (
          <span className="text-sm text-red-500 mt-1">{errors.purpose}</span>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <Select.Root
          value={sourceOfFunds}
          onValueChange={handleSourceOfFundsChange}
        >
          <Select.Trigger className="text-pebble dark:text-snow py-2 h-full w-full inline-flex items-end leading-none gap-[5px] min-w-[150px] justify-between outline-none border-b-rock border-b-2">
            <Select.Value
              placeholder="Select source of funds"
              className="text-gray-400"
            >
              {sourceOfFunds ? (
                SOURCE_OF_FUNDS.find((s) => s.value === sourceOfFunds)?.label
              ) : (
                <span className="text-gray-400">Select source of funds</span>
              )}
            </Select.Value>
            <Select.Icon>
              <ChevronDownIcon className="w-4 h-4 text-gray-400" />
            </Select.Icon>
          </Select.Trigger>

          <Select.Portal>
            <Select.Content
              className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden z-50"
              position="popper"
              sideOffset={5}
            >
              <Select.Viewport className="p-1">
                {SOURCE_OF_FUNDS.map((option) => (
                  <Select.Item
                    key={option.value}
                    value={option.value}
                    className="hover:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                  >
                    <Select.ItemText>{option.label}</Select.ItemText>
                  </Select.Item>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
        {touched.sourceOfFunds && errors.sourceOfFunds && (
          <span className="text-sm text-red-500 mt-1">
            {errors.sourceOfFunds}
          </span>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-base">
          Is the business registered as a Money Services Business
          <a
            href="https://fintrac-canafe.canada.ca/msb-esm/msb-eng"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center ml-1 text-blue-600 hover:text-blue-800"
          >
            (MSB)
          </a>
          :
        </p>
        <RadioGroup.Root
          value={msbReg === null ? "" : msbReg.toString()}
          onValueChange={(value) => handleMsbChange(value === "true")}
          className="flex items-center gap-8"
        >
          <div className="flex items-center space-x-3">
            <RadioGroup.Item
              value="true"
              id="msb-yes"
              className="w-4 h-4 rounded-full border border-gray-300 dark:border-gray-600"
            >
              <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-2 after:h-2 after:rounded-full after:bg-black dark:after:bg-white" />
            </RadioGroup.Item>
            <label
              htmlFor="msb-yes"
              className="text-md text-gray-900 dark:text-gray-100"
            >
              Yes
            </label>
          </div>

          <div className="flex items-center space-x-3">
            <RadioGroup.Item
              value="false"
              id="msb-no"
              className="w-4 h-4 rounded-full border border-gray-300 dark:border-gray-600"
            >
              <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-2 after:h-2 after:rounded-full after:bg-black dark:after:bg-white" />
            </RadioGroup.Item>
            <label
              htmlFor="msb-no"
              className="text-md text-gray-900 dark:text-gray-100"
            >
              No
            </label>
          </div>
        </RadioGroup.Root>
        {touched.msbReg && errors.msbReg && (
          <span className="text-sm text-red-500 mt-1">{errors.msbReg}</span>
        )}
      </div>
      <Button
        onClick={handleSubmit}
        disabled={isSubmitting || !isFormValid}
        className="mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
      >
        {isSubmitting ? "Saving..." : "Continue"}
      </Button>
    </div>
  );
}
