import { useState, PropsWithChildren, useEffect } from "react";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { useSignupStore } from "@/reducers";
import { ManualCheck } from "./ManualCheck/ManualCheck";
import { OliuCheck } from "./OliuCheck";
import { SoftCheck } from "./SoftCheck";

type VerificationTypes = "MANUAL" | "EQUIFAX" | "OLIU_ID" | "OLIU_BANK" | null;

interface DropdownItemProps extends PropsWithChildren {
  title: string;
  onSelect: () => void;
}

const DropdownItem = ({ title, children, onSelect }: DropdownItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative border rounded-md border-pebble dark:border-white mt-px overflow-visible first:mt-0">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-black dark:text-white group flex h-[55px] w-full cursor-default items-center justify-between px-5 text-base leading-none border-b border-pebble/50 dark:border-white/50 outline-none font-bold uppercase"
      >
        {title}
        <ChevronDownIcon
          className={`text-black dark:text-white ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
          aria-hidden
        />
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 w-full bg-white dark:bg-black border border-pebble dark:border-white rounded-b-md shadow-lg z-50">
          <div className="text-black dark:text-white p-5">{children}</div>
          <Button
            type="button"
            style={{ display: "block", width: "100%" }}
            onClick={() => {
              setIsOpen(false);
              onSelect();
            }}
          >
            CONTINUE
          </Button>
        </div>
      )}
    </div>
  );
};

const OrDivider = () => (
  <div className="flex items-center justify-center my-4">
    <span className="text-black dark:text-white">or</span>
  </div>
);

export default function SignupKYCQuestions() {
  const navigate = useNavigate();
  const [verificationType, setVerificationType] =
    useState<VerificationTypes>(null);
  const enabledEquifax =
    import.meta.env.VITE_ENABLE_EQUIFAX_VERIFICATION === "TRUE" ? true : false;
  const enabledManual =
    import.meta.env.VITE_ENABLE_MANUAL_VERIFICATION === "TRUE" ? true : false;
  const enabledIdVerify =
    import.meta.env.VITE_ENABLE_OLIU_ID_VERIFICATION === "TRUE" ? true : false;
  const enabledBankVerify =
    import.meta.env.VITE_ENABLE_OLIU_BANK_VERIFICATION === "TRUE"
      ? true
      : false;
  const useIframe =
    import.meta.env.VITE_OLIU_MODE_IFRAME === "TRUE" ? true : false;
  const setOliuVerifyType = useSignupStore.use.setOliuVerifyType();
  // const [oliuPhoneVerified, setOliuPhoneVerified] = [
  //   useSignupStore.use.oliuPhoneVerified(),
  //   useSignupStore.use.setOliuPhoneVerified(),
  // ];

  const setEmail = useSignupStore.use.setEmail();
  const urlParams = new URLSearchParams(window.location.search);
  const emailParams = urlParams.get("email");
  if (emailParams) {
    console.log("OVERRIDE EMAIL IN STATE:", emailParams);
    setEmail(emailParams);
  }

  if (!verificationType) {
    return (
      <div className="flex flex-col gap-5">
        <div className="text-lg font-bold uppercase">Verification</div>
        <div className="text-base">
          Select the verification type you'd like to use:
        </div>
        <div className="w-full flex flex-col gap-5">
          {enabledEquifax && (
            <DropdownItem
              title="EQUIFAX VERIFICATION"
              onSelect={() => setVerificationType("EQUIFAX")}
            >
              <div>
                Answer multiple choice questions from your credit report to
                verify your identity. Your credit score will not be impacted.
                You must have 3+ years of credit history in Canada. This secure
                process ensures that your information is never shared with
                external sources.
              </div>
            </DropdownItem>
          )}
          {enabledEquifax && <OrDivider />}

          {enabledManual && (
            <DropdownItem
              title="MANUAL VERIFICATION"
              onSelect={() => setVerificationType("MANUAL")}
            >
              <div>Manual Verification Process</div>
            </DropdownItem>
          )}
          {enabledManual && <OrDivider />}

          {/* {enabledBankVerify && oliuPhoneVerified && ( */}
          {enabledBankVerify && (
            <DropdownItem
              title="BANK VERIFICATION"
              onSelect={() => {
                setOliuVerifyType("bank");
                if (useIframe) {
                  navigate("/OliuIFrame");
                } else {
                  setVerificationType("OLIU_BANK");
                }
              }}
            >
              <div>
                Sign in to your bank to verify your identity. None of your
                banking information will be shared with Paytrie. This secure
                process ensures that your information is never shared with
                external sources.
              </div>
            </DropdownItem>
          )}
          {/* {enabledBankVerify && oliuPhoneVerified && <OrDivider />} */}
          {enabledBankVerify && <OrDivider />}

          {enabledIdVerify && (
            <DropdownItem
              title="ID VERIFICATION"
              onSelect={() => {
                setOliuVerifyType("document");
                setVerificationType("OLIU_ID");
              }}
            >
              <div>
                Upload and verify your identity documents securely. This secure
                process ensures that your information is never shared with
                external sources.
              </div>
            </DropdownItem>
          )}
        </div>
      </div>
    );
  }

  if (verificationType === "MANUAL") {
    return <ManualCheck />;
  }

  if (verificationType === "EQUIFAX") {
    return <SoftCheck />;
  }

  if (verificationType === "OLIU_ID") {
    return <OliuCheck />;
  }

  if (verificationType === "OLIU_BANK") {
    return <OliuCheck />;
  }
}
