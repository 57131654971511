import { useState } from "react";
import BuyForm from "./BuyForm";
import SellForm from "./SellForm";
import BankAccountForm from "./BankAccountForm";
import OrderSummary from "./OrderSummary";

const OrderForm = () => {
  const [activeTab, setActiveTab] = useState("buy");
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [, setCurrency] = useState("");
  const [formData, setFormData] = useState({});
  const [prevViews, setPrevViews] = useState<string[]>([]);

  const goBack = () => {
    if (prevViews.length > 0) {
      const lastView = prevViews.pop();
      setPrevViews([...prevViews]);
      if (lastView === "buy") {
        setShowOrderSummary(false);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:space-x-8">
      <div className="md:px-12">
        <h1 className="text-2xl md:text-3xl font-bold text-left mb-4">OTC</h1>
        <p className="text-sm md:text-base dark:text-white text-left mb-4">
          Personalized service for $10K+ transactions
        </p>
      </div>

      <div className="sticky top-0 px-4 z-20 border-b dark:border-white">
        <div className="flex flex-wrap space-x-4 md:space-x-12 text-2xl md:text-3xl font-bold">
          {[
            { key: "buy", label: "Buy" },
            { key: "sell", label: "Sell" },
            { key: "bankAccount", label: "Bank Account" },
          ].map(({ key, label }) => (
            <button
              key={key}
              onClick={() => {
                setActiveTab(key);
                setShowOrderSummary(false);
                setPrevViews([]);
              }}
              className={`pb-2 ${
                activeTab === key || (key === "buy" && showOrderSummary)
                  ? "border-b-2 border-clementine dark:border-sun"
                  : "text-gray-500"
              }`}
            >
              {label}
            </button>
          ))}
        </div>
      </div>

      <div className="flex-1 overflow-visible p-1 md:p-10">
        {showOrderSummary && (
          <button
            onClick={goBack}
            className="mb-4 text-lg font-medium dark:text-white hover:underline"
          >
            ← Back
          </button>
        )}
        {showOrderSummary ? (
          <OrderSummary
            setShowOrderSummary={(show) => {
              if (show) setPrevViews((prev) => [...prev, "buy"]);
              setShowOrderSummary(show);
            }}
            formData={formData}
          />
        ) : (
          <form className="space-y-8">
            {activeTab === "buy" && (
              <BuyForm
                setCurrency={setCurrency}
                setShowOrderSummary={(show) => {
                  if (show) setPrevViews((prev) => [...prev, "buy"]);
                  setShowOrderSummary(show);
                }}
                setFormData={setFormData}
              />
            )}
            {activeTab === "sell" && (
              <SellForm
                setCurrency={setCurrency}
                onAddBankAccount={() => setActiveTab("bankAccount")}
              />
            )}
            {activeTab === "bankAccount" && <BankAccountForm />}
          </form>
        )}
      </div>
    </div>
  );
};

export default OrderForm;
