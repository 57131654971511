import React, { useContext, useCallback } from "react";
import { truncateAddress } from "@/utils/currencies";
import {
  address,
  pipe,
  appendTransactionMessageInstruction,
  createTransactionMessage,
  setTransactionMessageFeePayerSigner,
  setTransactionMessageLifetimeUsingBlockhash,
  signAndSendTransactionMessageWithSigners,
  assertIsTransactionMessageWithSingleSendingSigner,
  getBase58Decoder,
} from "@solana/kit";
import { useWalletAccountTransactionSendingSigner } from "@solana/react";
import { type UiWalletAccount } from "@wallet-standard/react";
import { RpcContext } from "@/contexts/SolanaRpcContext";
import { ChainContext } from "@/contexts/SolanaChainContextProvider";
import {
  findAssociatedTokenPda,
  getTransferInstruction,
} from "@solana-program/token";

import * as Sentry from "@sentry/react";

interface UnifiedWalletButtonProps {
  amount: number;
  recipientAddress: string;
  tokenSymbol: string;
  senderAddress: string;
  orderId: string;
  onTransferComplete?: (txHash: string) => void;
  onError?: (error: Error) => void;
}

export const ConnectedSolanaWalletButton: React.FC<{
  selectedAccount: UiWalletAccount;
  props: UnifiedWalletButtonProps;
  showToast: (
    title: string,
    description: string,
    type: "info" | "warning" | "success"
  ) => void;
  setWalletDialogOpen: (open: boolean) => void;
  disconnectCurrentWallet: () => void;
  isSolanaProcessing: boolean;
  setIsSolanaProcessing: (processing: boolean) => void;
}> = ({
  selectedAccount,
  props,
  showToast,
  setWalletDialogOpen,
  disconnectCurrentWallet,
  isSolanaProcessing,
  setIsSolanaProcessing,
}) => {
  const { chain: currentChain } = useContext(ChainContext);
  const { rpc } = useContext(RpcContext);

  // This hook is now safely called with a valid selectedAccount
  const transactionSendingSigner = useWalletAccountTransactionSendingSigner(
    selectedAccount,
    currentChain
  );

  // Check if this is the wrong wallet
  const wrongSolanaWallet = selectedAccount.address !== props.senderAddress;

  // Handle Solana transfer with a valid signer
  const handleSolanaTransfer = useCallback(async () => {
    // First check if we're using the correct account
    if (wrongSolanaWallet) {
      showToast(
        "Wrong Account Selected",
        `This transaction requires account ${truncateAddress(props.senderAddress)}, but you're connected with ${truncateAddress(selectedAccount.address)}`,
        "warning"
      );
      disconnectCurrentWallet();
      setWalletDialogOpen(true);
      return;
    }

    if (!transactionSendingSigner) {
      showToast(
        "Wallet Connection Error",
        "There was an issue with your wallet connection. Please try reconnecting.",
        "warning"
      );
      return;
    }

    try {
      // Add initial breadcrumb
      Sentry.addBreadcrumb({
        category: "wallet",
        message: "Starting Solana transfer",
        data: {
          amount: props.amount,
          tokenSymbol: props.tokenSymbol,
          orderId: props.orderId,
          senderAddress: props.senderAddress,
          recipientAddress: props.recipientAddress,
        },
        level: "info",
      });

      // Set transaction state
      setIsSolanaProcessing(true);

      // Get latest blockhash
      let latestBlockhash;
      try {
        const response = await rpc
          .getLatestBlockhash({ commitment: "confirmed" })
          .send();
        latestBlockhash = response.value;
      } catch (error) {
        console.error("Failed to get latest blockhash:", error);
        throw new Error("Failed to get latest blockhash. Please try again.");
      }

      // Convert amount to bigint (based on token decimals, USDC has 6 decimals)
      const amountInBaseUnits = BigInt(Math.round(props.amount * 1_000_000));

      // Constants for Solana token system
      const USDC_MINT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
      const TOKEN_PROGRAM = "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
      const CLI_CREATED_ATA = "3pJTdiBQANMmRz7a5F7CRiqibPMhTSSpeazdNNkV2a6E";

      // Fetch source token account
      const sourceTokenAccountsResponse = await rpc
        .getTokenAccountsByOwner(
          address(selectedAccount.address),
          { mint: address(USDC_MINT) },
          { encoding: "jsonParsed" }
        )
        .send();

      if (!sourceTokenAccountsResponse?.value?.length) {
        throw new Error("Sender USDC token account not found");
      }

      // Source token account
      const sourceATA = await findAssociatedTokenPda({
        owner: address(transactionSendingSigner.address),
        tokenProgram: address(TOKEN_PROGRAM),
        mint: address(USDC_MINT),
      });

      // Create transaction message
      let transactionMessage = pipe(
        createTransactionMessage({ version: 0 }),
        (tx) =>
          setTransactionMessageFeePayerSigner(transactionSendingSigner, tx),
        (tx) => setTransactionMessageLifetimeUsingBlockhash(latestBlockhash, tx)
      );

      // Create the transfer instruction to the CLI-created account
      const transferInstruction = getTransferInstruction({
        source: sourceATA[0],
        destination: address(CLI_CREATED_ATA), // Use the CLI-created account directly
        authority: transactionSendingSigner,
        amount: amountInBaseUnits,
      });
      // Add transfer instruction to transaction
      transactionMessage = appendTransactionMessageInstruction(
        transferInstruction,
        transactionMessage
      );
      console.log(
        "Transaction message created with instructions count:",
        transactionMessage.instructions.length
      );

      // Sign and send the transfer transaction
      console.log("Signing and sending transfer transaction to CLI account...");

      const signedTransaction =
        await signAndSendTransactionMessageWithSigners(transactionMessage);
      assertIsTransactionMessageWithSingleSendingSigner(transactionMessage);

      const signature = getBase58Decoder().decode(signedTransaction);
      console.log(
        "Transfer transaction sent successfully! Signature:",
        signedTransaction
      );

      if (props.onTransferComplete) {
        props.onTransferComplete(signature);
      }

      showToast(
        "Transaction Complete!",
        "Your transaction has been confirmed.",
        "success"
      );
    } catch (error: any) {
      console.error("Solana transfer error:", error);
      showToast(
        "Transaction Failed",
        error?.message ||
          "An error occurred while processing your transaction.",
        "warning"
      );

      if (props.onError) {
        props.onError(error as Error);
      }

      // Capture error with Sentry
      Sentry.captureException(error, {
        tags: {
          action: "solana_transaction",
          recipient_address: props.recipientAddress,
          currentChain: currentChain,
          signer: transactionSendingSigner.address,
          senderAddress: props.senderAddress,
        },
      });
    } finally {
      setIsSolanaProcessing(false);
    }
  }, [
    transactionSendingSigner,
    rpc,
    props.amount,
    props.recipientAddress,
    props.tokenSymbol,
    props.orderId,
    props.senderAddress,
    props.onTransferComplete,
    props.onError,
    showToast,
    setIsSolanaProcessing,
  ]);

  // Handle button click for connected wallet
  const handleConnectedWalletClick = useCallback(() => {
    if (wrongSolanaWallet) {
      // If wrong wallet, ask if user wants to disconnect
      showToast(
        "Wrong Wallet Connected",
        `Connected to ${truncateAddress(selectedAccount.address)} but need ${truncateAddress(props.senderAddress)}`,
        "warning"
      );
      disconnectCurrentWallet();
    } else {
      showToast(
        "Transaction In Progress",
        "Please do not refresh the page or submit another transaction until this one completes.",
        "info"
      );

      // Call the handler directly
      handleSolanaTransfer();
    }
  }, [
    wrongSolanaWallet,
    selectedAccount.address,
    props.senderAddress,
    showToast,
    disconnectCurrentWallet,
    setWalletDialogOpen,
    handleSolanaTransfer,
  ]);

  return (
    <button
      onClick={handleConnectedWalletClick}
      disabled={isSolanaProcessing}
      className={`inline-flex items-center justify-center py-6 px-4 w-full text-center border-none dark:bg-gray-200 bg-black dark:text-black text-white`}
    >
      <span>{`Send ${props.amount} ${props.tokenSymbol}`}</span>
    </button>
  );
};
