import axios from "./axios";

const loginEndpoints = {
  LOGIN: "/login",
  PASSWORDLESS_LOGIN: "/secureLogin",
  SECURE_CHECK: "/secureCheck",
  SECURE_VERIFY: "/secureVerify",
};

type LoginSuccessResponse = {
  status: string;
  token: string;
};

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post<LoginSuccessResponse>(
      loginEndpoints.LOGIN,
      {
        email,
        password,
      }
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const triggerPasswordlessLogin = (email: string, nonce: number, path: string) => {
  return axios.post(
    loginEndpoints.PASSWORDLESS_LOGIN,
    {},
    {
      params: {
        email,
        unixTime: nonce,
        path,
      },
    }
  );
};

export const completePasswordlessLogin = async (
  email: string,
  nonce: number
) => {
  try {
    const response = await axios.post<LoginSuccessResponse>(
      loginEndpoints.SECURE_CHECK,
      {},
      {
        params: {
          email,
          unixTime: nonce,
        },
        "axios-retry": {
          retries: 10,
          retryDelay: () => 5000,
        },
      }
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const apiKeyLogin = () => {
  return Promise.resolve();
};

export const verifyEmail = async (email: string, id: string) => {
  try {
    const response = await axios.post<LoginSuccessResponse>(
      loginEndpoints.SECURE_VERIFY,
      {},
      {
        params: {
          email,
          id,
        },
      }
    );
    return { response };
  } catch (error: any) {
    return { errorMessage: error.response.data.message };
  }
};
