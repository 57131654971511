import EthereumLogo from "../assets/networks/ethereum-eth-logo.png";
import PolygonLogo from "../assets/networks/polygon-matic-logo.png";
import ArbitrumLogo from "../assets/networks/arbitrum-arb-logo.png";
import BinanceLogo from "../assets/networks/bnb-bnb-logo.png";
import OptimismLogo from "../assets/networks/optimism-ethereum-op-logo.png";
import BaseLogo from "../assets/networks/Base_Symbol_Blue.png";
import SXLogo from "../assets/networks/sx-network-logo.png";
import SOLLogo from "../assets/img/icons/cryptoicons/SVG/sol.svg";
import AVAXLogo from "../assets/img/icons/cryptoicons/SVG/avax.svg";
import CELOLogo from "../assets/celo_network.svg";
import CADARB from "../assets/currency/CADC_ARB.png";
import CADBASE from "../assets/currency/CADC_BASE.png";
import CADETH from "../assets/CADC_ETH.png";
import CADPOLY from "../assets/CADC_POLY.png";
import USDPOLY from "../assets/currency/USDC_POLY.png";
import USDETH from "../assets/currency/USDC_ETH.png";
import USDARB from "../assets/currency/USDC_ARB.png";
import USDCAVAX from "../assets/currency/USDC_AVAX.png";
import USDCCELO from "../assets/currency/USDC_CELO.png";
import USDCBN from "../assets/currency/USDC_BSC.png";
import USDCBASE from "../assets/currency/USDC_BASE.png";
import USDCOPTI from "../assets/currency/USDC_OP.png";
import USDCSX from "../assets/currency/USDC_SX.png";
import CADC from "../assets/currency/CADC2.svg";
import USDC from "../assets/img/icons/cryptoicons/SVG/usdc.svg";
import USDCSOL from "../assets/currency/USDC_SOL.png";
import CAD from "../assets/currency/cad.png";
import {
  mainnet,
  arbitrum,
  optimism,
  polygon,
  base,
  bsc,
  avalanche,
  celo,
} from "viem/chains";
import { http } from "viem";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { createConfig } from "wagmi";
import {
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  uniswapWallet,
  trustWallet,
} from "@rainbow-me/rainbowkit/wallets";

type NetworkOption = {
  name: string;
  icon: string;
};

export const Networks = {
  ethereum: "ETH",
  polygon: "POLY",
  arbitrum: "ARB",
  binance: "BSC",
  optimism: "OP",
  sx: "SX",
  sol: "SOL",
  base: "BASE",
  avalanche: "AVAX",
  celo: "CELO",
} as const;

export type NetworkValue = (typeof Networks)[keyof typeof Networks];

export const NetworkOptions: Record<NetworkValue, NetworkOption> = {
  [Networks.ethereum]: {
    name: "Ethereum",
    icon: EthereumLogo,
  },
  [Networks.sol]: {
    name: "Solana",
    icon: SOLLogo,
  },
  [Networks.binance]: {
    name: "BSC",
    icon: BinanceLogo,
  },
  [Networks.polygon]: {
    name: "Polygon",
    icon: PolygonLogo,
  },
  [Networks.base]: {
    name: "Base",
    icon: BaseLogo,
  },
  [Networks.arbitrum]: {
    name: "Arbitrum",
    icon: ArbitrumLogo,
  },
  [Networks.optimism]: {
    name: "Optimism",
    icon: OptimismLogo,
  },
  [Networks.avalanche]: {
    name: "Avalanche",
    icon: AVAXLogo,
  },
  [Networks.celo]: {
    name: "Celo",
    icon: CELOLogo,
  },
  [Networks.sx]: {
    name: "SX",
    icon: SXLogo,
  },
};

const chains = [
  mainnet,
  optimism,
  arbitrum,
  base,
  bsc,
  polygon,
  avalanche,
  celo,
] as const;

const connectors = connectorsForWallets(
  [
    {
      groupName: "Suggested",
      wallets: [
        rainbowWallet,
        metaMaskWallet,
        coinbaseWallet,
        walletConnectWallet,
        uniswapWallet,
        trustWallet,
      ],
    },
  ],
  {
    appName: "Paytrie",
    projectId:
      import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID ||
      "21627e108fd6feb1af48c3eb190400f2",
  }
);

export const rainbowKitConfig = createConfig({
  chains,
  transports: {
    [mainnet.id]: http(),
    [optimism.id]: http(),
    [arbitrum.id]: http(),
    [base.id]: http(),
    [bsc.id]: http(),
    [polygon.id]: http(),
    [avalanche.id]: http(),
    [celo.id]: http(),
  },
  connectors,
});

export function getNetwork(abr: string) {
  switch (abr) {
    case "ETH":
      return Networks.ethereum;
    case "POLY":
      return Networks.polygon;
    case "ARB":
      return Networks.arbitrum;
    case "BSC":
      return Networks.binance;
    case "OP":
      return Networks.optimism;
    case "SX":
      return Networks.sx;
    case "SOL":
      return Networks.sol;
    case "BASE":
      return Networks.base;
    case "AVAX":
      return Networks.avalanche;
    case "CELO":
      return Networks.celo;
    default:
      return Networks.ethereum;
  }
}

export const StableCoins = {
  CADC: "CADC",
  USDC: "USDC",
  // USDT: 'USDT',
  // DAI: 'DAI',
  // BUSD: 'BUSD',
} as const;

export type StableCoinValue = (typeof StableCoins)[keyof typeof StableCoins];

export const isStableCoin = (x: any): x is StableCoinValue =>
  Object.values(StableCoins).includes(x);

export function getStableCoinType(abr: string) {
  switch (abr) {
    case "USDC":
      return StableCoins.USDC;
    case "CADC":
      return StableCoins.CADC;
    default:
      return StableCoins.USDC;
  }
}

export const supportedCurrencies: Record<NetworkValue, StableCoinValue[]> = {
  [Networks.ethereum]: [StableCoins.CADC, StableCoins.USDC],
  [Networks.arbitrum]: [StableCoins.CADC, StableCoins.USDC],
  [Networks.polygon]: [StableCoins.CADC, StableCoins.USDC],
  [Networks.optimism]: [StableCoins.USDC],
  [Networks.sx]: [StableCoins.USDC],
  [Networks.sol]: [StableCoins.USDC],
  [Networks.binance]: [StableCoins.USDC],
  [Networks.base]: [StableCoins.CADC, StableCoins.USDC],
  [Networks.avalanche]: [StableCoins.USDC],
  [Networks.celo]: [StableCoins.USDC],
};

export const FiatCurrency = {
  CAD: "CAD",
  USD: "USD",
};

export const isFiat = (x: any) => Object.values(FiatCurrency).includes(x);

export const PaymentTypes = {
  REQUEST: "request",
  ETRANSFER: "e-transfer",
} as const;

export type PaymentType = (typeof PaymentTypes)[keyof typeof PaymentTypes];

export interface CoinOption {
  value: string;
  name: string;
  description: string;
  shortDescription?: string;
  image?: string;
}

export interface StableCoinOption extends CoinOption {
  value: StableCoinValue;
}

export const coinOptions: CoinOption[] = [
  {
    value: PaymentTypes.REQUEST,
    name: "CAD",
    description: "Interac Request Money Transfer",
    shortDescription: "Interac",
    image: CAD,
  },
  // {
  //   value: PaymentTypes.ETRANSFER,
  //   name: 'CAD',
  //   description: 'Interac e-Transfer',
  // },
];

export const allTokenOptions: StableCoinOption[] = [
  {
    value: StableCoins.CADC,
    name: "CADC",
    description: "",
    image: CADC,
  },
  {
    value: StableCoins.USDC,
    name: "USDC",
    description: "",
    image: USDC,
  },
  // {
  //   value: StableCoins.DAI,
  //   name: 'DAI',
  //   description: 'Coin',
  // },
  // {
  //   value: StableCoins.BUSD,
  //   name: 'BUSD',
  //   description: 'Coin',
  // },
  // {
  //   value: StableCoins.USDT,
  //   name: 'USDT',
  //   description: 'Coin',
  // },
];

export const stableCoinLogos = {
  [`${StableCoins.CADC}${Networks.arbitrum}`]: CADARB,
  [`${StableCoins.CADC}${Networks.base}`]: CADBASE,
  [`${StableCoins.CADC}${Networks.ethereum}`]: CADETH,
  [`${StableCoins.CADC}${Networks.polygon}`]: CADPOLY,
  [`${StableCoins.USDC}${Networks.polygon}`]: USDPOLY,
  [`${StableCoins.USDC}${Networks.ethereum}`]: USDETH,
  [`${StableCoins.USDC}${Networks.arbitrum}`]: USDARB,
  [`${StableCoins.USDC}${Networks.optimism}`]: USDCOPTI,
  [`${StableCoins.USDC}${Networks.sx}`]: USDCSX,
  [`${StableCoins.USDC}${Networks.sol}`]: USDCSOL,
  [`${StableCoins.USDC}${Networks.binance}`]: USDCBN,
  [`${StableCoins.USDC}${Networks.base}`]: USDCBASE,
  [`${StableCoins.USDC}${Networks.avalanche}`]: USDCAVAX,
  [`${StableCoins.USDC}${Networks.celo}`]: USDCCELO,
};

export const BUY_MAX_LIMIT_AMT: number = parseInt(
  import.meta.env.VITE_BUY_MAX_LIMIT_AMT
);
export const SELL_MAX_LIMIT_AMT: number = parseInt(
  import.meta.env.VITE_SELL_MAX_LIMIT_AMT
);
export const MIN_LIMIT_AMT: number = parseInt(
  import.meta.env.VITE_MIN_LIMIT_AMT
);
export const FEERATE: number = parseFloat(import.meta.env.VITE_FEERATE);
export const FEERATECADC: number = parseFloat(import.meta.env.VITE_FEERATECADC);
export const MINCADCFEE: number = parseInt(import.meta.env.VITE_MINCADCFEE);
export const MINUSDCFEE: number = parseInt(import.meta.env.VITE_MINUSDCFEE);

type CADCNetworks = Extract<NetworkValue, "ETH" | "POLY" | "ARB" | "BASE">;
type USDCNetworks = Extract<
  NetworkValue,
  "ETH" | "POLY" | "ARB" | "BSC" | "OP" | "BASE" | "AVAX" | "CELO"
>;

type TokenAddressMap = {
  [StableCoins.CADC]: Record<CADCNetworks, `0x${string}`>;
  [StableCoins.USDC]: Record<USDCNetworks, `0x${string}`>;
};

type TokenDecimalMap = {
  [StableCoins.CADC]: Record<CADCNetworks, number>;
  [StableCoins.USDC]: Record<USDCNetworks, number>;
};

export const TokenAddresses: TokenAddressMap = {
  [StableCoins.CADC]: {
    [Networks.ethereum]: "0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef",
    [Networks.polygon]: "0x9de41aFF9f55219D5bf4359F167d1D0c772A396D",
    [Networks.arbitrum]: "0x2b28E826b55e399F4d4699b85f68666AC51e6f70",
    [Networks.base]: "0x043eb4b75d0805c43d7c834902e335621983cf03",
  },
  [StableCoins.USDC]: {
    [Networks.ethereum]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    [Networks.polygon]: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    [Networks.arbitrum]: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    [Networks.binance]: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    [Networks.optimism]: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    [Networks.base]: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    [Networks.avalanche]: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    [Networks.celo]: "0xcebA9300f2b948710d2653dD7B07f33A8B32118C",
  },
} as const;

export const ChainIds = {
  ETH: 1,
  POLY: 137,
  ARB: 42161,
  BSC: 56,
  OP: 10,
  BASE: 8453,
  AVAX: 43114,
  CELO: 42220,
} as const;

export const TokenDecimals: TokenDecimalMap = {
  [StableCoins.CADC]: {
    ETH: 18,
    POLY: 18,
    ARB: 18,
    BASE: 18,
  },
  [StableCoins.USDC]: {
    ETH: 6,
    POLY: 6,
    ARB: 6,
    BSC: 18,
    OP: 6,
    BASE: 6,
    AVAX: 6,
    CELO: 6,
  },
} as const;

export function getTokenAddress(
  chainId: number,
  tokenSymbol: string
): `0x${string}` {
  const [baseToken, network] = tokenSymbol.split("-");

  if (!isStableCoin(baseToken)) {
    throw new Error(`Invalid token: ${baseToken}`);
  }

  // Validate the chainId matches the network in the token symbol
  validateChainIdNetwork(chainId, network);

  const networkValue = getNetworkFromChainId(chainId);
  const addresses = TokenAddresses[baseToken];

  if (!(networkValue in addresses)) {
    throw new Error(
      `${baseToken} is not supported on network with chainId ${chainId}`
    );
  }

  return addresses[networkValue as keyof typeof addresses];
}

// Helper function to get network from chainId
function getNetworkFromChainId(chainId: number): NetworkValue {
  const entry = Object.entries(ChainIds).find(([, id]) => id === chainId);
  if (!entry) {
    throw new Error(`Unsupported chainId: ${chainId}`);
  }
  return entry[0] as NetworkValue;
}

// Helper to verify chainId matches network string
function validateChainIdNetwork(chainId: number, network: string): void {
  const expectedChainId = ChainIds[network as keyof typeof ChainIds];
  if (expectedChainId !== chainId) {
    throw new Error(`ChainId ${chainId} does not match network ${network}`);
  }
}

export function getTokenDecimals(tokenSymbol: string): number {
  const [baseToken, network] = tokenSymbol.split("-");

  if (!isStableCoin(baseToken)) {
    throw new Error(`Invalid token: ${baseToken}`);
  }

  const networkValue = getNetwork(network);
  const decimals = TokenDecimals[baseToken];

  if (!(networkValue in decimals)) {
    throw new Error(
      `No decimal configuration found for ${baseToken} on ${network} network`
    );
  }

  return decimals[networkValue as keyof typeof decimals];
}

export function getChainId(tokenSymbol: string): number {
  // Extract network part from token symbol (e.g., "USDC-ETH" -> "ETH")
  const [, network] = tokenSymbol.split("-");

  if (!network) {
    throw new Error(`Invalid token symbol format: ${tokenSymbol}`);
  }

  const chainId = ChainIds[network as keyof typeof ChainIds];

  if (chainId === undefined) {
    throw new Error(`Unsupported network: ${network}`);
  }

  return chainId;
}

export function isTokenNetworkSupported(
  token: string,
  network: string
): boolean {
  try {
    if (!isStableCoin(token)) {
      return false;
    }

    const networkValue = getNetwork(network);
    const addresses = TokenAddresses[token];
    return networkValue in addresses;
  } catch {
    return false;
  }
}

export const truncateAddress = (address: string): string => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const isNetworkSupported = (network: string): boolean => {
  const supportedNetworks = [
    "ETH",
    "POLY",
    "ARB",
    "BSC",
    "OP",
    "BASE",
    "AVAX",
    "CELO",
    "SOL",
  ];
  return supportedNetworks.includes(network);
};

export const getNetworkName = (chainSymbol: string): string => {
  const networkNames: Record<string, string> = {
    ETH: "Ethereum",
    POLY: "Polygon",
    ARB: "Arbitrum",
    BSC: "BNB Chain",
    OP: "Optimism",
    BASE: "Base",
    AVAX: "Avalanche",
    CELO: "Celo",
    SX: "SX",
    SOL: "Solana",
  };
  return networkNames[chainSymbol] || chainSymbol;
};
