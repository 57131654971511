import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { TextInput } from "@/components/widget-components";
import { Checkbox } from "@/components/Checkbox";
import { BirthdayInput } from "@/components/Select/BirthdayInput";
import { ProvinceSelector } from "@/components/Select/ProvinceSelector";
import { useCorpStore } from "@/reducers/corporateReducer";
import { ROUTES } from "../CorporateSignupPanel";
import { TrashIcon } from "@radix-ui/react-icons";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useJsApiLoader } from "@react-google-maps/api";
import axiosInstance from "@/requests/axios";
import clsx from "clsx";
import * as RadioGroup from "@radix-ui/react-radio-group";

// Base interfaces
interface Address {
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  province: string;
  postalCode: string;
}

interface AuthorizedDirectorState {
  selectedDirector: string | null;
  authInfo: Director;
}

interface Director {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  occupation: string;
  address?: Address;
}

interface AuthorizedDirectorState {
  selectedDirector: string | null;
  authInfo: Director;
}

interface AuthErrors {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  occupation?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  submit?: string;
}

// Touched interface - matching DirectorInfo pattern
interface TouchedFields {
  firstName: boolean;
  lastName: boolean;
  dateOfBirth: boolean;
  occupation: boolean;
  streetAddress1: boolean;
  streetAddress2: boolean;
  city: boolean;
  province: boolean;
  postalCode: boolean;
}

const initialTouchedState: TouchedFields = {
  firstName: false,
  lastName: false,
  dateOfBirth: false,
  occupation: false,
  streetAddress1: false,
  streetAddress2: false,
  city: false,
  province: false,
  postalCode: false,
};

const isValidPostalCode = (code: string) => {
  const formattedCode = code.replace(/\s/g, "");
  return /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/.test(formattedCode);
};

export default function AuthorizedDirector() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<AuthErrors>({});
  const [touched, setTouched] = useState<TouchedFields>(initialTouchedState);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showNewSignerForm, setShowNewSignerForm] = useState<boolean>(false);
  const [showNewAddressForm, setShowNewAddressForm] = useState<boolean>(false);
  const [savedAddress, setSavedAddress] = useState<Address | null>(null);
  const [useExistingAddress, setUseExistingAddress] = useState<boolean>(false);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  // Single director selection state
  const [savedDirectors, setSavedDirectors] = useState<Director[]>([]);
  const [selectedDirector, setSelectedDirector] = useState<string | null>(null);

  const resetSignerForm = () => {
    setAuthFirstName("");
    setAuthLastName("");
    setAuthDateOfBirth("");
    setAuthOccupation("");
    setErrors((prev) => ({
      ...prev,
      additionalUsers: undefined,
    }));
    setTouched(initialTouchedState);
  };

  const resetAddressForm = () => {
    setAuthStreetAddress1("");
    setAuthStreetAddress2("");
    setAuthCity("");
    setAuthProvince("");
    setAuthPostalCode("");
    setErrors((prev) => ({
      ...prev,
      addresses: undefined,
    }));
    setTouched(initialTouchedState);
  };

  const handleShowNewSignerForm = () => {
    resetSignerForm();
    setShowNewSignerForm(true);
  };

  const handleShowNewAddressForm = () => {
    resetAddressForm();
    setShowNewAddressForm(true);
  };

  // Get all the state and actions from Redux store
  const {
    authFirstName,
    setAuthFirstName,
    authLastName,
    setAuthLastName,
    authDateOfBirth,
    setAuthDateOfBirth,
    authOccupation,
    setAuthOccupation,
    authStreetAddress1,
    setAuthStreetAddress1,
    authStreetAddress2,
    setAuthStreetAddress2,
    authCity,
    setAuthCity,
    authProvince,
    setAuthProvince,
    authPostalCode,
    setAuthPostalCode,
    nextState,
  } = useCorpStore();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`,
    libraries: ["places"],
  });

  const searchOptions = {
    componentRestrictions: { country: "ca" },
    types: ["address"],
  };

  const handleAddressSelect = (address: string) => {
    const addressObj: any = {};
    geocodeByAddress(address)
      .then((results) => {
        const addressComponents = results[0].address_components;
        for (const component of addressComponents) {
          for (const type of component.types) {
            addressObj[type] =
              type === "administrative_area_level_1"
                ? component.short_name
                : component.long_name;
          }
        }

        setAuthStreetAddress1(
          `${addressObj.street_number ? addressObj.street_number : ""} ${addressObj.route ? addressObj.route : ""}`.trim()
        );
        setAuthStreetAddress2(addressObj.subpremise || "");
        setAuthCity(addressObj.locality || "");
        setAuthProvince(addressObj.administrative_area_level_1 || "");
        setAuthPostalCode(
          addressObj.postal_code ? formatPostalCode(addressObj.postal_code) : ""
        );

        // Mark all address fields as touched when auto-filled
        setTouched((prev) => ({
          ...prev,
          streetAddress1: true,
          city: true,
          province: true,
          postalCode: true,
        }));

        // Validate the new address
        validateAddressFields({
          streetAddress1:
            `${addressObj.street_number ? addressObj.street_number : ""} ${addressObj.route ? addressObj.route : ""}`.trim(),
          city: addressObj.locality || "",
          province: addressObj.administrative_area_level_1 || "",
          postalCode: addressObj.postal_code || "",
        });
      })
      .catch((error) => {
        console.error("Error selecting address:", error);
        setErrors((prev) => ({
          ...prev,
          submit: "Error selecting address. Please try again.",
        }));
      });
  };

  const validateAddressFields = (addressData: {
    streetAddress1: string;
    city: string;
    province: string;
    postalCode: string;
  }) => {
    const newErrors: AuthErrors = {};

    if (!addressData.streetAddress1.trim()) {
      newErrors.streetAddress1 = "Street address is required";
    }
    if (!addressData.city.trim()) {
      newErrors.city = "City is required";
    }
    if (!addressData.province.trim()) {
      newErrors.province = "Province is required";
    }
    if (!addressData.postalCode.trim()) {
      newErrors.postalCode = "Postal code is required";
    } else if (!isValidPostalCode(addressData.postalCode)) {
      newErrors.postalCode = "Please enter a valid postal code (e.g., A1A 1A1)";
    }

    setErrors((prev) => ({
      ...prev,
      ...newErrors,
    }));

    return Object.keys(newErrors).length === 0;
  };

  // Validate form on field changes
  useEffect(() => {
    let isValid = true;

    // Validate selected director or new signer form
    if (!Object.values([selectedDirector]).some((selected) => selected)) {
      if (showNewSignerForm) {
        const signerErrors = {
          firstName: validateField("firstName", authFirstName),
          lastName: validateField("lastName", authLastName),
          dateOfBirth: validateField("dateOfBirth", authDateOfBirth),
          occupation: validateField("occupation", authOccupation),
        };

        if (Object.values(signerErrors).some((error) => error !== undefined)) {
          isValid = false;
        }

        // Update errors state
        setErrors((prev) => ({
          ...prev,
          ...signerErrors,
        }));
      } else {
        isValid = false;
      }
    }

    // Validate address
    if (!useExistingAddress && showNewAddressForm) {
      const addressErrors = {
        streetAddress1: validateField("streetAddress1", authStreetAddress1),
        city: validateField("city", authCity),
        province: validateField("province", authProvince),
        postalCode: validateField("postalCode", authPostalCode),
      };

      if (Object.values(addressErrors).some((error) => error !== undefined)) {
        isValid = false;
      }

      // Update errors state
      setErrors((prev) => ({
        ...prev,
        ...addressErrors,
      }));
    } else if (!useExistingAddress) {
      isValid = false;
    }

    setIsFormValid(isValid);
  }, [
    authFirstName,
    authLastName,
    authDateOfBirth,
    authOccupation,
    authStreetAddress1,
    authCity,
    authProvince,
    authPostalCode,
    selectedDirector,
    useExistingAddress,
    showNewSignerForm,
    showNewAddressForm,
    touched,
  ]);

  useEffect(() => {
    const authorizedInfoState = localStorage.getItem("authorizedInfoState");
    if (authorizedInfoState) {
      const { potentialSigners } = JSON.parse(authorizedInfoState) as {
        potentialSigners: Director[];
      };
      setSavedDirectors(potentialSigners || []);
    }

    const savedAddressData = localStorage.getItem("corporateAddressState");
    if (savedAddressData) {
      setSavedAddress(JSON.parse(savedAddressData) as Address);
    }

    const authorizedState = localStorage.getItem("authorizedDirectorsState");

    if (authorizedState) {
      const parsed = JSON.parse(authorizedState) as AuthorizedDirectorState;
      if (parsed.selectedDirector) {
        setSelectedDirector(parsed.selectedDirector);
      }

      if (parsed.authInfo) {
        const { authInfo } = parsed;
        setAuthFirstName(authInfo.firstName || "");
        setAuthLastName(authInfo.lastName || "");
        setAuthDateOfBirth(authInfo.dateOfBirth || "");
        setAuthOccupation(authInfo.occupation || "");
        if (authInfo.address) {
          setAuthStreetAddress1(authInfo.address.streetAddress1 || "");
          setAuthStreetAddress2(authInfo.address.streetAddress2 || "");
          setAuthCity(authInfo.address.city || "");
          setAuthProvince(authInfo.address.province || "");
          setAuthPostalCode(authInfo.address.postalCode || "");
        }
      }
    }
  }, []);

  const handleDirectorSelect = (directorFullName: string): void => {
    // Find the selected director from savedDirectors
    const director = savedDirectors.find(
      (d) => `${d.firstName} ${d.lastName}` === directorFullName
    );

    if (!director) {
      setSelectedDirector(null);
      resetSignerForm();
      return;
    }

    // Select the new director
    setSelectedDirector(directorFullName);
    setAuthFirstName(director.firstName);
    setAuthLastName(director.lastName);
    setAuthDateOfBirth(director.dateOfBirth);
    setAuthOccupation(director.occupation);

    if (director.address) {
      setAuthStreetAddress1(director.address.streetAddress1 || "");
      setAuthStreetAddress2(director.address.streetAddress2 || "");
      setAuthCity(director.address.city || "");
      setAuthProvince(director.address.province || "");
      setAuthPostalCode(director.address.postalCode || "");
    }

    setShowNewSignerForm(false);
  };

  const formatPostalCode = (code: string): string => {
    const cleaned = code.replace(/\s/g, "").toUpperCase();
    if (cleaned.length > 3) {
      return cleaned.slice(0, 3) + " " + cleaned.slice(3);
    }
    return cleaned;
  };

  const validateField = (field: string, value: string): string | undefined => {
    if (!touched[field as keyof TouchedFields]) {
      return undefined;
    }

    switch (field) {
      case "firstName":
      case "lastName":
        if (!value.trim()) {
          return `${field === "firstName" ? "First" : "Last"} name is required. Please enter the signer's ${field === "firstName" ? "first" : "last"} name.`;
        }
        if (value.trim().length < 2) {
          return `${field === "firstName" ? "First" : "Last"} name must be at least 2 characters long.`;
        }
        return undefined;

      case "occupation":
        if (!value.trim()) {
          return "Occupation is required. Please enter the signer's occupation.";
        }
        if (value.trim().length < 2) {
          return "Occupation must be at least 2 characters long.";
        }
        return undefined;

      case "dateOfBirth": {
        if (!value) {
          return "Date of birth is required. Please enter the signer's date of birth.";
        }

        const parts = value.split("-");
        if (parts.length === 3 && parts.every((part) => part !== "")) {
          const birthDate = new Date(value);
          const today = new Date();
          const age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();
          const isDayBeforeBirthday =
            monthDiff === 0 && today.getDate() < birthDate.getDate();
          const finalAge = monthDiff < 0 || isDayBeforeBirthday ? age - 1 : age;

          if (finalAge < 18) {
            return `Signer must be at least 18 years old. Current age based on date entered: ${finalAge} years.`;
          }
          if (finalAge > 120) {
            return "Date of birth indicates an age over 120 years. Please verify the date entered.";
          }
        } else {
          return "Incomplete date. Please enter a full date in YYYY-MM-DD format.";
        }
        return undefined;
      }

      case "streetAddress1":
        if (!value.trim()) {
          return "Street address is required. Please enter a valid street address.";
        }
        if (value.trim().length < 5) {
          return "Street address must be at least 5 characters long.";
        }
        return undefined;

      case "city":
        if (!value.trim()) {
          return "City is required. Please enter a valid city name.";
        }
        return undefined;

      case "province":
        if (!value) {
          return "Province is required. Please select a province.";
        }
        return undefined;

      case "postalCode": {
        if (!value) {
          return "Postal code is required. Please enter a valid postal code.";
        }
        const formattedCode = value.replace(/\s/g, "");
        if (!/^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/.test(formattedCode)) {
          return "Please enter a valid postal code format (e.g., A1A 1A1)";
        }
        return undefined;
      }

      default:
        return undefined;
    }
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    setTouched({
      firstName: true,
      lastName: true,
      dateOfBirth: true,
      occupation: true,
      streetAddress1: true,
      streetAddress2: true,
      city: true,
      province: true,
      postalCode: true,
    });

    const newErrors: AuthErrors = {};
    let hasErrors = false;

    if (showNewSignerForm) {
      ["firstName", "lastName", "dateOfBirth", "occupation"].forEach(
        (field) => {
          const value =
            {
              firstName: authFirstName,
              lastName: authLastName,
              dateOfBirth: authDateOfBirth,
              occupation: authOccupation,
            }[field] || "";

          const error = validateField(field, value);
          if (error) {
            newErrors[field as keyof AuthErrors] = error;
            hasErrors = true;
          }
        }
      );
    }

    if (selectedDirector && !useExistingAddress) {
      ["streetAddress1", "city", "province", "postalCode"].forEach((field) => {
        const value =
          {
            streetAddress1: authStreetAddress1,
            city: authCity,
            province: authProvince,
            postalCode: authPostalCode,
          }[field] || "";

        const error = validateField(field, value);
        if (error) {
          newErrors[field as keyof AuthErrors] = error;
          hasErrors = true;
        }
      });
    }

    setErrors(newErrors);

    if (hasErrors) return;

    setIsSubmitting(true);
    try {
      // Get corporation_email
      const corporateInfoState = localStorage.getItem("corporateInfoState");
      const corporateInfo = corporateInfoState
        ? JSON.parse(corporateInfoState)
        : {};
      const corporateEmail = corporateInfo.corporateEmail?.toLowerCase();

      if (!corporateEmail) {
        throw new Error("Corporate email not found");
      }

      let addressData;
      if (useExistingAddress && savedAddress) {
        addressData = {
          address1: savedAddress.streetAddress1.trim().toLowerCase(),
          address2: savedAddress.streetAddress2.trim().toLowerCase(),
          city: savedAddress.city.trim().toLowerCase(),
          province: savedAddress.province.trim().toLowerCase(),
          zipcode: savedAddress.postalCode.replace(/\s/g, "").toLowerCase(),
        };
      } else {
        addressData = {
          address1: authStreetAddress1.trim().toLowerCase(),
          address2: authStreetAddress2.trim().toLowerCase(),
          city: authCity.trim().toLowerCase(),
          province: authProvince.trim().toLowerCase(),
          zipcode: authPostalCode.replace(/\s/g, "").toLowerCase(),
        };
      }

      const authorized_user_data = {
        firstName: authFirstName.trim().toLowerCase(),
        lastName: authLastName.trim().toLowerCase(),
        dob: new Date(authDateOfBirth).toISOString().slice(0, 10),
        occupation: authOccupation.trim().toLowerCase(),
        ...addressData,
      };

      await axiosInstance({
        method: "post",
        url: "/updateCorporate",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          corporation_email: corporateEmail,
          authorized_user_data,
        },
      });

      nextState();
      navigate(ROUTES.CORPORATE_DOCUMENTS);
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        submit: "Unable to save your information. Please try again later.",
      }));
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    // Update the value based on the field
    switch (field) {
      case "firstName":
        setAuthFirstName(value);
        break;
      case "lastName":
        setAuthLastName(value);
        break;
      case "dateOfBirth":
        setAuthDateOfBirth(value);
        break;
      case "occupation":
        setAuthOccupation(value);
        break;
      case "streetAddress1":
        setAuthStreetAddress1(value);
        break;
      case "streetAddress2":
        setAuthStreetAddress2(value);
        break;
      case "city":
        setAuthCity(value);
        break;
      case "province":
        setAuthProvince(value);
        break;
      case "postalCode": {
        const formatted = formatPostalCode(value);
        if (formatted.length <= 7) {
          setAuthPostalCode(formatted);
        }
        break;
      }
    }

    // Mark field as touched
    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));

    // Validate and update errors
    const error = validateField(field, value);
    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }));
  };

  const handleAddressChange = (address: string) => {
    setAuthStreetAddress1(address);
    setTouched((prev) => ({
      ...prev,
      streetAddress1: true,
    }));
  };

  useEffect(() => {
    const handleDocumentKeyDown = async (e: KeyboardEvent) => {
      const isFormComplete = !isSubmitting && isFormValid;

      if (e.key === "Enter" && isFormComplete) {
        e.preventDefault();
        await handleSubmit();
      }
    };

    document.addEventListener("keydown", handleDocumentKeyDown);
    return () => {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    };
  }, [isSubmitting, isFormValid]);

  const handleRadioClick = (directorFullName: string, e: React.MouseEvent) => {
    // If clicking the currently selected director, prevent the radio event and deselect
    if (directorFullName === selectedDirector) {
      e.preventDefault();
      handleDirectorSelect("");
    }
  };

  return (
    <div className="flex flex-col h-full" tabIndex={-1}>
      <div className="flex-none space-y-6 mb-4">
        <div className="text-lg font-bold uppercase">ADD AUTHORIZED SIGNER</div>
      </div>

      <div className="flex-1 space-y-4">
        {/* Existing Directors Section */}
        <div className="space-y-4 p-2">
          <RadioGroup.Root
            value={selectedDirector || ""}
            onValueChange={handleDirectorSelect}
            className="flex flex-col gap-4"
          >
            {savedDirectors.map((director, idx) => {
              const directorFullName = `${director.firstName} ${director.lastName}`;
              return (
                <div key={idx} className="flex items-center space-x-3">
                  <RadioGroup.Item
                    value={directorFullName}
                    id={`director-${idx}`}
                    className="w-4 h-4 rounded-full border border-gray-300 dark:border-gray-600"
                    onClick={(e) => handleRadioClick(directorFullName, e)}
                  >
                    <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-2 after:h-2 after:rounded-full after:bg-black dark:after:bg-white" />
                  </RadioGroup.Item>
                  <label
                    htmlFor={`director-${idx}`}
                    className="text-md text-gray-900 dark:text-gray-100"
                  >
                    {directorFullName}
                  </label>
                </div>
              );
            })}
          </RadioGroup.Root>

          {/* New Signer Form */}
          {showNewSignerForm && (
            <div className="p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-semibold">Authorized User</h3>
                <button
                  onClick={() => {
                    setShowNewSignerForm(false);
                    resetSignerForm();
                  }}
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <TrashIcon className="h-5 w-5 text-red-400" />
                </button>
              </div>

              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col gap-1">
                    <TextInput
                      placeholder="First Name"
                      value={authFirstName}
                      onChange={(e) =>
                        handleInputChange("firstName", e.target.value)
                      }
                      onBlur={() =>
                        setTouched((prev) => ({ ...prev, firstName: true }))
                      }
                    />
                    {touched.firstName && errors.firstName && (
                      <span className="text-sm text-red-500">
                        {errors.firstName}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col gap-1">
                    <TextInput
                      placeholder="Last Name"
                      value={authLastName}
                      onChange={(e) =>
                        handleInputChange("lastName", e.target.value)
                      }
                      onBlur={() =>
                        setTouched((prev) => ({ ...prev, lastName: true }))
                      }
                    />
                    {touched.lastName && errors.lastName && (
                      <span className="text-sm text-red-500">
                        {errors.lastName}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <BirthdayInput
                    value={authDateOfBirth}
                    onChange={(value) =>
                      handleInputChange("dateOfBirth", value)
                    }
                  />
                  {touched.dateOfBirth && errors.dateOfBirth && (
                    <span className="text-sm text-red-500">
                      {errors.dateOfBirth}
                    </span>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <TextInput
                    placeholder="Occupation"
                    value={authOccupation}
                    onChange={(e) =>
                      handleInputChange("occupation", e.target.value)
                    }
                    onBlur={() =>
                      setTouched((prev) => ({ ...prev, occupation: true }))
                    }
                  />
                  {touched.occupation && errors.occupation && (
                    <span className="text-sm text-red-500">
                      {errors.occupation}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Business Address Section */}
          <div className="space-y-4">
            <h3 className="font-semibold">Authorized Signer's Address</h3>

            {savedAddress && (
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={useExistingAddress}
                  onCheckedChange={(checked) =>
                    setUseExistingAddress(!!checked)
                  }
                  label={`${savedAddress.streetAddress1}, ${savedAddress.city}, ${savedAddress.province} ${savedAddress.postalCode}`}
                />
              </div>
            )}

            {!showNewAddressForm && !useExistingAddress && (
              <Button onClick={handleShowNewAddressForm} className="w-full">
                ADD ADDRESS
              </Button>
            )}

            {/* New Address Form */}
            {showNewAddressForm && (
              <div className="p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-semibold">New Address</h3>
                  <button
                    onClick={() => {
                      setShowNewAddressForm(false);
                      resetAddressForm();
                    }}
                    className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  >
                    <TrashIcon className="h-5 w-5 text-red-400" />
                  </button>
                </div>
                <div className="space-y-4">
                  <div className="flex flex-col gap-1">
                    {isLoaded ? (
                      <div className="flex flex-col gap-1">
                        <PlacesAutocomplete
                          value={authStreetAddress1}
                          onChange={handleAddressChange}
                          onSelect={handleAddressSelect}
                          searchOptions={searchOptions}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="relative">
                              <TextInput
                                {...getInputProps({
                                  placeholder: "Street address",
                                  className: "location-search-input",
                                })}
                              />
                              <div className="absolute w-full z-50">
                                {loading && (
                                  <div className="bg-white dark:bg-pebble p-2 border-x border-pebble dark:border-white">
                                    Loading...
                                  </div>
                                )}
                                {suggestions.length > 0 && (
                                  <div className="bg-white dark:bg-pebble border-x border-b border-pebble dark:border-white shadow-lg">
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "bg-rock p-2 cursor-pointer"
                                        : "hover:bg-rock p-2 cursor-pointer";

                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {touched.streetAddress1 && errors.streetAddress1 && (
                          <span className="text-sm text-red-500">
                            {errors.streetAddress1}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div>Loading Google Maps...</div>
                    )}
                  </div>

                  <TextInput
                    placeholder="Unit #"
                    value={authStreetAddress2}
                    onChange={(e) => setAuthStreetAddress2(e.target.value)}
                  />

                  <div className="flex flex-col gap-1">
                    <TextInput
                      placeholder="City"
                      value={authCity}
                      onChange={(e) =>
                        handleInputChange("city", e.target.value)
                      }
                      onBlur={() =>
                        setTouched((prev) => ({ ...prev, city: true }))
                      }
                    />
                    {touched.city && errors.city && (
                      <span className="text-sm text-red-500">
                        {errors.city}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                      <ProvinceSelector
                        province={authProvince}
                        onSelectProvince={(value) =>
                          handleInputChange("province", value)
                        }
                      />
                      {touched.province && errors.province && (
                        <span className="text-sm text-red-500">
                          {errors.province}
                        </span>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <TextInput
                        placeholder="Postal code"
                        value={authPostalCode}
                        maxLength={7}
                        onChange={(e) =>
                          handleInputChange("postalCode", e.target.value)
                        }
                        onBlur={() =>
                          setTouched((prev) => ({ ...prev, postalCode: true }))
                        }
                      />
                      {touched.postalCode && errors.postalCode && (
                        <span className="text-sm text-red-500">
                          {errors.postalCode}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex-none pt-4">
        {errors.submit && (
          <span className="text-sm text-red-500 block mb-4">
            {errors.submit}
          </span>
        )}

        <Button
          onClick={handleSubmit}
          disabled={isSubmitting || !isFormValid}
          className={clsx(
            "inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2",
            {
              "opacity-50 cursor-not-allowed bg-gray-400":
                !isFormValid || isSubmitting,
              "hover:bg-gray-500": !isFormValid || isSubmitting,
            }
          )}
        >
          {isSubmitting ? "Saving..." : "Continue"}
        </Button>
      </div>
    </div>
  );
}
