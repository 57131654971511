import { HamburgerMenuIcon, MoonIcon, SunIcon } from "@radix-ui/react-icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePageStateStore } from "../../reducers";

interface DarkModeMenuProps {
  showSignOutButton?: boolean;
}

export const DarkModeMenu = ({
  showSignOutButton = false,
}: DarkModeMenuProps) => {
  const getInitialMode = () => {
    const savedMode = localStorage.getItem("darkMode");
    if (savedMode !== null) {
      return savedMode === "true";
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  };

  const [darkMode, setDarkMode] = useState(getInitialMode);
  const navigate = useNavigate();
  const clearAuthToken = usePageStateStore.use.clearAuthToken();
  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem("darkMode", String(newMode));
    document.documentElement.classList.toggle("dark", newMode);
    window.parent.postMessage({ darkMode: newMode }, "*");
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    const syncDarkModeAcrossTabs = (event: StorageEvent) => {
      if (event.key === "darkMode") {
        setDarkMode(event.newValue === "true");
      }
    };

    const handleParentMessage = (event: MessageEvent) => {
      if (event.data && typeof event.data.darkMode === "boolean") {
        const newMode = event.data.darkMode;
        setDarkMode(newMode);
        localStorage.setItem("darkMode", String(newMode));
        document.documentElement.classList.toggle("dark", newMode);
      }
    };

    window.addEventListener("storage", syncDarkModeAcrossTabs);
    window.addEventListener("message", handleParentMessage);

    return () => {
      window.removeEventListener("storage", syncDarkModeAcrossTabs);
      window.removeEventListener("message", handleParentMessage);
    };
  }, []);

  if (!showSignOutButton) {
    return (
      <button
        className="IconButton"
        onClick={toggleDarkMode}
        aria-label="toggle dark mode"
      >
        {darkMode ? (
          <SunIcon className="w-8 h-8" />
        ) : (
          <MoonIcon className="w-8 h-8" />
        )}
      </button>
    );
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="IconButton" aria-label="menu">
          <HamburgerMenuIcon className="w-8 h-8" />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="bg-white dark:bg-pebble min-w-[120px] p-3"
          sideOffset={5}
        >
          {showSignOutButton && (
            <DropdownMenu.Item
              className="flex items-center space-x-2 mb-2 cursor-pointer dark:text-snow text-pebble"
              onClick={() => {
                clearAuthToken();
                navigate("/");
              }}
            >
              Sign out
            </DropdownMenu.Item>
          )}
          <DropdownMenu.CheckboxItem
            className="flex items-center space-x-2 cursor-pointer"
            checked={darkMode}
            onCheckedChange={toggleDarkMode}
          >
            {darkMode ? (
              <SunIcon className="w-5 h-5" />
            ) : (
              <MoonIcon className="w-5 h-5" />
            )}
          </DropdownMenu.CheckboxItem>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
