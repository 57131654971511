import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import {
  FormManager,
  StateValue,
} from "@/panels/corporatesignup/FormStateMachine";

interface Director {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  streetAddress: string;
  city: string;
  province: string;
  postalCode: string;
  paytrieEmail: string;
  isQuarterOwner: boolean;
  occupation: string;
  email: string;
}

interface CorpState {
  formState: StateValue;
  // Corporate Info
  corporateEmail: string;
  corporationName: string;
  corporationNumber: string;
  corporatePhone: string;

  // Business Details
  businessNature: string;
  purpose: string;
  sourceOfFunds: string;
  msbReg: boolean | null;

  // Corporate Dates
  dateOfIncorp: string;
  tpd: boolean;

  // Address Details
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  province: string;
  postalCode: string;

  // Director Information
  directors: Director[];
  directorEmail: string;
  ownershipStatus: boolean | null;
  ownerDateOfBirth: string; // Added
  ownerOccupation: string; // Added
  ownerAddress: string; // Added
  isPep: boolean | null;

  // AUTH Director Information

  authFirstName: string;
  authLastName: string;
  authDateOfBirth: string;
  authOccupation: string;
  authStreetAddress1: string;
  authStreetAddress2: string;
  authCity: string;
  authProvince: string;
  authPostalCode: string;

  // Documents
  incorporationDocument: File | null;
}

interface CorpActions {
  // Corporate Info Actions
  setCorporateEmail: (value: string) => void;
  setCorporationName: (value: string) => void;
  setCorporationNumber: (value: string) => void;
  setCorporatePhone: (value: string) => void;

  // Business Details Actions
  setBusinessNature: (value: string) => void;
  setPurpose: (value: string) => void;
  setSourceOfFunds: (value: string) => void;
  setMsbReg: (value: boolean) => void;

  // Corporate Dates Actions
  setDateOfIncorp: (value: string) => void;
  setTpd: (value: boolean) => void;

  // Address Actions
  setStreetAddress1: (value: string) => void;
  setStreetAddress2: (value: string) => void;
  setCity: (value: string) => void;
  setProvince: (value: string) => void;
  setPostalCode: (value: string) => void;

  // Director Actions
  setDirectors: (directors: Director[]) => void;
  updateDirector: (index: number, director: Partial<Director>) => void;
  addDirector: () => void;
  removeDirector: (index: number) => void;
  setIsPep: (value: boolean) => void;
  setDirectorEmail: (value: string) => void;
  setOwnershipStatus: (value: boolean) => void;
  setOwnerDateOfBirth: (value: string) => void; // Added
  setOwnerOccupation: (value: string) => void; // Added
  setOwnerAddress: (value: string) => void; // Added

  // Authorized Director Actions
  setAuthFirstName: (value: string) => void;
  setAuthLastName: (value: string) => void;
  setAuthDateOfBirth: (value: string) => void;
  setAuthOccupation: (value: string) => void;
  setAuthStreetAddress1: (value: string) => void;
  setAuthStreetAddress2: (value: string) => void;
  setAuthCity: (value: string) => void;
  setAuthProvince: (value: string) => void;
  setAuthPostalCode: (value: string) => void;
  // Document Actions
  setIncorporationDocument: (file: File | null) => void;

  // Form Navigation Actions
  nextState: () => void;
  prevState: () => void;
  jumpTo: (page: StateValue) => void;
  complete: () => void;
  reset: () => void;
}

const initialState: CorpState = {
  formState: FormManager.getCurrentState(),
  // Corporate Info
  corporateEmail: "",
  corporationName: "",
  corporationNumber: "",
  corporatePhone: "",

  // Business Details
  businessNature: "",
  purpose: "",
  sourceOfFunds: "",
  msbReg: null as boolean | null,

  // Corporate Dates
  dateOfIncorp: "",
  tpd: false,

  // Address Details
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  province: "",
  postalCode: "",

  // Director Information
  directors: [
    {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      streetAddress: "",
      city: "",
      province: "",
      postalCode: "",
      paytrieEmail: "",
      isQuarterOwner: false,
      occupation: "",
      email: "",
    },
  ],
  directorEmail: "",
  ownershipStatus: null,
  ownerDateOfBirth: "", // Added
  ownerOccupation: "", // Added
  ownerAddress: "", // Added
  isPep: null,

  // Authorized Director Information
  authFirstName: "",
  authLastName: "",
  authDateOfBirth: "",
  authOccupation: "",
  authStreetAddress1: "",
  authStreetAddress2: "",
  authCity: "",
  authProvince: "",
  authPostalCode: "",

  // Documents
  incorporationDocument: null,
};

export const useCorpStore = create<CorpState & CorpActions>()(
  immer((set) => ({
    ...initialState,

    // Corporate Info Setters
    setCorporateEmail: (value) =>
      set((state) => {
        state.corporateEmail = value;
      }),
    setCorporationName: (value) =>
      set((state) => {
        state.corporationName = value;
      }),
    setCorporationNumber: (value) =>
      set((state) => {
        state.corporationNumber = value;
      }),
    setCorporatePhone: (value) =>
      set((state) => {
        state.corporatePhone = value;
      }),

    // Business Details Setters
    setBusinessNature: (value) =>
      set((state) => {
        state.businessNature = value;
      }),
    setPurpose: (value) =>
      set((state) => {
        state.purpose = value;
      }),
    setSourceOfFunds: (value) =>
      set((state) => {
        state.sourceOfFunds = value;
      }),
    setMsbReg: (value) =>
      set((state) => {
        state.msbReg = value;
      }),

    // Corporate Dates Setters
    setDateOfIncorp: (value) =>
      set((state) => {
        state.dateOfIncorp = value;
      }),
    setTpd: (value) =>
      set((state) => {
        state.tpd = value;
      }),

    // Address Setters
    setStreetAddress1: (value) =>
      set((state) => {
        state.streetAddress1 = value;
      }),
    setStreetAddress2: (value) =>
      set((state) => {
        state.streetAddress2 = value;
      }),
    setCity: (value) =>
      set((state) => {
        state.city = value;
      }),
    setProvince: (value) =>
      set((state) => {
        state.province = value;
      }),
    setPostalCode: (value) =>
      set((state) => {
        state.postalCode = value;
      }),

    // Director Actions
    setDirectors: (directors) =>
      set((state) => {
        state.directors = directors;
      }),
    updateDirector: (index, director) =>
      set((state) => {
        state.directors[index] = {
          ...state.directors[index],
          ...director,
        };
      }),
    addDirector: () =>
      set((state) => {
        state.directors.push({
          firstName: "",
          lastName: "",
          dateOfBirth: "",
          streetAddress: "",
          city: "",
          province: "",
          postalCode: "",
          paytrieEmail: "",
          isQuarterOwner: false,
          occupation: "",
          email: "",
        });
      }),
    removeDirector: (index) =>
      set((state) => {
        if (state.directors.length > 1) {
          state.directors = state.directors.filter((_, i) => i !== index);
        }
      }),
    setIsPep: (value) =>
      set((state) => {
        state.isPep = value;
      }),
    setDirectorEmail: (value) =>
      set((state) => {
        state.directorEmail = value;
      }),
    setOwnershipStatus: (value) =>
      set((state) => {
        state.ownershipStatus = value;
      }),

    // Added Owner Detail Setters
    setOwnerDateOfBirth: (value) =>
      set((state) => {
        state.ownerDateOfBirth = value;
      }),
    setOwnerOccupation: (value) =>
      set((state) => {
        state.ownerOccupation = value;
      }),
    setOwnerAddress: (value) =>
      set((state) => {
        state.ownerAddress = value;
      }),

    // Authorized Director Setters
    setAuthFirstName: (value) =>
      set((state) => {
        state.authFirstName = value;
      }),
    setAuthLastName: (value) =>
      set((state) => {
        state.authLastName = value;
      }),
    setAuthDateOfBirth: (value) =>
      set((state) => {
        state.authDateOfBirth = value;
      }),
    setAuthOccupation: (value) =>
      set((state) => {
        state.authOccupation = value;
      }),
    setAuthStreetAddress1: (value) =>
      set((state) => {
        state.authStreetAddress1 = value;
      }),
    setAuthStreetAddress2: (value) =>
      set((state) => {
        state.authStreetAddress2 = value;
      }),
    setAuthCity: (value) =>
      set((state) => {
        state.authCity = value;
      }),
    setAuthProvince: (value) =>
      set((state) => {
        state.authProvince = value;
      }),
    setAuthPostalCode: (value) =>
      set((state) => {
        state.authPostalCode = value;
      }),

    // Document Actions
    setIncorporationDocument: (file) =>
      set((state) => {
        state.incorporationDocument = file;
      }),

    // Form Navigation Actions
    nextState: () =>
      set((state) => {
        FormManager.next();
        state.formState = FormManager.getCurrentState();
      }),
    prevState: () =>
      set((state) => {
        FormManager.back();
        state.formState = FormManager.getCurrentState();
      }),
    complete: () =>
      set((state) => {
        FormManager.complete();
        state.formState = FormManager.getCurrentState();
      }),
    reset: () =>
      set(() => {
        FormManager.reset();
        return initialState;
      }),
    jumpTo: (page) =>
      set((state) => {
        FormManager.jumpTo(page);
        state.formState = FormManager.getCurrentState();
      }),
  }))
);

// Utility type to get store value types
export type CorpStoreState = ReturnType<typeof useCorpStore.getState>;

// Utility type to get store action types
export type CorpStoreActions = Omit<CorpStoreState, keyof CorpState>;
