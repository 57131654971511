// When buying I need to set the wallet to send the stable coin to.

import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { usePageStateStore } from "@/reducers";
import { WidgetLayout } from "../WidgetLayout";
import { AddWallet } from "./AddWallet";
import { TransactionInstructions } from "./TransactionInstructions";
import { DarkModeMenu } from "@/panels/signup/DarkmodeSignUpPanel";

enum BuyPageState {
  ADD_WALLET = "ADD_WALLET",
  SHOW_SUMMARY = "SHOW SUMMARY",
}

export const Buy = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parent = searchParams.get("parent");

  const [wallet, setWallet] = useState("");
  const [buyState, setBuyState] = useState(BuyPageState.ADD_WALLET);
  const [tx_id, setTxId] = [
    usePageStateStore.use.tx_id(),
    usePageStateStore.use.setTxId(),
  ];
  const [, setLoading] = useState(false);

  const confirmWallet = (txnId: string) => {
    setTxId(txnId);
    setBuyState(BuyPageState.SHOW_SUMMARY);
    navigate(`/status?id=${txnId}`);
  };

  return (
    <WidgetLayout showInteracLabel={false} showPoweredByLabel={true}>
      <div className="mt-32">
        {window.self === window.top && parent !== "landing" && (
          <div className="absolute top-10 right-0">
            <DarkModeMenu showSignOutButton={true} />
          </div>
        )}

        {buyState === BuyPageState.ADD_WALLET && (
          <AddWallet
            wallet={wallet}
            onSetWallet={setWallet}
            onSubmitSuccess={confirmWallet}
            setLoading={setLoading}
          />
        )}
        {buyState === BuyPageState.SHOW_SUMMARY && (
          <TransactionInstructions orderId={tx_id} wallet={wallet} />
        )}
      </div>
    </WidgetLayout>
  );
};
