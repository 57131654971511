import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";

export default function RegistrationComplete() {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate("/"); // Navigate to your homepage
  };

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-2xl font-bold">
        THANK YOU FOR REGISTERING WITH PAYTRIE
      </h1>

      <p className="text-base">
        Please allow up to 24 hours for us to verify your business. If we
        require more information, we will contact you by email.
      </p>

      <Button
        onClick={handleReturnHome}
        className="mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
      >
        RETURN TO HOMEPAGE
      </Button>
    </div>
  );
}
