import { useState, useMemo } from "react";

import { InputText } from "@/components/widget-components";
import { StableCoinValue, allTokenOptions } from "@/utils/currencies";

type TokensProps = {
  onSelectToken: (token: StableCoinValue) => void;
};

export const Tokens = ({ onSelectToken }: TokensProps) => {
  const [searchText, setSearchText] = useState("");

  const filteredOptions = useMemo(() => {
    try {
      return allTokenOptions.filter((tokenOption) => {
        return tokenOption.name.includes(searchText.toUpperCase());
      });
    } catch (e) {
      return [];
    }
  }, [searchText]);
  return (
    <div>
      {/* <div className="uppercase text-xl font-bold">Tokens</div> */}
      <InputText
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      <div className="flex flex-col items-start mt-2">
        {filteredOptions.toReversed().map((option) => {
          return (
            // <Dialog.Close key={option.value} asChild>
            <div
              onClick={() => onSelectToken(option.value)}
              className="p-4 hover:border-px hover:bg-slate-200 hover:text-black dark:hover:text-black"
            >
              <div className="flex gap-1 justify-center items-center">
                <span style={{ pointerEvents: "none" }}>
                  <div className="flex md:w-[16rem]">
                    {option.image && (
                      <div className="mr-1 md:mr-2">
                        <img src={option.image} height={20} width={20} />
                      </div>
                    )}
                    <div className="text-left flex flex-col justify-center">
                      {option.name}
                    </div>
                  </div>
                </span>
                <span>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
                      fill="currentColor"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
            // </Dialog.Close>
          );
        })}
      </div>
    </div>
  );
};
