import { Stepper } from "./Stepper";
import { CorporateSignupPanel } from "@/panels/corporatesignup/CorporateSignupPanel";
import { LoginHeader } from "@/components/widget-components";

export const CorporateSignup = () => {
  return (
    <div className="flex flex-col md:flex-row gap-4 max-w-[730px] lg:min-w-[730px] mx-auto min-w-full relative">
      <LoginHeader>
        <Stepper />
      </LoginHeader>
      <div className="flex flex-col flex-grow relative">
        <CorporateSignupPanel />
      </div>
    </div>
  );
};
