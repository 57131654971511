import { useState, useEffect, useCallback, useRef } from "react";
import { useProfileStore, usePageStateStore } from "@/reducers";
import {
  getBankDetailsByUser,
  submitOtcSellOrder,
  getPriceQuote,
} from "@/requests/order";
import { getLoggedInUser } from "@/requests/user";
import {
  InfoCircledIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as RadixSelect from "@radix-ui/react-select";
import { createPortal } from "react-dom";
import { isAddress } from "web3-utils";
import axios from "axios";

import USDCLogo from "../../../assets/img/icons/cryptoicons/SVG/usdc.svg";
import CADCLogo from "../../../assets/currency/CADC2.svg";
import EthereumLogo from "../../../assets/networks/ethereum-eth-logo.png";
import PolygonLogo from "../../../assets/networks/polygon-matic-logo.png";
import ArbitrumLogo from "../../../assets/networks/arbitrum-arb-logo.png";
import BinanceLogo from "../../../assets/networks/bnb-bnb-logo.png";
import OptimismLogo from "../../../assets/networks/optimism-ethereum-op-logo.png";
import BaseLogo from "../../../assets/networks/Base_Symbol_Blue.png";
import SXLogo from "../../../assets/networks/sx-network-logo.png";
import SOLLogo from "../../../assets/img/icons/cryptoicons/SVG/sol.svg";
import AVAXLogo from "../../../assets/img/icons/cryptoicons/SVG/avax.svg";
import CELOLogo from "../../../assets/celo_network.svg";

const stablecoinLogos: Record<string, string> = {
  USDC: USDCLogo,
  CADC: CADCLogo,
};

const networkLogos: Record<string, string> = {
  ETH: EthereumLogo,
  SOL: SOLLogo,
  BSC: BinanceLogo,
  POLY: PolygonLogo,
  BASE: BaseLogo,
  ARB: ArbitrumLogo,
  OP: OptimismLogo,
  AVAX: AVAXLogo,
  CELO: CELOLogo,
  SX: SXLogo,
};

const TooltipPortal: React.FC<{
  targetRef: React.RefObject<HTMLElement>;
  visible: boolean;
  offsetX?: number;
  offsetY?: number;
  children: React.ReactNode;
}> = ({ targetRef, visible, offsetX = 0, offsetY = 0, children }) => {
  const [position, setPosition] = useState<{
    left: number;
    top: number;
  } | null>(null);
  useEffect(() => {
    const updatePosition = () => {
      if (targetRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        setPosition({ left: rect.right + offsetX, top: rect.top + offsetY });
      }
    };
    updatePosition();
    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);
    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
    };
  }, [targetRef, visible, offsetX, offsetY]);
  if (!visible || !position) return null;
  return createPortal(
    <div
      style={{ position: "fixed", left: position.left, top: position.top }}
      className="bg-black text-white text-xs rounded-md px-3 py-2 shadow-md z-50 max-w-xs whitespace-normal"
    >
      {children}
    </div>,
    document.body
  );
};

interface SellFormProps {
  setCurrency: (currency: string) => void;
  onAddBankAccount: () => void;
}

const SellForm: React.FC<SellFormProps> = ({ onAddBankAccount }) => {
  const { email: userEmail, setEmail } = useProfileStore();
  const [bankAccounts, setBankAccounts] = useState<any[]>([]);
  const initialFormValues = {
    amount: "",
    wallet: "",
    stablecoin: "",
    network: "",
    bankAccount: "",
    purpose: "",
    source: "",
    currency: "",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [walletError, setWalletError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [quoteError, setQuoteError] = useState("");
  const [estimatedValue, setEstimatedValue] = useState<string | null>(null);
  const [submitTooltipVisible, setSubmitTooltipVisible] = useState(false);
  const submitTooltipRef = useRef<HTMLDivElement>(null);
  const [showIframe, setShowIframe] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [, setIsMobile] = useState(false);

  const authToken = usePageStateStore.use.authToken() || "";

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!userEmail && authToken) {
      async function fetchUserEmail() {
        try {
          const { response, error } = await getLoggedInUser(authToken);
          if (response) {
            const user = response.data || {};
            setEmail(user.email || "");
          } else if (error) {
            console.error("Failed to fetch user email", error);
          }
        } catch (err) {
          console.error("Error fetching user email:", err);
        }
      }
      fetchUserEmail();
    }
  }, [userEmail, authToken, setEmail]);
  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const response = await getBankDetailsByUser();
        setBankAccounts(response.data);
      } catch (error) {
        console.error("Failed to fetch bank accounts:", error);
      }
    };
    fetchBankAccounts();
  }, []);
  const formatNumber = (value: string) => {
    const parts = value.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  const validateWalletAddress = (address: string, network: string) => {
    if (!address) return "";
    if (!network) return "";
    if (network === "SOL" || network === "Solana") {
      const solRegex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
      if (!solRegex.test(address)) {
        return "Enter a valid Solana address";
      }
    } else {
      if (!isAddress(address)) {
        return `Enter a valid Address for ${network}`;
      }
    }
    return "";
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "amount") {
      newValue = value.replace(/[^0-9.]/g, "");
      const parts = newValue.split(".");
      if (parts.length > 2) {
        newValue = parts[0] + "." + parts[1];
      }
      if (parts.length === 2) {
        parts[1] = parts[1].slice(0, 2);
        newValue = parts[0] + "." + parts[1];
      }
      newValue = formatNumber(newValue);
    }
    setFormValues((prev) => ({ ...prev, [name]: newValue }));
    if (name === "wallet") {
      const errorMessage = validateWalletAddress(newValue, formValues.network);
      setWalletError(errorMessage);
    }
  };
  useEffect(() => {
    const errorMessage = validateWalletAddress(
      formValues.wallet,
      formValues.network
    );
    setWalletError(errorMessage);
  }, [formValues.wallet, formValues.network]);
  const fetchSellQuote = useCallback(
    async (amount: string) => {
      if (!amount) {
        setEstimatedValue(null);
        return;
      }
      const numericAmount = Number(amount.replace(/,/g, ""));
      if (
        numericAmount < 10000 ||
        !formValues.stablecoin ||
        !formValues.network ||
        !formValues.bankAccount ||
        formValues.bankAccount === "add-bank-account"
      ) {
        setEstimatedValue(null);
        return;
      }
      setQuoteLoading(true);
      setQuoteError("");
      try {
        const response = await getPriceQuote(
          numericAmount,
          `${formValues.stablecoin}-${formValues.network}`,
          formValues.currency
        );
        setEstimatedValue(formatNumber(String(response.data.rightSideValue)));
      } catch (e) {
        void e;
        setQuoteError("Failed to fetch estimated value");
        setEstimatedValue(null);
      } finally {
        setQuoteLoading(false);
      }
    },
    [
      formValues.stablecoin,
      formValues.network,
      formValues.currency,
      formValues.bankAccount,
    ]
  );
  useEffect(() => {
    const numericAmount = Number(formValues.amount.replace(/,/g, ""));
    if (
      numericAmount >= 10000 &&
      formValues.stablecoin &&
      formValues.network &&
      formValues.bankAccount &&
      formValues.bankAccount !== "add-bank-account"
    ) {
      fetchSellQuote(formValues.amount);
    } else {
      setEstimatedValue(null);
    }
  }, [
    formValues.amount,
    formValues.stablecoin,
    formValues.network,
    formValues.bankAccount,
    fetchSellQuote,
  ]);
  const stablecoinOptions = [
    { value: "USDC", label: "USDC" },
    { value: "CADC", label: "CADC" },
  ];
  const networkOptions =
    formValues.stablecoin === "CADC"
      ? [
          { value: "BASE", label: "Base" },
          { value: "ARB", label: "Arbitrum" },
          { value: "ETH", label: "Ethereum" },
          { value: "POLY", label: "Polygon" },
        ]
      : formValues.stablecoin === "USDC"
        ? [
            { value: "ETH", label: "Ethereum" },
            { value: "SOL", label: "Solana" },
            { value: "BSC", label: "BSC" },
            { value: "POLY", label: "Polygon" },
            { value: "BASE", label: "Base" },
            { value: "ARB", label: "Arbitrum" },
            { value: "OP", label: "Optimism" },
            { value: "AVAX", label: "Avalanche" },
            { value: "CELO", label: "Celo" },
            { value: "SX", label: "SX" },
          ]
        : [];
  const purposeOptionsSelect = [
    { value: "Business expenses", label: "Business expenses" },
    { value: "Payroll", label: "Payroll" },
    { value: "Loan repayment", label: "Loan repayment" },
    { value: "Savings", label: "Savings" },
    { value: "Real estate", label: "Real estate" },
    { value: "Gift", label: "Gift" },
    { value: "Other", label: "Other" },
  ];
  const sourceOptionsSelect = [
    { value: "Investment proceeds", label: "Investment proceeds" },
    { value: "Business revenue", label: "Business revenue" },
    { value: "Salary", label: "Salary" },
    { value: "Savings", label: "Savings" },
    { value: "Loan", label: "Loan" },
    { value: "Gift", label: "Gift" },
    { value: "Other", label: "Other" },
  ];
  const filteredBankAccountOptions = (
    formValues.stablecoin === "CADC"
      ? bankAccounts.filter((account) => account.currency === "CAD")
      : bankAccounts
  ).map((account) => ({
    value: account.bank_id,
    label: `${account.account_holder_name} - ${account.account_number} (${account.currency})`,
    currency: account.currency,
  }));
  filteredBankAccountOptions.push({
    value: "add-bank-account",
    label: "Add bank account",
    currency: "",
  });
  const isFormValid =
    formValues.amount &&
    parseFloat(formValues.amount.replace(/,/g, "")) >= 10000 &&
    formValues.wallet &&
    formValues.stablecoin &&
    formValues.network &&
    formValues.bankAccount &&
    formValues.purpose &&
    formValues.source &&
    !walletError;
  const selectedBankAccount = bankAccounts.find(
    (account) => account.bank_id === formValues.bankAccount
  );
  const payCurrency = selectedBankAccount ? selectedBankAccount.currency : "";
  const isWithinAllowedTime = () => {
    const now = new Date();
    const nowEST = new Date(
      now.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    const hours = nowEST.getHours();
    return hours >= 9 && hours < 16;
  };
  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    try {
      const payload = {
        email: userEmail,
        wallet: formValues.wallet,
        amount: Number(formValues.amount.replace(/,/g, "")) || 0,
        source_label: `${formValues.stablecoin}-${formValues.network.toUpperCase()}`,
        currency: selectedBankAccount ? selectedBankAccount.currency : "",
        bank_id: formValues.bankAccount,
        purpose: formValues.purpose,
        source: formValues.source,
      };
      const response = await submitOtcSellOrder(payload);
      console.log("OTC Sell Order Created:", response.data);
      const txId = response.data.tx;
      setTransactionId(txId);
      setShowIframe(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errMsg = err?.response?.data?.message || "";
        if (errMsg.toLowerCase().includes("too many cancelled")) {
          setError(
            "You've had too many cancelled or expired transactions today. Please try again tomorrow or contact support@paytrie.com if the issue persists."
          );
        } else {
          setError(
            "We’re unable to process your transaction. Please try again or contact support@paytrie.com if the issue persists."
          );
        }
      } else {
        setError("An unknown error occurred. Unable to create transaction");
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setFormValues((prev) => {
      if (prev.stablecoin === "CADC") {
        const selected = bankAccounts.find(
          (account) => account.bank_id === prev.bankAccount
        );
        if (!selected || selected.currency !== "CAD") {
          return { ...prev, bankAccount: "", currency: "" };
        }
      }
      return prev;
    });
  }, [formValues.stablecoin, bankAccounts]);
  if (showIframe) {
    const handleBack = () => {
      setShowIframe(false);
      setFormValues(initialFormValues);
      setTransactionId("");
    };
    return (
      <div className="w-full max-w-[340px] md:max-w-full mx-auto">
        <button
          onClick={handleBack}
          className="mb-4 text-lg font-medium dark:text-white hover:underline"
        >
          ← Back
        </button>
        <iframe
          src={`${import.meta.env.VITE_APPLICATION_URL}/Status?id=${transactionId}`}
          style={{ width: "100%", height: "800px", border: "none" }}
          title="Status"
          allow="camera"
        />
      </div>
    );
  }
  return (
    <div className="flex flex-col md:flex-row gap-8 mx-auto md:mt-[-20px] md:ml-[-22px] mt-4">
      <div className="w-full md:w-1/2 rounded-md">
        <h3 className="text-3xl font-bold mb-4">Instructions:</h3>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="font-bold">
              Please send your stablecoins from your wallet to our OTC
              designated wallet address provided in your confirmation email.
              Once we receive your stablecoins, we will process the payment via
              EFT (which may take 1–2 business days).
            </span>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 space-y-8">
        <div className="rounded-md">
          <h2 className="text-3xl font-bold mb-4">Pay</h2>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="w-full md:w-48 font-bold">Amount:</label>
            <input
              type="text"
              name="amount"
              value={formValues.amount}
              onChange={handleInputChange}
              placeholder="Enter amount"
              className="w-full border-b border-black dark:border-white bg-transparent dark:text-white outline-none pl-1 placeholder-gray-500"
            />
          </div>
          {formValues.amount &&
            parseFloat(formValues.amount.replace(/,/g, "")) < 10000 && (
              <span className="text-red-500 text-sm">
                Minimum amount is $10,000
              </span>
            )}
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="w-full md:w-48 font-bold">Stablecoin:</label>
            <div className="w-full">
              <RadixSelect.Root
                value={formValues.stablecoin || undefined}
                onValueChange={(value) => {
                  setFormValues((prev) => {
                    const allowedNetworks =
                      value === "CADC"
                        ? ["BASE", "ARB", "ETH", "POLY"]
                        : value === "USDC"
                          ? [
                              "ETH",
                              "SOL",
                              "BSC",
                              "POLY",
                              "BASE",
                              "ARB",
                              "OP",
                              "AVAX",
                              "CELO",
                              "SX",
                            ]
                          : [];
                    const currentNetworkAllowed =
                      prev.network && allowedNetworks.includes(prev.network);
                    const newNetwork = currentNetworkAllowed
                      ? prev.network
                      : "";
                    let newFormValues = {
                      ...prev,
                      stablecoin: value,
                      network: newNetwork,
                    };
                    if (value === "CADC") {
                      const selected = bankAccounts.find(
                        (account) => account.bank_id === prev.bankAccount
                      );
                      if (!selected || selected.currency !== "CAD") {
                        newFormValues = {
                          ...newFormValues,
                          bankAccount: "",
                          currency: "",
                        };
                      }
                    }
                    const numericAmount = Number(
                      newFormValues.amount.replace(/,/g, "")
                    );
                    if (
                      numericAmount >= 10000 &&
                      newFormValues.stablecoin &&
                      newFormValues.network &&
                      newFormValues.bankAccount &&
                      newFormValues.bankAccount !== "add-bank-account"
                    ) {
                      setTimeout(() => {
                        fetchSellQuote(newFormValues.amount);
                      }, 0);
                    }
                    return newFormValues;
                  });
                }}
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value placeholder="Select stablecoin" />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {stablecoinOptions.map((opt) => (
                        <RadixSelect.Item
                          key={opt.value}
                          value={opt.value}
                          className="hover:bg-rock data-[highlighted]:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                        >
                          <RadixSelect.ItemText>
                            <div className="flex items-center">
                              <img
                                src={stablecoinLogos[opt.value]}
                                alt={`${opt.label} logo`}
                                className="w-4 h-4 mr-2"
                              />
                              {opt.label}
                            </div>
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="text-md font-bold w-48 mb-1">Network:</label>
            <div className="w-full max-w-[350px]">
              <RadixSelect.Root
                value={formValues.network || undefined}
                onValueChange={(value) =>
                  setFormValues((prev) => ({ ...prev, network: value }))
                }
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value placeholder="Select network" />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {networkOptions.map((opt) => (
                        <RadixSelect.Item
                          key={opt.value}
                          value={opt.value}
                          className="hover:bg-rock data-[highlighted]:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                        >
                          <RadixSelect.ItemText>
                            <div className="flex items-center">
                              <img
                                src={networkLogos[opt.value]}
                                alt={`${opt.label} logo`}
                                className="w-4 h-4 mr-2"
                              />
                              {opt.label}
                            </div>
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="text-md font-bold w-48 mb-1">
              Wallet Address:
            </label>
            <input
              type="text"
              name="wallet"
              value={formValues.wallet}
              onChange={handleInputChange}
              placeholder="Enter wallet address"
              className="w-full max-w-[350px] bg-transparent border-b border-black dark:border-white outline-none pl-1 placeholder-gray-500"
            />
          </div>
          {walletError && (
            <span className="text-red-500 text-sm">{walletError}</span>
          )}
        </div>
        <div className="rounded-md">
          <h2 className="text-3xl font-bold mb-4">Receive</h2>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="text-md font-bold w-48 mb-1">Bank Account:</label>
            {bankAccounts.length > 0 ? (
              <div className="w-full max-w-[350px]">
                <RadixSelect.Root
                  value={formValues.bankAccount || undefined}
                  onValueChange={(value) => {
                    if (value === "add-bank-account") {
                      onAddBankAccount();
                    } else {
                      const selected = filteredBankAccountOptions.find(
                        (opt) => opt.value === value
                      );
                      setFormValues((prev) => ({
                        ...prev,
                        bankAccount: value,
                        currency: selected?.currency || "",
                      }));
                    }
                  }}
                >
                  <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                    <RadixSelect.Value placeholder="Select bank account" />
                    <RadixSelect.Icon className="text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.Icon>
                  </RadixSelect.Trigger>
                  <RadixSelect.Portal>
                    <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                      <RadixSelect.ScrollUpButton className="p-2.5">
                        <ChevronUpIcon />
                      </RadixSelect.ScrollUpButton>
                      <RadixSelect.Viewport>
                        {filteredBankAccountOptions.map((opt) => (
                          <RadixSelect.Item
                            key={opt.value}
                            value={opt.value}
                            className="hover:bg-rock data-[highlighted]:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                          >
                            <RadixSelect.ItemText>
                              {opt.label}
                            </RadixSelect.ItemText>
                          </RadixSelect.Item>
                        ))}
                      </RadixSelect.Viewport>
                      <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                        <ChevronDownIcon />
                      </RadixSelect.ScrollDownButton>
                    </RadixSelect.Content>
                  </RadixSelect.Portal>
                </RadixSelect.Root>
              </div>
            ) : (
              <p className="text-sm dark:text-gray-400 text-gray-600 px-1">
                No linked bank account. Add one to continue.
              </p>
            )}
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="text-md font-bold w-48 mb-1">
              Estimated Amount:
            </label>
            <div className="w-full max-w-[350px]">
              <span className="text-md font-semibold ml-1">
                {parseFloat(formValues.amount.replace(/,/g, "")) >= 10000 ? (
                  quoteLoading ? (
                    "Fetching quote"
                  ) : quoteError ? (
                    <span className="text-gray-500">{quoteError}</span>
                  ) : estimatedValue ? (
                    `${estimatedValue} ${payCurrency}`
                  ) : (
                    <span className="text-gray-500">
                      Please fill all input fields
                    </span>
                  )
                ) : (
                  <span className="text-gray-500">
                    Please fill all input fields
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="text-md font-bold w-48 mb-1">Purpose:</label>
            <div className="w-full max-w-[350px]">
              <RadixSelect.Root
                value={formValues.purpose || undefined}
                onValueChange={(value) =>
                  setFormValues((prev) => ({ ...prev, purpose: value }))
                }
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value placeholder="Select purpose" />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {purposeOptionsSelect.map((opt) => (
                        <RadixSelect.Item
                          key={opt.value}
                          value={opt.value}
                          className="hover:bg-rock data-[highlighted]:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                        >
                          <RadixSelect.ItemText className="whitespace-normal">
                            {opt.label}
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="text-md font-bold w-48 mb-1">
              Source Of Funds:
            </label>
            <div className="w-full max-w-[350px]">
              <RadixSelect.Root
                value={formValues.source || undefined}
                onValueChange={(value) =>
                  setFormValues((prev) => ({ ...prev, source: value }))
                }
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value placeholder="Select source" />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {sourceOptionsSelect.map((opt) => (
                        <RadixSelect.Item
                          key={opt.value}
                          value={opt.value}
                          className="hover:bg-rock data-[highlighted]:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                        >
                          <RadixSelect.ItemText>
                            {opt.label}
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
          <div className="flex items-center mt-6">
            <div className="relative inline-block">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={!isFormValid || loading || !isWithinAllowedTime()}
                className={`px-12 py-3 text-lg font-medium ${
                  isFormValid && !loading && isWithinAllowedTime()
                    ? "dark:bg-white dark:text-white bg-black text-white"
                    : "bg-gray-400 text-gray-700 cursor-not-allowed"
                }`}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
              <div
                ref={submitTooltipRef}
                onMouseEnter={() => setSubmitTooltipVisible(true)}
                onMouseLeave={() => setSubmitTooltipVisible(false)}
                onTouchStart={() => setSubmitTooltipVisible(true)}
                onTouchEnd={() => setSubmitTooltipVisible(false)}
                className="inline-block ml-2"
              >
                <InfoCircledIcon className="text-gray-500 cursor-pointer w-4 h-4" />
              </div>
              <TooltipPortal
                targetRef={submitTooltipRef}
                visible={submitTooltipVisible}
                offsetX={8}
                offsetY={-8}
              >
                OTC is available Mon - Fri, 9am - 4pm EST, excluding bank
                holidays.
              </TooltipPortal>
            </div>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default SellForm;
