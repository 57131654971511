import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Button } from "@/components/Button/Button";
import { TextInput, ErrorBlockText } from "@/components/widget-components";
import { useCorpStore } from "@/reducers/corporateReducer";
import { ROUTES } from "../CorporateSignupPanel";
import { ProvinceSelector } from "@/components/Select/ProvinceSelector";
import axiosInstance from "@/requests/axios";
import clsx from "clsx";

export function CorporateBusinessAddress() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const streetAddress1 = useCorpStore((state) => state.streetAddress1);
  const setStreetAddress1 = useCorpStore((state) => state.setStreetAddress1);
  const streetAddress2 = useCorpStore((state) => state.streetAddress2);
  const setStreetAddress2 = useCorpStore((state) => state.setStreetAddress2);
  const city = useCorpStore((state) => state.city);
  const setCity = useCorpStore((state) => state.setCity);
  const province = useCorpStore((state) => state.province);
  const setProvince = useCorpStore((state) => state.setProvince);
  const postalCode = useCorpStore((state) => state.postalCode);
  const setPostalCode = useCorpStore((state) => state.setPostalCode);

  const [errorMessage, setErrorMessage] = useState("");
  const [touched, setTouched] = useState({
    streetAddress1: false,
    city: false,
    province: false,
    postalCode: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`,
    libraries: ["places"],
  });

  const searchOptions = {
    componentRestrictions: { country: "ca" },
    types: ["address"],
  };

  useEffect(() => {
    const savedState = localStorage.getItem("corporateAddressState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      setStreetAddress1(parsed.streetAddress1 || "");
      setStreetAddress2(parsed.streetAddress2 || "");
      setCity(parsed.city || "");
      setProvince(parsed.province || "");
      setPostalCode(parsed.postalCode || "");
    }
  }, []);

  const isValidPostalCode = useCallback((code: string) => {
    const formattedCode = code.replace(/\s/g, "");
    return /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/.test(formattedCode);
  }, []);

  const formatPostalCode = (code: string): string => {
    const cleaned = code.replace(/\s/g, "").toUpperCase();
    if (cleaned.length > 3) {
      return cleaned.slice(0, 3) + " " + cleaned.slice(3);
    }
    return cleaned;
  };

  const validateField = useCallback(
    (field: string, value: string) => {
      switch (field) {
        case "streetAddress1":
          return !value.trim() ? "Street address is required" : "";
        case "city":
          return !value.trim() ? "City is required" : "";
        case "province":
          return !value.trim() ? "Province is required" : "";
        case "postalCode":
          if (!value.trim()) return "Postal code is required";
          if (!isValidPostalCode(value))
            return "Please enter a valid postal code (e.g., A1A 1A1)";
          return "";
        default:
          return "";
      }
    },
    [isValidPostalCode]
  );

  useEffect(() => {
    const isValid = !Object.entries({
      streetAddress1,
      city,
      province,
      postalCode,
    }).some(([field, value]) => validateField(field, value));
    setIsFormValid(isValid);

    // Only show errors for touched fields
    if (Object.values(touched).some((t) => t)) {
      const touchedFieldErrors = Object.entries(touched)
        .filter(([, isTouched]) => isTouched)
        .map(([field]) => validateField(field, eval(field)))
        .filter((error) => error !== "");
      setErrorMessage(touchedFieldErrors[0] || "");
    } else {
      setErrorMessage("");
    }
  }, [streetAddress1, city, province, postalCode, touched]);

  const handleAddressChange = (address: string) => {
    setStreetAddress1(address);
    setTouched((prev) => ({ ...prev, streetAddress1: true }));
  };

  const handleAddressSelect = (address: string) => {
    const addressObj: any = {};
    geocodeByAddress(address)
      .then((results) => {
        const addressComponents = results[0].address_components;
        for (const component of addressComponents) {
          for (const type of component.types) {
            addressObj[type] =
              type === "administrative_area_level_1"
                ? component.short_name
                : component.long_name;
          }
        }

        setStreetAddress1(
          `${addressObj.street_number ? addressObj.street_number : ""} ${addressObj.route ? addressObj.route : ""}`
        );
        setStreetAddress2(addressObj.subpremise || "");
        setCity(addressObj.locality || "");
        setProvince(addressObj.administrative_area_level_1 || "");
        setPostalCode(
          addressObj.postal_code
            ? addressObj.postal_code.replace(/\s/g, "")
            : ""
        );

        setTouched({
          streetAddress1: true,
          city: true,
          province: true,
          postalCode: true,
        });
      })
      .catch((error) => {
        console.error("Error selecting address:", error);
        setErrorMessage("Error selecting address. Please try again.");
      });
  };

  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPostalCode(e.target.value);
    if (formatted.length <= 7) {
      setPostalCode(formatted);
      setTouched((prev) => ({ ...prev, postalCode: true }));
    }
  };

  const validateForm = useCallback(() => {
    setTouched({
      streetAddress1: true,
      city: true,
      province: true,
      postalCode: true,
    });

    const errors = {
      streetAddress1: validateField("streetAddress1", streetAddress1),
      city: validateField("city", city),
      province: validateField("province", province),
      postalCode: validateField("postalCode", postalCode),
    };

    const firstError = Object.values(errors).find((error) => error !== "");
    setErrorMessage(firstError || "");

    return !Object.values(errors).some((error) => error !== "");
  }, [streetAddress1, city, province, postalCode, validateField]);

  const handleSubmit = useCallback(async () => {
    setTouched({
      streetAddress1: true,
      city: true,
      province: true,
      postalCode: true,
    });

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const stateToSave = {
        streetAddress1,
        streetAddress2,
        city,
        province,
        postalCode,
      };
      localStorage.setItem(
        "corporateAddressState",
        JSON.stringify(stateToSave)
      );

      const addressValues = {
        address1: streetAddress1,
        address2: streetAddress2 || "",
        city: city,
        province: province,
        postal: postalCode,
      };

      const lowerCasedValues = Object.fromEntries(
        Object.entries(addressValues).map(([key, value]) => [
          key,
          typeof value === "string" ? value.toLowerCase() : value,
        ])
      );

      const corporateInfoState = localStorage.getItem("corporateInfoState");
      const corporateInfo = corporateInfoState
        ? JSON.parse(corporateInfoState)
        : {};
      const corporateEmail = corporateInfo.corporateEmail?.toLowerCase();

      await axiosInstance({
        method: "post",
        url: "/updateCorporate",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          corporation_email: corporateEmail,
          corporation_address: {
            ...lowerCasedValues,
            postal: lowerCasedValues.postal.replace(" ", ""),
          },
        },
      });
      nextState();
      navigate(ROUTES.CORPORATE_ADDITIONAL_DETAILS);
    } catch (error) {
      console.error("Error updating corporate details:", error);
      setErrorMessage("Failed to update corporate details. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  }, [
    streetAddress1,
    streetAddress2,
    city,
    province,
    postalCode,
    validateForm,
    nextState,
    navigate,
  ]);

  // Add the global keydown effect
  useEffect(() => {
    const handleGlobalKeyDown = async (e: KeyboardEvent) => {
      if (e.key === "Enter" && isFormValid && !isSubmitting) {
        e.preventDefault();
        // Don't trigger submit if suggestions are showing
        const suggestions = document.querySelector(".location-search-input")
          ?.nextElementSibling?.children;
        if (!suggestions || suggestions.length === 0) {
          await handleSubmit();
        }
      }
    };

    document.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [isFormValid, isSubmitting, handleSubmit]);

  return (
    <div className="flex flex-col gap-6">
      <div className="text-lg font-bold uppercase">BUSINESS DETAILS</div>

      {isLoaded ? (
        <PlacesAutocomplete
          value={streetAddress1}
          onChange={handleAddressChange}
          onSelect={handleAddressSelect}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="relative">
              <TextInput
                {...getInputProps({
                  placeholder: "Street address",
                  className: "location-search-input",
                })}
              />
              <div className="absolute w-full z-50">
                {loading && (
                  <div className="bg-white dark:bg-neutral-900 p-2 border-x border-pebble dark:border-white">
                    Loading...
                  </div>
                )}
                {suggestions.length > 0 && (
                  <div className="bg-white dark:bg-neutral-900 border-x border-b border-pebble dark:border-white shadow-lg">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "bg-rock dark:bg-neutral-800 p-2 cursor-pointer"
                        : "hover:bg-rock dark:hover:bg-neutral-800 p-2 cursor-pointer";

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        <div>Loading Google Maps...</div>
      )}

      <TextInput
        placeholder="Unit #"
        value={streetAddress2}
        onChange={(e) => setStreetAddress2(e.target.value)}
      />

      <TextInput
        placeholder="City"
        value={city}
        onChange={(e) => {
          setCity(e.target.value);
          setTouched((prev) => ({ ...prev, city: true }));
        }}
      />

      <div className="grid grid-cols-2 gap-4">
        <ProvinceSelector
          province={province}
          onSelectProvince={(value) => {
            setProvince(value);
            setTouched((prev) => ({ ...prev, province: true }));
          }}
        />

        <TextInput
          placeholder="Postal code"
          value={postalCode}
          maxLength={7}
          onChange={handlePostalCodeChange}
        />
      </div>

      {errorMessage && <ErrorBlockText>{errorMessage}</ErrorBlockText>}

      <Button
        onClick={handleSubmit}
        disabled={isSubmitting || !isFormValid}
        className={clsx(
          "mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2",
          {
            "opacity-50 cursor-not-allowed bg-gray-400":
              !isFormValid || isSubmitting,
            "hover:bg-gray-500": !isFormValid || isSubmitting,
          }
        )}
      >
        {isSubmitting ? "Saving..." : "Continue"}
      </Button>
    </div>
  );
}
