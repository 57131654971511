import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { BirthdayInput } from "@/components/Select/BirthdayInput";
import { useCorpStore } from "@/reducers/corporateReducer";
import { ROUTES } from "../CorporateSignupPanel";
import axiosInstance from "@/requests/axios";
import clsx from "clsx";

interface ValidationError {
  dateOfIncorp?: string;
  tpd?: string;
  submit?: string;
}

export function CorporateDateDetails() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const dateOfIncorp = useCorpStore((state) => state.dateOfIncorp);
  const setDateOfIncorp = useCorpStore((state) => state.setDateOfIncorp);
  const tpd = useCorpStore((state) => state.tpd);
  const setTpd = useCorpStore((state) => state.setTpd);

  const [errors, setErrors] = useState<ValidationError>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Load state on component mount
  useEffect(() => {
    const savedState = localStorage.getItem("corporateDateState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      if (parsed.dateOfIncorp) {
        setDateOfIncorp(parsed.dateOfIncorp);
        setTouched((prev) => ({ ...prev, dateOfIncorp: true }));
      }
      if (parsed.tpd !== undefined) {
        setTpd(parsed.tpd);
        setTouched((prev) => ({ ...prev, tpd: true }));
      }
    }
  }, []);

  // Validate whenever values change
  useEffect(() => {
    const validationResult = validateForm();
    setIsFormValid(
      Object.keys(validationResult).length === 0 &&
        dateOfIncorp !== null &&
        dateOfIncorp !== undefined &&
        dateOfIncorp !== ""
    );

    if (touched.dateOfIncorp || touched.tpd) {
      setErrors(validationResult);
    }
  }, [dateOfIncorp, tpd, touched]);

  // Remove the existing handleKeyDown function and its div binding

  const validateForm = useCallback((): ValidationError => {
    const newErrors: ValidationError = {};

    if (!dateOfIncorp) {
      newErrors.dateOfIncorp = "Date of incorporation is required";
    } else {
      // Check if the date string has all components (YYYY-MM-DD)
      const dateComponents = dateOfIncorp.split("-");
      if (
        dateComponents.length !== 3 ||
        !dateComponents[1] ||
        dateComponents[1] === "00" || // month check
        !dateComponents[2] ||
        dateComponents[2] === "00" || // day check
        parseInt(dateComponents[2]) === 0 || // additional check for day 0
        parseInt(dateComponents[1]) === 0 // additional check for month 0
      ) {
        newErrors.dateOfIncorp =
          "Please enter a valid date with a valid month and day";
      } else {
        // Parse the date components
        const year = parseInt(dateComponents[0]);
        const month = parseInt(dateComponents[1]);
        const day = parseInt(dateComponents[2]);

        // Additional validation for valid month and day ranges
        if (year < 1800 || year > new Date().getFullYear()) {
          newErrors.dateOfIncorp = "Please enter a valid year";
        } else if (month < 1 || month > 12) {
          newErrors.dateOfIncorp = "Please enter a valid month (1-12)";
        } else if (day < 1 || day > 31) {
          newErrors.dateOfIncorp = "Please enter a valid day (1-31)";
        } else {
          const selectedDate = new Date(dateOfIncorp);
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Reset time portion for accurate date comparison

          // Calculate date 1 month ago
          const oneMonthAgo = new Date(today);
          oneMonthAgo.setMonth(today.getMonth() - 1);

          if (selectedDate > today) {
            newErrors.dateOfIncorp =
              "Date of incorporation cannot be in the future";
          } else if (selectedDate > oneMonthAgo) {
            newErrors.dateOfIncorp =
              "Date of incorporation must be older than 1 month";
          }
        }
      }
    }

    if (tpd === null || tpd === undefined) {
      newErrors.tpd =
        "Please indicate if you are acting at the instruction of another person or entity";
    }

    return newErrors;
  }, [dateOfIncorp, tpd]); // Add dependency array here

  const handleDateChange = (value: string) => {
    setDateOfIncorp(value);
    setTouched((prev) => ({ ...prev, dateOfIncorp: true }));
  };

  const handleSubmit = useCallback(async () => {
    if (!isFormValid || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    // Mark all fields as touched
    setTouched({
      dateOfIncorp: true,
      tpd: true,
    });

    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      try {
        // Save to localStorage
        const stateToSave = {
          dateOfIncorp,
          tpd,
        };
        localStorage.setItem("corporateDateState", JSON.stringify(stateToSave));

        // Get corporation_email from corporateInfoState
        const corporateInfoState = localStorage.getItem("corporateInfoState");
        const corporateInfo = corporateInfoState
          ? JSON.parse(corporateInfoState)
          : {};
        const corporateEmail = corporateInfo.corporateEmail?.toLowerCase();

        await axiosInstance({
          method: "post",
          url: "/updateCorporate",
          data: {
            corporation_email: corporateEmail,
            date_of_incorp: new Date(dateOfIncorp).toISOString().slice(0, 10),
            tpd: tpd,
          },
        });

        nextState();
        navigate(ROUTES.CORPORATE_BUSINESS_ADDRESS);
      } catch (error) {
        console.error("Error updating corporate details:", error);
        setErrors((prev) => ({
          ...prev,
          submit:
            "Unfortunately we were unable to process your account details at this time. Please try again later or contact us at support@paytrie.com",
        }));
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  }, [
    isFormValid,
    isSubmitting,
    dateOfIncorp,
    tpd,
    validateForm,
    nextState,
    navigate,
  ]); // Added dependency array here

  useEffect(() => {
    const handleGlobalKeyDown = async (e: KeyboardEvent) => {
      if (e.key === "Enter" && isFormValid && !isSubmitting) {
        e.preventDefault();
        await handleSubmit();
      }
    };

    document.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [isFormValid, isSubmitting, handleSubmit]);

  return (
    <div className="flex flex-col gap-6">
      <div className="text-lg font-bold uppercase">BUSINESS DETAILS</div>

      <div className="flex flex-col gap-2">
        <p>Date of Incorporation</p>
        <BirthdayInput value={dateOfIncorp} onChange={handleDateChange} />
        {touched.dateOfIncorp && errors.dateOfIncorp && (
          <span className="text-sm text-red-500 mt-1">
            {errors.dateOfIncorp}
          </span>
        )}
      </div>

      {errors.submit && (
        <span className="text-sm text-red-500 mt-1">{errors.submit}</span>
      )}

      <Button
        onClick={handleSubmit}
        disabled={isSubmitting || !isFormValid}
        className={clsx(
          "mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2",
          {
            "opacity-50 cursor-not-allowed bg-gray-400":
              !isFormValid || isSubmitting,
            "hover:bg-gray-500": !isFormValid || isSubmitting,
          }
        )}
      >
        {isSubmitting ? "Saving..." : "Continue"}
      </Button>
    </div>
  );
}
