import { PropsWithChildren } from "react";
import clsx from "clsx";
import { useCorpStore } from "@/reducers/corporateReducer";
import {
  StateValue,
  FormStates,
} from "@/panels/corporatesignup/FormStateMachine";
import { useLocation } from "react-router-dom";
import { ROUTES } from "@/panels/corporatesignup/CorporateSignupPanel";

interface StepItemProps {
  isActive: boolean;
  isComplete: boolean;
  ariaLabel?: string;
}

const StepItem = ({
  isActive,
  isComplete,
  children,
  ariaLabel,
}: PropsWithChildren<StepItemProps>) => {
  return (
    <li
      aria-label={ariaLabel}
      className={clsx(
        // Base styles
        "relative pl-12 pb-12 last:pb-0",
        // Line styles - Modified to use full height only for non-last items
        "before:absolute before:left-0 before:top-0 before:w-0.5 last:before:h-0 before:h-full",
        "before:bg-pebble dark:before:bg-rock",
        // Circle styles
        "after:absolute after:-left-[6px] after:top-0",
        "after:w-3.5 after:h-3.5 after:rounded-full after:border-2",
        {
          // Active state
          ["text-clementine dark:text-sun"]: isActive,
          ["after:bg-clementine dark:after:bg-sun"]: isActive,
          ["after:border-clementine dark:after:border-sun"]: isActive,

          // Complete state
          ["text-emerald-500 dark:text-emerald-400"]: isComplete && !isActive,
          ["after:bg-emerald-500 dark:after:bg-emerald-400"]:
            isComplete && !isActive,
          ["after:border-emerald-500 dark:after:border-emerald-400"]:
            isComplete && !isActive,

          // Default state
          ["text-pebble dark:text-rock"]: !isActive && !isComplete,
          ["after:bg-pebble dark:after:bg-rock"]: !isActive && !isComplete,
          ["after:border-pebble dark:after:border-rock"]:
            !isActive && !isComplete,
        }
      )}
    >
      {children}
    </li>
  );
};

const corporateDetailsStates = [
  FormStates.CORPINFO,
  FormStates.CORP_BUS_ADDRESS,
  FormStates.CORP_ADDN_DETAILS,
  FormStates.CORP_DATE_DETAILS,
];

const directorInfoStates = [
  FormStates.DIRECTOR_INFO,
  FormStates.DIRECTOR_PEP_INFO,
  FormStates.DIRECTOR_PERSONAL_SHARE,
  FormStates.DIRECTOR_AUTH_INFO,
];

const corporateDocumentStates = [
  FormStates.CORPDOCS,
  FormStates.CORPORATE_ARTICLE_INCORPORATION,
  FormStates.CORPORATE_ASSIST,
  FormStates.COMPLETE,
];

const isStateInGroup = (state: StateValue, states: StateValue[]) =>
  states.includes(state);

const isStatePastGroup = (state: StateValue, states: StateValue[]) => {
  const stateOrder = [
    ...corporateDetailsStates,
    ...directorInfoStates,
    ...corporateDocumentStates,
  ];
  const currentStateIndex = stateOrder.indexOf(state);
  const groupLastStateIndex = stateOrder.indexOf(states[states.length - 1]);

  return currentStateIndex > groupLastStateIndex;
};

export const Stepper = () => {
  const formState = useCorpStore((state) => state.formState);
  const location = useLocation();

  // Check if we're on the registration complete page
  const isComplete =
    location.pathname === ROUTES.COMPLETE ||
    location.pathname === ROUTES.CORPORATE_ASSIST;

  const isCorporateDetailsActive =
    !isComplete && isStateInGroup(formState, corporateDetailsStates);
  const isCorporateDetailsComplete =
    isComplete || isStatePastGroup(formState, corporateDetailsStates);

  const isDirectorInfoActive =
    !isComplete && isStateInGroup(formState, directorInfoStates);
  const isDirectorInfoComplete =
    isComplete || isStatePastGroup(formState, directorInfoStates);

  const isCorporateDocsActive =
    !isComplete && isStateInGroup(formState, corporateDocumentStates);
  const isCorporateDocsComplete = isComplete;

  // First page (CORPINFO) - show only intro text
  if (formState === FormStates.CORPINFO) {
    return (
      <div className="hidden md:block mb-8">
        <div className="dark:text-sun text-clementine text-md md:text-[48px] leading-tight">
          Sign up to Paytrie for Business,
        </div>
        <div className="text-md md:text-[48px] leading-tight">
          Canada's home for stablecoins
        </div>
      </div>
    );
  }

  // For all other pages - show only the stepper
  return (
    <ol className="flex flex-col mt-1 px-3">
      <StepItem
        isActive={isCorporateDetailsActive}
        isComplete={isCorporateDetailsComplete}
        ariaLabel="Corporate details step"
      >
        <h3 className="text-sm font-medium leading-tight">BUSINESS DETAILS</h3>
      </StepItem>

      <StepItem
        isActive={isDirectorInfoActive}
        isComplete={isDirectorInfoComplete}
        ariaLabel="Director's information step"
      >
        <h3 className="text-sm font-medium leading-tight">
          OWNER'S INFORMATION
        </h3>
      </StepItem>

      <StepItem
        isActive={isCorporateDocsActive}
        isComplete={isCorporateDocsComplete}
        ariaLabel="Corporate documents step"
      >
        <h3 className="text-sm font-medium leading-tight">
          BUSINESS DOCUMENTS
        </h3>
      </StepItem>
    </ol>
  );
};
