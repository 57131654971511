import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { TextInput, TocSmallText } from "@/components/widget-components";
import { BirthdayInput } from "@/components/Select/BirthdayInput";
import { useCorpStore } from "@/reducers/corporateReducer";
import { ROUTES } from "../CorporateSignupPanel";
import axiosInstance from "@/requests/axios";
import { TrashIcon } from "@radix-ui/react-icons";
import clsx from "clsx";

type DirectorField =
  | "firstName"
  | "lastName"
  | "dateOfBirth"
  | "occupation"
  | "email";

interface DirectorErrors {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  occupation?: string;
  email?: string;
  submit?: string;
}

interface Director {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  occupation: string;
  email: string;
}

const initialTouchedState: Record<DirectorField, boolean> = {
  firstName: false,
  lastName: false,
  dateOfBirth: false,
  occupation: false,
  email: false,
};

const initialDirectorState: Director = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  occupation: "",
  email: "",
};

export default function DirectorInfo() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const directors = useCorpStore((state) => state.directors);
  const updateDirector = useCorpStore((state) => state.updateDirector);
  const addDirector = useCorpStore((state) => state.addDirector);
  const removeDirector = useCorpStore((state) => state.removeDirector);
  const setDirectors = useCorpStore((state) => state.setDirectors);

  const [errors, setErrors] = useState<DirectorErrors[]>([]);
  const [touched, setTouched] = useState<Record<DirectorField, boolean>[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const savedState = localStorage.getItem("directorInfoState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      const updatedDirectors =
        parsed.directors?.map((director: Director) => ({
          ...initialDirectorState,
          ...director,
        })) || [];
      setDirectors(updatedDirectors);
      setErrors(updatedDirectors.map(() => ({})));
      setTouched(updatedDirectors.map(() => ({ ...initialTouchedState })));
    } else {
      setErrors(directors.map(() => ({})));
      setTouched(directors.map(() => ({ ...initialTouchedState })));
    }
  }, []);

  useEffect(() => {
    if (directors.length === 0) {
      setIsFormValid(false);
      return;
    }

    const allFieldsFilled = directors.every(
      (director) =>
        director.firstName.trim() !== "" &&
        director.lastName.trim() !== "" &&
        director.dateOfBirth !== "" &&
        director.occupation.trim() !== "" &&
        director.email.trim() !== ""
    );

    const noErrors = errors.every(
      (directorErrors) => Object.keys(directorErrors).length === 0
    );

    setIsFormValid(allFieldsFilled && noErrors);
  }, [directors, errors]);

  const validateDirector = useCallback((director: Director): DirectorErrors => {
    const directorErrors: DirectorErrors = {};

    // First name validation
    if (!director.firstName.trim()) {
      directorErrors.firstName =
        "First name is required. Please enter the director's first name.";
    } else if (director.firstName.trim().length < 2) {
      directorErrors.firstName =
        "First name must be at least 2 characters long.";
    } else if (!/^[a-zA-Z\s'-]+$/.test(director.firstName.trim())) {
      directorErrors.firstName =
        "First name can only contain letters, spaces, hyphens, and apostrophes.";
    }

    // Last name validation
    if (!director.lastName.trim()) {
      directorErrors.lastName =
        "Last name is required. Please enter the director's last name.";
    } else if (director.lastName.trim().length < 2) {
      directorErrors.lastName = "Last name must be at least 2 characters long.";
    } else if (!/^[a-zA-Z\s'-]+$/.test(director.lastName.trim())) {
      directorErrors.lastName =
        "Last name can only contain letters, spaces, hyphens, and apostrophes.";
    }

    // Date of birth validation
    if (!director.dateOfBirth) {
      directorErrors.dateOfBirth = "Date of birth is required.";
    } else {
      const birthDate = new Date(director.dateOfBirth);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (isNaN(birthDate.getTime())) {
        directorErrors.dateOfBirth = "Please enter a valid date of birth.";
      } else if (birthDate > today) {
        directorErrors.dateOfBirth = "Date of birth cannot be in the future.";
      } else if (
        age < 18 ||
        (age === 18 &&
          (monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())))
      ) {
        directorErrors.dateOfBirth = "Director must be at least 18 years old.";
      } else if (age > 120) {
        directorErrors.dateOfBirth = "Please enter a valid date of birth.";
      }
    }

    // Occupation validation
    if (!director.occupation.trim()) {
      directorErrors.occupation =
        "Occupation is required. Please enter the director's occupation.";
    } else if (director.occupation.trim().length < 2) {
      directorErrors.occupation =
        "Occupation must be at least 2 characters long.";
    } else if (!/^[a-zA-Z0-9\s,.-]+$/.test(director.occupation.trim())) {
      directorErrors.occupation =
        "Occupation can only contain letters, numbers, spaces, commas, periods, and hyphens.";
    }

    // Email validation
    if (!director.email.trim()) {
      directorErrors.email =
        "Email is required. Please enter the director's email address.";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        director.email.trim()
      )
    ) {
      directorErrors.email = "Please enter a valid email address.";
    }

    return directorErrors;
  }, []);

  const handleInputChange = useCallback(
    (index: number, field: DirectorField, value: string) => {
      updateDirector(index, { [field]: value });

      const newTouched = [...touched];
      newTouched[index] = {
        ...newTouched[index],
        [field]: true,
      };
      setTouched(newTouched);

      const newErrors = [...errors];
      const directorErrors = validateDirector({
        ...directors[index],
        [field]: value,
      });
      newErrors[index] = directorErrors;
      setErrors(newErrors);
    },
    [directors, errors, touched, updateDirector, validateDirector]
  );

  const handleAddDirector = useCallback(() => {
    addDirector();
    setErrors((prev) => [...prev, {}]);
    setTouched((prev) => [...prev, { ...initialTouchedState }]);
  }, [addDirector]);

  const handleRemoveDirector = useCallback(
    (index: number) => {
      removeDirector(index);
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors.splice(index, 1);
        return newErrors;
      });

      setTouched((prev) => {
        const newTouched = [...prev];
        newTouched.splice(index, 1);
        return newTouched;
      });
    },
    [removeDirector]
  );

  const handleSubmit = useCallback(async () => {
    if (!isFormValid || isSubmitting) return;

    const allErrors = directors.map((director) => validateDirector(director));
    setErrors(allErrors);

    const allTouched = directors.map(() => ({
      firstName: true,
      lastName: true,
      dateOfBirth: true,
      occupation: true,
      email: true,
    }));
    setTouched(allTouched);

    if (allErrors.every((error) => Object.keys(error).length === 0)) {
      setIsSubmitting(true);
      try {
        // Save to localStorage
        localStorage.setItem(
          "directorInfoState",
          JSON.stringify({ directors })
        );

        // Get corporation_email from corporateInfoState
        const corporateInfoState = localStorage.getItem("corporateInfoState");
        const corporateInfo = corporateInfoState
          ? JSON.parse(corporateInfoState)
          : {};
        const corporateEmail = corporateInfo.corporateEmail?.toLowerCase();

        // Format director data for API
        const directorNames = directors.map((director) =>
          `${director.firstName} ${director.lastName}`.toLowerCase()
        );
        const directorsDOB = directors.map((director) =>
          new Date(director.dateOfBirth).toISOString().slice(0, 10)
        );
        const directorOccupations = directors.map((director) =>
          director.occupation.toLowerCase()
        );
        const directorEmails = directors.map((director) =>
          director.email.toLowerCase()
        );

        await axiosInstance({
          method: "post",
          url: "/updateCorporate",
          data: {
            corporation_email: corporateEmail,
            list_of_directors: {
              directorNames,
              directorsDOB,
              directorOccupations,
              directorEmails,
            },
          },
        });

        nextState();
        navigate(ROUTES.DIRECTOR_PERSONAL_SHARE);
      } catch (error) {
        console.error("Error updating director information:", error);
        setErrors((prev) =>
          prev.map((error) => ({
            ...error,
            submit:
              "Unfortunately we were unable to process your account details at this time. Please try again later or contact us at support@paytrie.com",
          }))
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  }, [
    directors,
    isFormValid,
    isSubmitting,
    validateDirector,
    nextState,
    navigate,
  ]);

  // Add global keydown effect
  useEffect(() => {
    const handleGlobalKeyDown = async (e: KeyboardEvent) => {
      if (e.key === "Enter" && isFormValid && !isSubmitting) {
        e.preventDefault();
        await handleSubmit();
      }
    };

    document.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [isFormValid, isSubmitting, handleSubmit]);

  return (
    <div className="flex flex-col h-[600px]">
      <div className="flex-none py-4 pt-0">
        <div className="text-lg font-bold uppercase">OWNER'S INFORMATION</div>
        <p className="mt-2 text-sm">
          List the names, date of birth, occupation, and email of the company
          directors
        </p>
      </div>
      <div className="flex-1 min-h-0 overflow-y-auto mb-4">
        <div className="space-y-4 pr-2">
          {directors.map((director, index) => (
            <div
              key={index}
              className="p-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
            >
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-medium">Director {index + 1}</h3>
                {directors.length > 1 && (
                  <button
                    onClick={() => handleRemoveDirector(index)}
                    className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50 transition-colors"
                  >
                    <TrashIcon />
                  </button>
                )}
              </div>

              <div className="space-y-2">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col gap-1">
                    <TextInput
                      placeholder="First name"
                      value={director.firstName}
                      onChange={(e) =>
                        handleInputChange(index, "firstName", e.target.value)
                      }
                      onBlur={() => {
                        const newTouched = [...touched];
                        newTouched[index].firstName = true;
                        setTouched(newTouched);
                      }}
                    />
                    {touched[index]?.firstName && errors[index]?.firstName && (
                      <span className="text-sm text-red-500">
                        {errors[index].firstName}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col gap-1">
                    <TextInput
                      placeholder="Last name"
                      value={director.lastName}
                      onChange={(e) =>
                        handleInputChange(index, "lastName", e.target.value)
                      }
                      onBlur={() => {
                        const newTouched = [...touched];
                        newTouched[index].lastName = true;
                        setTouched(newTouched);
                      }}
                    />
                    {touched[index]?.lastName && errors[index]?.lastName && (
                      <span className="text-sm text-red-500">
                        {errors[index].lastName}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <BirthdayInput
                    value={director.dateOfBirth}
                    onChange={(value) =>
                      handleInputChange(index, "dateOfBirth", value)
                    }
                  />
                  {touched[index]?.dateOfBirth &&
                    errors[index]?.dateOfBirth && (
                      <span className="text-sm text-red-500">
                        {errors[index].dateOfBirth}
                      </span>
                    )}
                </div>

                <div className="flex flex-col gap-1">
                  <TextInput
                    placeholder="Occupation"
                    value={director.occupation}
                    onChange={(e) =>
                      handleInputChange(index, "occupation", e.target.value)
                    }
                    onBlur={() => {
                      const newTouched = [...touched];
                      newTouched[index].occupation = true;
                      setTouched(newTouched);
                    }}
                  />
                  {touched[index]?.occupation && errors[index]?.occupation && (
                    <span className="text-sm text-red-500">
                      {errors[index].occupation}
                    </span>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <TextInput
                    placeholder="Email"
                    type="email"
                    value={director.email}
                    onChange={(e) =>
                      handleInputChange(index, "email", e.target.value)
                    }
                    onBlur={() => {
                      const newTouched = [...touched];
                      newTouched[index].email = true;
                      setTouched(newTouched);
                    }}
                  />
                  {touched[index]?.email && errors[index]?.email && (
                    <span className="text-sm text-red-500">
                      {errors[index].email}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-none mt-auto">
        <Button
          onClick={handleAddDirector}
          className="w-full flex items-center justify-center mb-4"
        >
          Add Another Director
        </Button>

        {errors.some((error) => error.submit) && (
          <span className="text-sm text-red-500 block mb-4">
            {errors.find((error) => error.submit)?.submit}
          </span>
        )}

        <div className="pb-4">
          <TocSmallText>
            By clicking continue, you, your business' directors, your business'
            owners, are attesting to not be a Foreign or Domestic{" "}
            <a
              href={import.meta.env.VITE_PAYTRIE_PEP_URL}
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              Politically Exposed Person
            </a>{" "}
            (PEP), a{" "}
            <a
              href={import.meta.env.VITE_PAYTRIE_HIO_URL}
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              Head of an International Organization
            </a>{" "}
            (HIO), or a family member or close associate of a PEP or HIO. You
            are also attesting not to be transacting for a{" "}
            <a
              href={import.meta.env.VITE_PAYTRIE_TPD_URL}
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              third party
            </a>
            .
          </TocSmallText>
        </div>

        <Button
          onClick={handleSubmit}
          disabled={isSubmitting || !isFormValid}
          className={clsx(
            "inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2",
            {
              "opacity-50 cursor-not-allowed bg-gray-400":
                !isFormValid || isSubmitting,
              "hover:bg-gray-500": !isFormValid || isSubmitting,
            }
          )}
        >
          {isSubmitting ? "Saving..." : "Continue"}
        </Button>
      </div>
    </div>
  );
}
