import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { ROUTES } from "../CorporateSignupPanel";

export default function CorporateAssist() {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(ROUTES.CORPORATE_INFO);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">
          THANK YOU FOR REGISTERING WITH PAYTRIE
        </h1>
      </div>

      <div className="mt-8 flex flex-col gap-4">
        <p className="text-base">
          Please allow up to 24 hours for a PayTrie support staff to verify your
          identity. If we require more information, we will contact you via
          email.
        </p>

        <Button
          onClick={handleSubmit}
          className="mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
        >
          Return to Homepage
        </Button>
      </div>
    </div>
  );
}
