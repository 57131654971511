import React, { useEffect, useState, useContext, useCallback } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useDisconnect, useSwitchChain } from "wagmi";
import { Button } from "./Button";
import {
  getChainId,
  getNetworkName,
  isNetworkSupported,
  truncateAddress,
} from "@/utils/currencies";
import { useTransaction } from "@/hooks/useTransactionListener";

import {
  useWallets,
  useConnect,
  useDisconnect as useSolanaDisconnect,
  type UiWallet,
  type UiWalletAccount,
} from "@wallet-standard/react";

import { SelectedWalletAccountContext } from "@/contexts/WalletAccountProvider";

import * as Toast from "@radix-ui/react-toast";
import * as Icons from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Sentry from "@sentry/react";
import { ConnectedSolanaWalletButton } from "./ConnectedSolanaWalletButton";

const ErrorMessage = ({ message }: { message: string }) => (
  <div className="absolute left-0 right-0 mt-1 flex items-center justify-center gap-2 text-red-500 text-xs">
    <Icons.ExclamationTriangleIcon className="h-3 w-3" />
    <span>{message}</span>
  </div>
);

interface UnifiedWalletButtonProps {
  amount: number;
  recipientAddress: string;
  tokenSymbol: string;
  senderAddress: string;
  orderId: string;
  onTransferComplete?: (txHash: string) => void;
  onError?: (error: Error) => void;
}

export const HybridWalletConnectButton: React.FC<UnifiedWalletButtonProps> = (
  props
) => {
  const { disconnect } = useDisconnect();
  const { switchChain } = useSwitchChain();
  const [, network] = props.tokenSymbol.split("-");
  const isSupported = isNetworkSupported(network);
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  // EVM transaction handling hook
  const {
    transactionState,
    handleEvmTransfer,
    isEVM,
    isPending,
    isConfirming,
    chainId,
    isCorrectWallet,
  } = useTransaction(props);

  // New Solana wallet hooks
  const wallets = useWallets();

  // Filter for Solana wallets
  const availableSolanaWallets = wallets.filter((wallet) =>
    wallet.chains.includes("solana:mainnet")
  );

  // Get the selected wallet account from context
  const [selectedWalletAccount, setSelectedWalletAccount] = useContext(
    SelectedWalletAccountContext
  );

  // Find the wallet that owns the current account
  const currentWallet = selectedWalletAccount
    ? wallets.find((wallet) =>
        wallet.accounts.some(
          (account) => account.address === selectedWalletAccount.address
        )
      )
    : undefined;

  // Create a map of disconnect functions for each wallet
  // We'll use these to disconnect specific wallets
  const walletDisconnectFns = new Map();

  // Setup disconnect functions for each wallet
  wallets.forEach((wallet) => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [, disconnectFn] = useSolanaDisconnect(wallet);
      walletDisconnectFns.set(wallet.name, disconnectFn);
    } catch (e) {
      walletDisconnectFns.set(wallet.name, () => {});
    }
  });

  // Transaction processing state for Solana
  const [isSolanaProcessing, setIsSolanaProcessing] = useState(false);

  // Toast state
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    description: "",
    type: "info" as "info" | "warning" | "success",
  });

  // Function to show toast notifications
  const showToast = useCallback(
    (
      title: string,
      description: string,
      type: "info" | "warning" | "success"
    ) => {
      setToastMessage({ title, description, type });
      setToastOpen(true);
    },
    []
  );

  // Use the ConnectButton component for each wallet
  const WalletConnectButton = ({
    wallet,
    onConnect,
  }: {
    wallet: UiWallet;
    onConnect: (accounts: UiWalletAccount) => void;
  }) => {
    const [isConnecting, connect] = useConnect(wallet);

    const handleConnectClick = useCallback(async () => {
      try {
        const nextAccounts = await connect();
        // Handle account selection if needed
        if (nextAccounts.length > 0) {
          // Check if the required account is among the connected accounts
          const requiredAccount = nextAccounts.find(
            (account) => account.address === props.senderAddress
          );

          if (requiredAccount) {
            // If we found the exact account needed, use it directly
            onConnect(requiredAccount);
          } else if (nextAccounts.length === 1) {
            // If there's only one account, use it (with a warning if it's not the right one)
            onConnect(nextAccounts[0]);
          } else {
            // If there are multiple accounts, show the account selection UI
            console.log("Multiple accounts, showing selection UI");
          }
        }
      } catch (error: any) {
        console.error("Wallet connection error:", error);
        showToast(
          "Connection Failed",
          error.message || "Failed to connect to wallet",
          "warning"
        );

        Sentry.captureException(error, {
          tags: {
            action: "wallet_connect",
            wallet_name: wallet.name,
          },
        });
      }
    }, [connect, wallet.accounts, onConnect]);

    return (
      <button
        onClick={handleConnectClick}
        disabled={isConnecting}
        className="w-full justify-start p-3 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
      >
        <div className="flex items-center gap-2">
          {wallet.icon && (
            <img
              src={wallet.icon}
              alt={`${wallet.name} icon`}
              className="w-6 h-6"
            />
          )}
          <span className="font-medium">{wallet.name}</span>
          {isConnecting && <span className="ml-auto">Connecting...</span>}
        </div>
      </button>
    );
  };

  // Handle accounts after successful connection
  const handleAccountsReceived = useCallback(
    (account: UiWalletAccount) => {
      if (account) {
        // Set in the context (this will also save to localStorage)
        setSelectedWalletAccount(account);
        showToast("Wallet Connected", `Connected successfully`, "success");
      } else {
        showToast(
          "Connection Failed",
          "No accounts were returned from the wallet",
          "warning"
        );
      }
      setWalletDialogOpen(false);
    },
    [setSelectedWalletAccount, showToast]
  );

  // Solana wallet selection dialog
  const WalletSelectDialog = () => (
    <Dialog.Root open={walletDialogOpen} onOpenChange={setWalletDialogOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40 backdrop-blur-sm" />
        <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-900 p-6 rounded-lg shadow-xl w-[90vw] max-w-md">
          <Dialog.Title className="text-xl font-semibold mb-4">
            {selectedWalletAccount ? "Switch Wallet" : "Select a Wallet"}
          </Dialog.Title>
          {selectedWalletAccount && (
            <div className="mb-4 p-3 bg-yellow-50 dark:bg-yellow-900/30 rounded border border-yellow-200 dark:border-yellow-800">
              <p className="text-sm text-yellow-800 dark:text-yellow-200">
                You'll be disconnected from your current wallet (
                {truncateAddress(selectedWalletAccount.address)}) when you
                connect to a new one.
              </p>
            </div>
          )}
          <div className="space-y-2 max-h-64 overflow-y-auto">
            {availableSolanaWallets.map((wallet) => (
              <WalletConnectButton
                key={wallet.name}
                wallet={wallet}
                onConnect={handleAccountsReceived}
              />
            ))}
          </div>
          <Dialog.Close asChild>
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              aria-label="Close"
            >
              <Icons.Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );

  // Toast notification component
  const ToastNotification = () => (
    <Toast.Provider swipeDirection="right">
      <Toast.Root
        className={`
          fixed bottom-4 right-4 z-50
          bg-white dark:bg-black rounded-none shadow-lg
          p-4 w-[350px] max-w-[95vw]
          data-[state=open]:animate-slideIn
          data-[state=closed]:animate-slideOut
          data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)]
          data-[swipe=cancel]:translate-x-0
          data-[swipe=end]:animate-swipeOut
          border-l-4
          ${toastMessage.type === "warning" ? "border-yellow-500" : ""}
          ${toastMessage.type === "success" ? "border-green-500" : ""}
          ${toastMessage.type === "info" ? "border-blue-500" : ""}
        `}
        open={toastOpen}
        onOpenChange={setToastOpen}
        duration={toastMessage.type === "success" ? 3000 : 5000}
      >
        <div className="flex items-start">
          {toastMessage.type === "warning" && (
            <svg
              className="w-5 h-5 text-yellow-500 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          )}
          <div>
            <Toast.Title
              className={`
                font-semibold text-base mb-1
                ${toastMessage.type === "warning" ? "text-yellow-800" : "dark:text-gray-100 text-black"}
              `}
            >
              {toastMessage.title}
            </Toast.Title>
            <Toast.Description className="text-gray-600 text-sm dark:text-gray-500">
              {toastMessage.description}
            </Toast.Description>
          </div>
        </div>
      </Toast.Root>
      <Toast.Viewport className="fixed bottom-0 right-0 flex flex-col p-6 gap-2 w-[390px] max-w-[100vw] m-0 list-none z-50 outline-none" />
    </Toast.Provider>
  );

  // Function to disconnect current wallet
  const disconnectCurrentWallet = useCallback(async () => {
    if (selectedWalletAccount && currentWallet) {
      try {
        // Get the disconnect function for this specific wallet
        const disconnectFn = walletDisconnectFns.get(currentWallet.name);

        if (disconnectFn) {
          // Use the disconnect function from the hook
          await disconnectFn();
          console.log("Wallet disconnected successfully");
        } else {
          console.warn(
            "No disconnect function found for wallet:",
            currentWallet.name
          );
        }
      } catch (error) {
        console.error("Error disconnecting wallet:", error);
      }

      // Clear the selected account from context (will also remove from localStorage)
      setSelectedWalletAccount(undefined);

      showToast(
        "Wallet Disconnected",
        "Please select another wallet to connect",
        "info"
      );
    }
  }, [
    selectedWalletAccount,
    currentWallet,
    walletDisconnectFns,
    setSelectedWalletAccount,
    showToast,
  ]);

  // Get error message if any for Solana
  const getSolanaErrorMessage = () => {
    if (
      selectedWalletAccount &&
      selectedWalletAccount.address !== props.senderAddress
    ) {
      return `Please connect wallet: ${truncateAddress(props.senderAddress)}`;
    }
    return null;
  };

  // Handle connect wallet button click (for disconnected state)
  const handleConnectWalletClick = useCallback(() => {
    setWalletDialogOpen(true);
  }, []);

  // EVM Transaction Handling
  const handleEVMTransaction = async (handler: () => Promise<void>) => {
    if (transactionState.status === "completed") {
      showToast(
        "Transaction Already Complete",
        "This transaction has already been confirmed. Please do not send it again.",
        "warning"
      );
      return;
    }

    showToast(
      "Transaction In Progress",
      "Please do not refresh the page or submit another transaction until this one completes.",
      "info"
    );

    try {
      await handler();
    } catch (error) {
      console.error(error);
      setToastOpen(false);
    }
  };

  // Effect to show toast on transaction completion
  useEffect(() => {
    if (transactionState.status === "completed") {
      showToast(
        "Transaction Complete!",
        "Your transaction has been confirmed.",
        "success"
      );
    } else if (transactionState.status === "failed") {
      showToast(
        "Transaction Failed",
        transactionState.error?.message ||
          "An error occurred during the transaction.",
        "warning"
      );
    }
  }, [transactionState, showToast]);

  if (!isSupported) {
    return (
      <div className="relative h-full">
        <Button
          disabled={true}
          className="inline-flex items-center justify-center py-6 px-4 w-full text-center bg-gray-200 dark:bg-gray-700 cursor-not-allowed"
        >
          <span className="text-gray-500 dark:text-gray-400">
            Wallet payment unavailable
          </span>
        </Button>
        <ErrorMessage
          message={`${getNetworkName(network)} network is not supported`}
        />
        <ToastNotification />
      </div>
    );
  }

  // Determine whether to show Solana or EVM wallet UI
  if (isEVM) {
    return (
      <ConnectButton.Custom>
        {({ account, chain, openConnectModal, mounted }) => {
          if (!mounted) return null;

          const ready = account && chain;
          const connected = ready && !chain?.unsupported;
          const wrongNetwork =
            connected && chainId !== getChainId(props.tokenSymbol);
          const wrongWallet = connected && !isCorrectWallet;

          const isButtonDisabled =
            isPending ||
            isConfirming ||
            transactionState.status === "mining" ||
            transactionState.status === "completed";

          const handleClick = async () => {
            try {
              if (wrongWallet) {
                disconnect();
                setTimeout(openConnectModal, 500);
              } else if (!connected) {
                openConnectModal();
              } else if (wrongNetwork) {
                try {
                  await switchChain({ chainId: getChainId(props.tokenSymbol) });
                } catch (error) {
                  props.onError?.(error as Error);
                }
              } else {
                handleEVMTransaction(handleEvmTransfer);
              }
            } catch (error) {
              Sentry.captureException(error, {
                tags: {
                  action: "wallet_button_click",
                  is_connected: connected ? "true" : "false",
                  wrong_wallet: wrongWallet ? "true" : "false",
                  wrong_network: wrongNetwork ? "true" : "false",
                  ready: ready ? "true" : "false",
                },
              });

              console.error("Button click error:", error);
              props.onError?.(error as Error);
            }
          };

          const getErrorMessage = () => {
            if (wrongWallet)
              return `Please connect wallet: ${truncateAddress(props.senderAddress)}`;
            if (wrongNetwork)
              return `Please switch to ${getNetworkName(props.tokenSymbol.split("-")[1])}`;
            if (transactionState.status === "failed")
              return "Transaction failed. Please try again.";
            return null;
          };

          const buttonText =
            transactionState.status === "completed"
              ? "Transaction Complete!"
              : `Send ${props.amount} ${props.tokenSymbol}`;

          return (
            <div className="relative h-full">
              <div className="cursor-pointer">
                <button
                  onClick={handleClick}
                  disabled={isButtonDisabled}
                  className="w-full py-6 bg-black text-white dark:bg-white dark:text-black border-black text-center block"
                  style={{
                    height: "auto",
                    fontFeatureSettings: '"ss01"',
                  }}
                >
                  <span>{connected ? buttonText : "PAY WITH WALLET"}</span>
                </button>
              </div>
            </div>
          );
        }}
      </ConnectButton.Custom>
    );
  } else {
    // Solana wallet UI
    return (
      <div className="relative h-full">
        {/* Conditional rendering of connected or disconnected state */}
        {selectedWalletAccount ? (
          <ConnectedSolanaWalletButton
            selectedAccount={selectedWalletAccount}
            props={props}
            showToast={showToast}
            setWalletDialogOpen={setWalletDialogOpen}
            disconnectCurrentWallet={disconnectCurrentWallet}
            isSolanaProcessing={isSolanaProcessing}
            setIsSolanaProcessing={setIsSolanaProcessing}
          />
        ) : (
          <>
            <button
              onClick={handleConnectWalletClick}
              className="inline-flex items-center h-full justify-center py-6 px-4 w-full text-center bg-black dark:bg-gray-200 text-white dark:text-black"
            >
              <span>PAY WITH WALLET</span>
            </button>
          </>
        )}

        {getSolanaErrorMessage() && (
          <ErrorMessage message={getSolanaErrorMessage()!} />
        )}
        <WalletSelectDialog />
        <ToastNotification />
      </div>
    );
  }
};

export default HybridWalletConnectButton;
