import { mainnet, testnet } from "@solana/kit";
import { useMemo, useState } from "react";
import type { ClusterUrl } from "@solana/kit";
import { devnet } from "@solana/kit";
import { createContext } from "react";

const STORAGE_KEY = "solana-example-react-app:selected-chain";

export type ChainContext = Readonly<{
  chain: `solana:${string}`;
  displayName: string;
  setChain?(chain: `solana:${string}`): void;
  solanaExplorerClusterName: "devnet" | "mainnet-beta" | "testnet";
  solanaRpcSubscriptionsUrl: ClusterUrl;
  solanaRpcUrl: ClusterUrl;
}>;

export const DEFAULT_CHAIN_CONFIG = Object.freeze({
  chain: "solana:mainnet",
  displayName: "Mainnet Beta",
  solanaExplorerClusterName: "mainnet-beta",
  solanaRpcSubscriptionsUrl: mainnet("wss://api.mainnet-beta.solana.com"),
  solanaRpcUrl: mainnet("https://api.mainnet-beta.solana.com"),
});

export const ChainContext = createContext<ChainContext>(DEFAULT_CHAIN_CONFIG);

export function ChainContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Get RPC URL from environment variable or fall back to default
  const mainnetRpcUrl =
    import.meta.env.VITE_SOLANA_RPC || "https://api.mainnet-beta.solana.com";

  // Derive WebSocket URL from HTTP URL (Helius supports this pattern)
  const mainnetWsUrl = mainnetRpcUrl.replace("https://", "wss://");

  const [chain, setChain] = useState(
    () => localStorage.getItem(STORAGE_KEY) ?? "solana:mainnet"
  );

  const contextValue = useMemo<ChainContext>(() => {
    switch (chain) {
      case "solana:mainnet":
        return {
          chain: "solana:mainnet",
          displayName: "Mainnet Beta",
          solanaExplorerClusterName: "mainnet-beta",
          solanaRpcSubscriptionsUrl: mainnet(mainnetWsUrl),
          solanaRpcUrl: mainnet(mainnetRpcUrl),
        };
      case "solana:testnet":
        return {
          chain: "solana:testnet",
          displayName: "Testnet",
          solanaExplorerClusterName: "testnet",
          solanaRpcSubscriptionsUrl: testnet("wss://api.testnet.solana.com"),
          solanaRpcUrl: testnet("https://api.testnet.solana.com"),
        };
      case "solana:devnet":
      default:
        if (chain !== "solana:devnet") {
          localStorage.removeItem(STORAGE_KEY);
          console.error(`Unrecognized chain \`${chain}\``);
        }
        return {
          chain: "solana:devnet",
          displayName: "Devnet",
          solanaExplorerClusterName: "devnet",
          solanaRpcSubscriptionsUrl: devnet("wss://api.devnet.solana.com"),
          solanaRpcUrl: devnet("https://api.devnet.solana.com"),
        };
    }
  }, [chain, mainnetRpcUrl, mainnetWsUrl]);

  return (
    <ChainContext.Provider
      value={useMemo(
        () => ({
          ...contextValue,
          setChain(chain) {
            localStorage.setItem(STORAGE_KEY, chain);
            setChain(chain);
          },
        }),
        [contextValue]
      )}
    >
      {children}
    </ChainContext.Provider>
  );
}
