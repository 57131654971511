import axios from "./axios";

const userEndpoints = {
  GET_ORDER_STATUS: "getTransactionById",
  CANCEL_TRANSACTION: "userCancelTransaction",
  PRICE_QUOTE: "priceQuote",
  ADD_OTC_BUY_ORDER: "addOtcBuyOrder",
  SUBMIT_OTC_SELL_ORDER: "submitOtcSellOrder",
  ADD_BANK_DETAILS: "addBankDetails",
  GET_BANK_DETAILS_BY_USER: "getBankDetailsByUser",
  GET_BANK_DETAILS_BY_ID: "getBankDetailsById",
  DELETE_BANK_DETAILS: "deleteBankDetails",
};

export type OrderStatusResponse = {
  date: string;
  tx_id: string;
  wallet: string;
  status: string;
  leftSideValue: string;
  leftSideLabel: string;
  rightSideValue: string;
  rightSideLabel: string;
  atb_status: string;
  payment_id: string;
  eth_fee: boolean;
  payment_type: string;
  coin: string;
  fee: number;
};

const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

export const getOrderStatusByTransactionId = (transactionId: string) => {
  return axios.get<OrderStatusResponse>(`/${userEndpoints.GET_ORDER_STATUS}`, {
    params: { tx_id: transactionId },
  });
};

export const userCancelTransaction = (transactionId: string) => {
  const token = getAuthToken();
  return axios.post(`/${userEndpoints.CANCEL_TRANSACTION}`, null, {
    params: { tx_id: transactionId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPriceQuote = (
  leftSideValue: number,
  leftSideLabel: string,
  rightSideLabel: string
) => {
  const token = getAuthToken();
  return axios.get(`/${userEndpoints.PRICE_QUOTE}`, {
    params: { leftSideValue, leftSideLabel, rightSideLabel },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addOtcBuyOrder = (orderData: any) => {
  const token = getAuthToken();
  return axios.post(`/${userEndpoints.ADD_OTC_BUY_ORDER}`, orderData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const submitOtcSellOrder = (orderData: any) => {
  const token = getAuthToken();
  return axios.post(`/${userEndpoints.SUBMIT_OTC_SELL_ORDER}`, orderData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addBankDetails = (bankData: any) => {
  const token = getAuthToken();
  return axios.post(`/${userEndpoints.ADD_BANK_DETAILS}`, bankData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBankDetailsByUser = () => {
  const token = getAuthToken();
  return axios.get(`/${userEndpoints.GET_BANK_DETAILS_BY_USER}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBankDetailsById = (bankId: string) => {
  const token = getAuthToken();
  return axios.get(`/${userEndpoints.GET_BANK_DETAILS_BY_ID}`, {
    params: { bank_id: bankId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteBankDetails = (bankId: string) => {
  const token = getAuthToken();
  return axios.post(
    `/${userEndpoints.DELETE_BANK_DETAILS}`,
    {
      bank_id: bankId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
